import './SelectField.css'
export default function SelectField({ field }) {
  return (
    <div className="field select__field">
      <div className="field__label">
        <span>{field.label}</span>
        {field.required ? <span className="required">*</span> : null}
      </div>
      <select >
        {field.options.map((fieldOption) => (
          <option key={fieldOption.option}>{fieldOption.option}</option>
        ))}
      </select>
    </div>
  );
}
