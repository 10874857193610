import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Select,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { countryList } from "../../utils/CountryList/CountryList";
import { useContext } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

export default function CallVisualRepresentation({ widgetFields }) {
  const fieldsMap = {
    text: Input,
    checkbox: Checkbox,
    textarea: Textarea,
    gdpr: Checkbox,
  };

  const agency = useContext(AgencyContext);
  const { myDetails } = agency;
  const agency_id = myDetails ? myDetails?.agency_id : null;
  const agencyName = myDetails?.branding?.agency_name
  const agencyLogoUrl = myDetails?.branding?.logo_url

  const renderLogo = (size) => {
    if (widgetFields.logo) {
      return (
        <Image
          src={widgetFields.logo}
          fallbackSrc={"/logo/favicon.png"}
          width={size}
          alt="logo"
        />
      );
    } else if (agency_id) {
      if (agencyLogoUrl) {
        return (
          <Image
            src={
              agencyLogoUrl.includes(window.origin)
                ? agencyLogoUrl
                : `${window.location.origin}${agencyLogoUrl}`
            }
            width={size}
            alt={`${agencyName}-logo`}
          />
        );
      } else {
        return null;
      }
    } else {
      return <Image src={"/logo/favicon.png"} width={size} alt="logo" />;
    }
  };

  return (
    <Box>
      <Flex p={3} gap={2} justifyContent={"center"} alignItems={"center"}>
        {renderLogo(30)}
        <Heading>
          {widgetFields.header_name || agencyName || "Insighto.AI"}
        </Heading>
      </Flex>
      <Stack p={3} spacing={3}>
        {widgetFields.fields.map((field, index) => {
          const Field = fieldsMap[field.type] || Input;
          return field.type === "tel" ? (
            <FormControl isRequired={field.required} key={index}>
              <FormLabel>{field.label}</FormLabel>
              <Flex>
                <Box maxW={150}>
                  <Select defaultValue={field.default_country_code}>
                    {countryList.map((countryCode) => (
                      <option value={countryCode.code} key={countryCode.code}>
                        +{countryCode.dial_code}({countryCode.code})
                      </option>
                    ))}
                  </Select>
                </Box>
                <Input {...field} />
              </Flex>
            </FormControl>
          ) : field.type === "checkbox" || field.type === "gdpr" ? (
            <Flex
              gap={3}
              justifyContent={"flex-start"}
              alignItems={"center"}
              key={index}
            >
              <Field {...field} key={index}>
                {field.label}
              </Field>
              {field.type === "gdpr" ? (
                <Link href={field.privacy_consent_url}>
                  {field.description}
                </Link>
              ) : null}
            </Flex>
          ) : (
            <FormControl isRequired={field.required} key={index}>
              <FormLabel>{field.label}</FormLabel>
              {field.type === "select" ? (
                <Select>
                  {field.options.map((field, index) => (
                    <option key={index}>{field}</option>
                  ))}
                </Select>
              ) : (
                <Field {...field} />
              )}
            </FormControl>
          );
        })}
        <Button
          backgroundColor={"3b81f6"}
          margin={"auto"}
          style={{ ...widgetFields.theme.button }}
        >
          {widgetFields.button_label}
        </Button>
      </Stack>
      {widgetFields.remove_branding ? null : (
        <Flex p={3} gap={2} justifyContent={"center"} alignItems={"center"}>
          <Text>{"Powered By"}</Text>
          {renderLogo(15)}
          <strong>
            {agencyName || "Insighto.AI"}
          </strong>
        </Flex>
      )}
    </Box>
  );
}
