import {
  Box,
  Heading,
} from "@chakra-ui/react";
import addDays from "date-fns/addDays";
import endOfWeek from "date-fns/endOfWeek";
import startOfWeek from "date-fns/startOfWeek";
import subDays from "date-fns/subDays";
import { useContext, useEffect, useState } from "react";
import dateFormat from "../../utils/DateFormat/dateFormat";
import { fetchController } from "../../utils/FetchController/fetchController";
import Layout from "../Layout/Layout";
import AssignIntent from "./AssignIntent";
import IntentTable from "./IntentTable";
import IntentTour from "./IntentTour";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";

const Intent = () => {
  const baseUrl = getHostDomain();
  const [converSationdata, setconverSationdata] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedChat, setSelectedChat] = useState();
  const [selectionAssistant, setselectionAssistant] = useState(null);
  const [assistantOption, setassistantOption] = useState(null);
  const [selectionIntent, setselectionIntent] = useState(null);
  const [intentOption, setintentOption] = useState(null);
  const [tab, setTab] = useState("Profile");

  const predefinedBottomRanges = [
    {
      label: "Today",
      value: [new Date(), new Date()],
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
    },
    {
      label: "Last 7 days",
      value: [subDays(new Date(), 6), new Date()],
    },
    {
      label: "Last 30 days",
      value: [subDays(new Date(), 29), new Date()],
    },
  ];
  const [dateRange, setdateRange] = useState(predefinedBottomRanges[2].value);
  const endpointassistant = "/api/v1/assistant/list";
  const endpointIntent = "/api/v1/intent/list";
  const endpointConversation =
    "/api/v1/analytics/coversation_assistant_daterange";
  const urlHitAssistant = baseUrl + endpointassistant;
  const urlHitIntent = baseUrl + endpointIntent;
  const urlHitConversation = baseUrl + endpointConversation;
  const handleConversation = async (data) => {
    setSelectedChat("");
    setMessages([]);
    setSelectedChat(data.Conversation);
    if (data.Conversation) {
      const getConversation = await fetchController(
        baseUrl +
          `/api/v1/analytics/conversationchat_daterange?conversation_id=${
            data?.Conversation?.id
          }&date_from=${dateFormat(dateRange[0])}&date_to=${dateFormat(
            dateRange[1]
          )}`,
        "GET"
      );
      if (getConversation.data) {
        setMessages(getConversation.data);
      }
    }
  };

  const handleAssistantList = async () => {
    const getData = await fetchController(urlHitAssistant, "GET");
    setassistantOption(
      getData?.data?.items?.map((data) => {
        return {
          label: data.name,
          value: data.id,
        };
      })
    );
  };
  const handleIntentList = async () => {
    const getData = await fetchController(urlHitIntent, "GET");
    setintentOption(
      getData?.data?.items?.map((data) => {
        return {
          label: data.name,
          value: data.id,
        };
      })
    );
  };

  const handleOnDateRangeChange = (value) => {
    if (!value) {
      setdateRange([]);
      setconverSationdata([]);
      setMessages([]);
    }
    setdateRange(value);
  };
  useEffect(() => {
    // if ((dateRange && selectionAssistant) || selectionIntent) {
    // handleConversationCall();
    // }
  }, [dateRange, selectionAssistant, selectionIntent]);
  const handleConversationCall = async () => {
    let fullUrl;
    if (!selectionAssistant?.value && !selectionIntent?.value) {
      fullUrl = `/api/v1/conversation/conversation_assistant_daterange?&date_from=${dateFormat(
        dateRange[0]
      )}&date_to=${dateFormat(dateRange[1])}`;
    } else if (selectionAssistant?.value && selectionIntent?.value) {
      fullUrl = `/api/v1/conversation/conversation_assistant_daterange?assistant_id=${
        selectionAssistant?.value
      }&intent_id=${selectionIntent?.value}&date_from=${dateFormat(
        dateRange[0]
      )}&date_to=${dateFormat(dateRange[1])}`;
    } else if (!selectionAssistant?.value) {
      fullUrl = `/api/v1/conversation/conversation_assistant_daterange?
       }&intent_id=${selectionIntent?.value}&date_from=${dateFormat(
        dateRange[0]
      )}&date_to=${dateFormat(dateRange[1])}`;
    } else if (!selectionIntent?.value) {
      fullUrl = `/api/v1/conversation/conversation_assistant_daterange?assistant_id=${
        selectionAssistant?.value
      }&date_from=${dateFormat(dateRange[0])}&date_to=${dateFormat(
        dateRange[1]
      )}`;
    }

    const getData = await fetchController(
      baseUrl + `${fullUrl}`,
      "GET"
    );
    if (getData) {
      setconverSationdata(getData?.data.items);
    }
  };
  useEffect(() => {
    handleAssistantList();
    handleIntentList();
  }, []);
  const [intentTourVisibility, setIntentTourVisibility] = useState(
    localStorage.getItem("intentTourVisibility") ? false : true
  );
  const onCloseIntentTour = () => {
    localStorage.setItem("intentTourVisibility", false);
    setIntentTourVisibility(false);
  };
  const agency = useContext(AgencyContext);
  const { textColor } = agency;
  return (
    <Layout>
      <Box
        border="1px"
        borderColor={"black"}
        borderRadius={"md"}
        h="90svh"
        p={5}
        overflowY={"auto"}
      >
        <Heading color={textColor} fontSize="xl">
          Intents
        </Heading>
        {intentTourVisibility ? (
          <IntentTour onCloseIntentTour={onCloseIntentTour} />
        ) : null}
        <Box mt={4}>
          <IntentTable />
        </Box>
      </Box>
    </Layout>
  );
};

export default Intent;
