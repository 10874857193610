import {
  Modal,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  Button,
} from "@chakra-ui/react";
import WebhookForm from "./WebhookForm";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../../utils/utils";
export default function WebhookModal({
  isOpen,
  onClose,
  selectedWebhook,
  fetchWebhooks,
}) {
  const baseUrl = getHostDomain();
  const webhookForm = useForm({
    defaultValues: {
      name: "",
      endpoint: "",
      enabled: true,
    },
  });
  const [status, setStatus] = useState("idle");
  const onSubmitWebhookForm = async (values) => {
    const { id, name, endpoint, enabled } = values;
    const method = id ? "PUT" : "POST";
    const url = id
      ? `/api/v1/outbound_webhook/${id}`
      : "/api/v1/outbound_webhook";
    setStatus("submitting");
    await fetchController(baseUrl + url, method, {
      name,
      endpoint,
      enabled,
    });
    fetchWebhooks();
    onClose();
    setStatus("idle");
    webhookForm.reset({
      name: "",
      endpoint: "",
      enabled: true,
    });
  };
  useEffect(() => {
    webhookForm.reset(
      selectedWebhook || { name: "", endpoint: "", enabled: true }
    );
  }, [selectedWebhook, isOpen]);
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Webhook</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={webhookForm.handleSubmit(onSubmitWebhookForm)}>
          <ModalBody>
            <WebhookForm webhookForm={webhookForm} buttonColorScheme={buttonColorScheme}/>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              isLoading={status === "submitting"}
              colorScheme={buttonColorScheme}
              type="submit"
            >
              Submit
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
