 const dateFormat=(inputDate)=>{
    const dateObject = new Date(inputDate);

    // Get individual date components
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); 
    const day = String(dateObject.getDate()).padStart(2, "0");

    // Construct the date string in YYYY-MM-DD format
    const dateString = `${year}-${month}-${day}`;

    return dateString
}





export default dateFormat;