import { Box, Text } from "@chakra-ui/react";
import { formatDateWithAmPm } from "../../../utils/DateFormat/dateFormatWithTimeAmPm";

export default function WebhookLogListItem({ log }) {
  return (
    <Box>
      <Text>
        <strong>Event Name :</strong> {log.event_name}
      </Text>
      <Text fontSize={"sm"}>
        <strong>Log Id : </strong> {log.id}
      </Text>
      <Text fontSize={"sm"}>
        <strong>Created At : </strong> {formatDateWithAmPm(log.created_at)}
      </Text>
      {log.response_code ? (
        <Text
          title="Response code"
          color={log.response_code < 400 ? "green" : "red"}
          textAlign={"right"}
        >
          {log.response_code}
        </Text>
      ) : null}
    </Box>
  );
}
