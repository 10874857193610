import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
const VoiceTimestampModal = ({
  isVoiceTimeStampModalOpen,
  onVoiceTimeStampModalClose,
  onAddMyVoiceModalOpen,
  voiceData,
  buttonColorScheme
}) => {
  return (
    <Modal
      isOpen={isVoiceTimeStampModalOpen}
      onClose={onVoiceTimeStampModalClose}
      size="800px"
    >
      <ModalOverlay />
      <ModalContent w="750px">
        <ModalHeader>My voice</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer>
            <Table variant="simple" size={"md"}>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th colSpan={2}>Description</Th>
                  <Th>Timestamp</Th>
                  <Th>Operation</Th>
                </Tr>
              </Thead>
              <Tbody>
                {voiceData.map((val, index) => (
                  <Tr key={val.id}>
                    <Td>{val.name}</Td>
                    <Td
                      colSpan={2}
                      style={{ maxWidth: "500px", overflowWrap: "break-word" }}
                    >
                      {val.description}
                    </Td>
                    <Td> {val.created_at}</Td>
                    <Td>
                      {" "}
                      <Button
                        colorScheme={buttonColorScheme}
                        onClick={onAddMyVoiceModalOpen}
                      >
                        Update my voice
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VoiceTimestampModal