import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import Prompt from "./Tabs/Prompt";
import Layout from "../Layout/Layout";

const Library = () => {
  const { name = "prompts" } = useParams();
  const navigate = useNavigate();
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, colorPreset } = agency;

  const tabs = [
    {
      route: "prompts",
      tabName: "Prompts",
      children: (
        <Prompt
          tab={"Prompts"}
          buttonColorScheme={buttonColorScheme}
          textColor={textColor}
        />
      ),
    },
  ];

  const currentIndex = tabs.findIndex((tab) => tab.route === name);
  const tabIndex = currentIndex === -1 ? 0 : currentIndex;

  return (
    <Layout>
      <Box>
        <Tabs isLazy index={tabIndex}>
          <TabList>
            {tabs.map((tab) => (
              <Tab
                onClick={() => navigate(`library/${tab.route}`)}
                key={tab.route}
                color={textColor}
                buttonColorScheme
              >
                {tab.tabName}
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel key={tab.route}>
                <Box mt={4}>{tab.children}</Box>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
};

export default Library;
