import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import Loader from "../../utils/Loader/Loader";
import Layout from "../Layout/Layout";
import useWebcallWidgetForm from "./useWebcallWidgetForm";
import WebcallWidgetForm from "./WebcallWidgetForm";
import WebcallRepresentation from "./WebcallRepresentation";

export default function WebcallWidgetCustomizationPage() {
  const { widgetId } = useParams();
  const { form, isWidgetLoading, widget, getWidgetByIdWithParams } =
    useWebcallWidgetForm({
      widgetId,
    });
  const { watch } = form;
  const formColor = useColorModeValue("white", "gray.700");
  return (
    <Layout>
      <Box
        border={"1px solid black"}
        borderRadius={"md"}
        h={"90svh"}
        p={4}
        overflowY={"scroll"}
      >
        {isWidgetLoading ? (
          <Loader />
        ) : widget ? (
          <Grid gap={2} gridTemplateColumns={"1fr 1fr"}>
            <Box>
              <Box
                bg={formColor}
                p={2}
                border={"1px solid lightgray"}
                borderRadius={"md"}
              >
                <WebcallWidgetForm
                  form={form}
                  getWidgetByIdWithParams={getWidgetByIdWithParams}
                />
              </Box>
            </Box>
            <Box border={"1px solid lightgray"} borderRadius={"md"} p={2}>
              <WebcallRepresentation watch={watch} />
            </Box>
          </Grid>
        ) : (
          <Box>Widget not found</Box>
        )}
      </Box>
    </Layout>
  );
}
