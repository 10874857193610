import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import AddPromptModal from "../../Modals/AddPrompt";
import { getHostDomain } from "../../../utils/utils";
import Loader from "../../../utils/Loader/Loader";
import Table from "../../Tables/Table";
import Paginator from "../../../utils/Loader/Paginator";

const Prompt = ({ tab, buttonColorScheme, textColor }) => {
  const baseUrl = getHostDomain();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [heads, setHeads] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState("idle");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  const getPrompts = async () => {
    try {
      setStatus("loading");
      const response = await fetchController(
        baseUrl + `/api/v1/prompt/list?size=10&page=${page}`,
        "GET"
      );
      setPages(response?.data?.pages);

      const headsData = ["name", "description"];
       const columns = headsData.map((key) => ({
        Header: key,
        accessor: key,
        Filter: ({ column }) => <input {...column.filterProps} />,
      }));
      setTableData(response.data.items);
      setHeads(columns);
    } catch (error) {
      console.error(error);
    } finally {
      setStatus("idle");
    }
  };

  useEffect(() => {
      getPrompts();
  }, []);

  return (
    <Box border="1px" h="81vh" overflowY="auto" p="5" borderRadius="md">
      <Box display="flex" justifyContent="space-between">
        <Text fontSize="xl" fontWeight="bold" color={textColor} p={2}>
          Prompts
        </Text>
        <Button colorScheme="blue" onClick={() => onOpen()}>
          Add a Prompt
        </Button>
      </Box>

      {status === "loading" ? (
        <Loader />
      ) : (
        <Box height="auto">
          <Table
            fetch={getPrompts}
            columns={heads}
            data={tableData}
            table="prompt"
          />

          <Flex
            justifyContent={"flex-end"}
            alignItems={"center"}
            marginBlock={2}
          >
            <Paginator
              colorScheme={buttonColorScheme}
              onChangePage={setPage}
              page={page}
              pages={pages}
            />
          </Flex>
        </Box>
      )}
      
      {isOpen && (
        <AddPromptModal
          isOpen={isOpen}
          onClose={onClose}
          refreshTable={getPrompts}
        />
      )}
    </Box>
  );
};

export default Prompt;
