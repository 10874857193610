import { Box, Flex, Heading, Stack, useDisclosure } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import Loader from "../../utils/Loader/Loader";
import Paginator from "../../utils/Loader/Paginator";
import Layout from "../Layout/Layout";
import LogDeleteAlert from "./LogDeleteAlert";
import LogJsonModal from "./LogJsonModal";
import LogsTable from "./LogsTable";
import NoLogsFound from "./NoLogsFound";
import ShowModal from "./ShowModal";
import useLogs from "./useLogs";

export default function LogsPage() {
  const selectedLogType = "tool_function_invoke_log";
  const headings = {
    tool_function_invoke_log: "Tool Calling Log",
  };
  const heading = headings[selectedLogType];
  const [selectedLog, setSelectedLog] = useState(null);
  const {
    isOpen: isShowModalOpen,
    onClose: closeShowModal,
    onOpen: openShowModal,
  } = useDisclosure();
  const [selectedJson, setSelectedJson] = useState(null);
  const {
    isOpen: isCallDetailsModal,
    onClose: closeCallDetailsModal,
    onOpen: openCallDetailsModal,
  } = useDisclosure();
  const onOpenCallDetailsModal = useCallback((logJson) => {
    setSelectedJson(logJson);
    openCallDetailsModal();
  }, []);
  const onCloseCallDetailsModal = useCallback(() => {
    setSelectedJson(null);
    closeCallDetailsModal();
  }, []);
  const onOpenShowModal = useCallback(
    (log) => {
      setSelectedLog(log);
      openShowModal();
    },
    [selectedLog]
  );
  const onCloseShowModal = useCallback(() => {
    setSelectedLog(null);
    closeShowModal();
  }, []);
  const {
    isOpen: isDeleteAlertOpen,
    onClose: closeDeleteAlert,
    onOpen: openDeleteAlert,
  } = useDisclosure();

  const onDeleteLog = useCallback((log) => {
    setSelectedLog(log);
    openDeleteAlert();
  });
  const { paginator, response, isLoading, fetchLogs, deleteLog, status } =
    useLogs({
      logType: selectedLogType,
      closeDeleteAlert,
    });
  const totalPages = response?.data?.pages;
  const logs = response?.data?.items || [];
  return (
    <Layout>
      <Box border="1px" h="91vh" overflowY="auto" p="5" borderRadius="md">
        <Stack spacing={4}>
          <Heading fontSize={"xl"} color={"#3182ce"}>
            {heading}
          </Heading>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {logs.length ? (
                <LogsTable
                  logs={logs}
                  onOpenCallDetailsModal={onOpenCallDetailsModal}
                  onOpenShowModal={onOpenShowModal}
                  onDeleteLog={onDeleteLog}
                />
              ) : (
                <NoLogsFound />
              )}
              <Flex justifyContent={"flex-end"} alignItems={"center"}>
                <Paginator
                  onChangePage={paginator.onSetPage}
                  page={paginator.page}
                  pages={totalPages}
                />
              </Flex>
            </>
          )}
        </Stack>
      </Box>
      {selectedLog && isShowModalOpen ? (
        <ShowModal
          selectedLog={selectedLog}
          isOpen={isShowModalOpen}
          onClose={onCloseShowModal}
        />
      ) : null}
      {selectedJson && isCallDetailsModal ? (
        <LogJsonModal
          isOpen={isCallDetailsModal}
          onClose={onCloseCallDetailsModal}
          selectedJson={selectedJson}
        />
      ) : null}
      {selectedLog && isDeleteAlertOpen ? (
        <LogDeleteAlert
          isOpen={isDeleteAlertOpen}
          onClose={closeDeleteAlert}
          selectedLog={selectedLog}
          onConfirmDelete={deleteLog}
          status={status}
        />
      ) : null}
    </Layout>
  );
}
