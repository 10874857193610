import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import { Background, Controls, ReactFlow } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import Layout from "../Layout/Layout";
import NodeModal from "./NodeModal";
import useAppFlowNodes from "./useAppFlowNodes";
import useNodeForm from "./useNodeForm";
import OperationalHeader from "./OperationalHeader";
import InstructionModal from "../Modals/InstructionModal";
import FlowInstructionsModal from "./FlowInstructionModal";

export default function ConversationFlow() {
  const { onConnect, onEdgesChange, onNodesChange, edges, nodes, operations } =
    useAppFlowNodes();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isInstrctonModalOpen,
    onOpen: openInstructionModal,
    onClose: closeInstructionModal,
  } = useDisclosure();
  const nodeForm = useNodeForm({
    operations,
    onCloseForm: onClose,
  });
  return (
    <Layout>
      <Box w={"100%"} overflowY={"auto"} h={"90svh"}>
        <OperationalHeader onOpen={onOpen} />
        <ReactFlow
          nodes={nodes}
          onNodeDoubleClick={(_, node) => {
            nodeForm.form.reset(node);
            onOpen();
          }}
          onNodesChange={onNodesChange}
          onEdgeDoubleClick={(_, edge) => {
            operations.onDeleteEdge(edge.id);
          }}
          edges={edges}
          onConnect={onConnect}
          onEdgesChange={onEdgesChange}
          fitView
        >
          <Background />
          <Controls />
        </ReactFlow>
        <Box p={2}>
          <Button colorScheme="yellow" onClick={openInstructionModal}>
            Instructions
          </Button>
        </Box>
      </Box>
      <NodeModal
        isOpen={isOpen}
        onClose={onClose}
        operations={operations}
        nodeForm={nodeForm}
      />
      <FlowInstructionsModal
        isOpen={isInstrctonModalOpen}
        onClose={closeInstructionModal}
      />
    </Layout>
  );
}
