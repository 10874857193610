import "./Message.css";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  theme,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  BsFillTelephoneInboundFill,
  BsFillTelephoneOutboundFill,
} from "react-icons/bs";

const Summary = ({ conversationChatData }) => {
  const [attributeJSON, setAttributeJSON] = useState(null);

  useEffect(() => {
    if(conversationChatData?.attributes && typeof(conversationChatData?.attributes) === "string") {
      try {
        const attributes = JSON.parse(conversationChatData?.attributes);
        setAttributeJSON(attributes);
      } catch (e) {
        console.error(e);
      }
    } else {
      setAttributeJSON(conversationChatData?.attributes)
    }
  }, [conversationChatData]);

  const boatMsgBgColor = useColorModeValue("#E8E8E8", theme.colors.gray[700]);

  const attributeLabel = {
    call_status: "Call Status",
    call_duration: "Call Duration",
    call_direction: "Call Direction",
    ip: "IP",
  };

  const formatKey = (key) => attributeLabel[key] || key.replace("_", " ");

  const renderAttributeValue = (key, value) => {
    if (key === "call_direction") {
      return (
        <Tooltip label={value}>
          <Box as="span">
            <Icon
              as={
                value === "inbound"
                  ? BsFillTelephoneInboundFill
                  : BsFillTelephoneOutboundFill
              }
              color="blue"
            />
          </Box>
        </Tooltip>
      );
    } else {
      return value;
    }
  };

  return (
    <div className={"message"}>
      <Box width={"100%"}>
        <Box
          className={"conversation_attributes"}
          bg={boatMsgBgColor}
          width={"100%"}
        >
          {attributeJSON && (
            <>
              <Heading size={"sm"}>Conversation Metadata</Heading>
              <Stack spacing={2} p={"0.5rem"}>
                {Object.entries(attributeJSON).map(([key, value]) => (
                  <Flex alignItems={"center"} gap={1} key={`${key}_${value}`}>
                    <Text fontSize='sm'>
                      <Box
                        as={"span"}
                        fontWeight={600}
                        textTransform={"capitalize"}
                      >
                        {`${formatKey(key)}: `}
                      </Box>

                      <Box as={"span"}>{renderAttributeValue(key, value)}</Box>
                    </Text>
                  </Flex>
                ))}
              </Stack>
            </>
          )}

          {conversationChatData?.summary && (
            <>
              <Heading size={"sm"}>Summary</Heading>
              <Box p={"0.5rem"}>
                <Text fontSize='sm'>{conversationChatData.summary}</Text>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Summary;
