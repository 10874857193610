import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaRegCircleDot } from "react-icons/fa6";
import { TbUsers } from "react-icons/tb";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import Layout from "../Layout/Layout";
import AgentChatWidget from "./AgentChatWidget";
import LiveUsers from "./LiveUsers";
import SelectAssistant from "./SelectAssistant";
import useLiveAgentSocket from "./useLiveAgentSocket";
import { getHostDomain } from "../../utils/utils";

export default function LiveAgentChats() {
  const [assistant, setAssistant] = useState();
  const [activeUsers, setActiveUsers] = useState({});

  const [loading, setLoading] = useState(false);
  const [dropdownDisable, setDropdownDisable] = useState(false);

  const checkBillingStatus = async () => {
    const baseUrl = getHostDomain();
    setLoading(true);
    const userActiveBillingPlanId = localStorage.getItem(
      "current_active_billing_plan_id"
    );

    if (userActiveBillingPlanId) {
      const response = await fetchController(
        baseUrl + `/api/v1/billing/billing_plan/${userActiveBillingPlanId}`,
        "GET"
      );
      const userBillingPlan = response?.data;
      setDropdownDisable((userBillingPlan.credits.human_agents || 0) <= 0);
    }
    setLoading(false);
  };

  useEffect(() => {
    checkBillingStatus();
  }, []);

  const updateUserMessages = ({ user, message }) => {
    setActiveUsers((prev) => ({
      ...prev,
      [user]: {
        ...prev[user],
        messages: [...prev[user].messages, message],
      },
    }));
  };

  const onSetActiveUsersList = (response) => {
    setActiveUsers(
      response.users.reduce(
        (activeUserMessageMap, userId) => ({
          ...activeUserMessageMap,
          [userId]: {
            messages: [],
            accepted: false,
            active: true,
          },
        }),
        {}
      )
    );
  };
  const onUserDeactivate = (response) => {
    setActiveUsers((prev) => ({
      ...prev,
      [response.user_id]: {
        ...prev[response.user_id],
        active: false,
      },
    }));
  };
  const onAddNewActiveUser = async (response) => {
    setActiveUsers((prev) => ({
      ...prev,
      [response.user_id]: {
        messages: response.chat_history.map((chat) => ({
          sentBy: chat.role,
          message: chat.content,
        })),
        accepted: false,
        active: true,
      },
    }));
    const tone = new Audio("/new-user-tone.mp3");
    await tone.play();
  };
  const onAddMessagesToActiveUser = async (response) => {
    setActiveUsers((prev) => ({
      ...prev,
      [response.user_id]: {
        ...prev[response.user_id],
        messages: [
          ...prev[response.user_id].messages,
          ...response.chat_history.map((chat) => ({
            sentBy: chat.role,
            message: chat.content,
          })),
        ],
      },
    }));
  };
  const onAddReceivedMessage = (response) => {
    if (!currentUser && !response.user_id) return;

    const user_id = response.user_id || currentUser.user;
    setActiveUsers((prev) => ({
      ...prev,
      [user_id]: {
        ...prev[user_id],
        messages: [
          ...prev[user_id].messages,
          { message: response.message, sentBy: user_id },
        ],
      },
    }));
  };
  const { status, acceptNewUser, sendMessage } = useLiveAgentSocket({
    assistant,
    onSetActiveUsersList,
    onAddNewActiveUser,
    onAddReceivedMessage,
    updateUserMessages,
    onUserDeactivate,
    onAddMessagesToActiveUser,
  });
  const onSetCurrentUser = (newUserItem) => {
    setCurrentUser(newUserItem);
    if (!activeUsers[newUserItem.user]?.accepted) {
      acceptNewUser({
        user: newUserItem.user,
      });
      setActiveUsers({
        ...activeUsers,
        [newUserItem.user]: {
          ...activeUsers[newUserItem.user],
          accepted: true,
        },
      });
    }
  };
  const connected = status === "connected";
  const [currentUser, setCurrentUser] = useState(null);
  const currentUserProps = {
    currentUser,
    onSetCurrentUser,
  };
  const messageList =
    currentUser && activeUsers[currentUser.user]
      ? activeUsers[currentUser.user].messages
      : [];
  const sendMessageToUser = (userId) => (message) => {
    sendMessage({
      user_id: userId,
      message,
    });
    setActiveUsers((prev) => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        messages: [...prev[userId].messages, { message, sentBy: "agent" }],
      },
    }));
  };
  const users = Object.keys(activeUsers);
  const chatWindowHeading = users.indexOf(currentUser?.user) + 1;
  return (
    <Layout>
      <Box border="1px" h="90svh" overflowY="auto" p="5" borderRadius="md">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Tooltip
              isDisabled={!dropdownDisable}
              label="Please upgrade to Platinum or above"
              placement="bottom-start"
            >
              <FormControl cursor={"pointer"}>
                <FormLabel>Select Assistant</FormLabel>
                <SelectAssistant
                  assistant={assistant}
                  onChangeAssistant={({ value }) => {
                    setAssistant(value);
                  }}
                  disable={dropdownDisable}
                />
              </FormControl>
            </Tooltip>
            {assistant ? (
              <Stack marginBlock={4} spacing={2}>
                <Flex
                  gap={3}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Heading fontSize={"xl"}>
                    {" "}
                    Live Human Agent Conversations
                  </Heading>
                  <FaRegCircleDot
                    title={connected ? "Connected" : "Not connected"}
                    size={20}
                    color={connected ? "green" : "red"}
                  />
                </Flex>
                {Object.keys(activeUsers).length ? (
                  <Grid
                    marginBlock={2}
                    gap={3}
                    gridTemplateColumns={"300px 1fr"}
                  >
                    <Box maxH={"70svh"} overflowY={"auto"}>
                      <LiveUsers
                        activeUsers={activeUsers}
                        currentUserProps={currentUserProps}
                      />
                    </Box>
                    {currentUser ? (
                      <Box paddingLeft={3} borderLeft={"1px solid lightgray"}>
                        <AgentChatWidget
                          messages={messageList}
                          chatWindowHeading={chatWindowHeading}
                          connected={connected}
                          sendMessage={sendMessageToUser(currentUser.user)}
                        />
                      </Box>
                    ) : null}
                  </Grid>
                ) : (
                  <Flex
                    gap={8}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDir={"column"}
                  >
                    <Box p={3} bg={"white"} borderRadius={"full"}>
                      <TbUsers size={50} />
                    </Box>
                    <Heading fontSize={"md"}>No users found</Heading>
                  </Flex>
                )}
              </Stack>
            ) : null}
          </>
        )}
      </Box>
    </Layout>
  );
}
