import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { fetchController } from "../../utils/FetchController/fetchController";
import { countryList } from "../../utils/CountryList/CountryList";
import { toast } from "react-toastify";
import TelephoneContactField from "../Contacts/TelephoneContactField";
import {
  AddIcon,
  CheckIcon,
  EditIcon,
  SmallAddIcon,
  SmallCloseIcon,
} from "@chakra-ui/icons";
import { getHostDomain } from "../../utils/utils";

const AddContact = ({
  isOpen,
  onGotofirstPage,
  onOpen,
  onClose,
  fetchContact,
  contactToEdit,
}) => {
  const baseUrl = getHostDomain();
  const finalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState("");

  const [isCustomFieldOpen, setIsCustomFieldOpen] = useState(true);
  const [customFields, setCustomFields] = useState([]);
  const [addedCustomFields, setAddedCustomFields] = useState({});

  const defaultContact = {
    whatsapp: "",
    first_name: "",
    last_name: "",
    email: "",
    instagram: "",
    phoneNumber: "",
    microsoft_teams: "",
    web: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: defaultContact,
  });

  const onSubmit = async (data) => {
    setFormError(""); // Reset form error

    try {
      if (
        !data.whatsapp &&
        !data.instagram &&
        !data.microsoft_teams &&
        !data.web &&
        !data.email &&
        !(data.countryCode && data.phoneNumber)
      ) {
        setFormError(
          "Add at least one from WhatsApp, Instagram, Microsoft Teams, Web, Email, or Phone Number"
        );
        setIsLoading(false);
        return;
      }

      const endpoint = data.id
        ? `/api/v1/contact/${data.id}`
        : "/api/v1/contact";
      const urlHit = baseUrl + endpoint;

      const customFields = {};
      Object.keys(addedCustomFields).map((key) => {
        customFields[key] = addedCustomFields[key].value;
      });

      const body = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        last_seen: new Date().toISOString(),
        last_sent: new Date().toISOString(),
        channels: {
          whatsapp: data.whatsapp || null,
          web: data.web || null,
          instagram: data.instagram || null,
          telephone: `${data.phoneNumber}` || null,
          microsoft_teams: data.microsoft_teams || null,
          dial_code: data.countryCode,
        },
        custom_fields: customFields,
      };
      setIsLoading(true);

      const method = data.id ? "PUT" : "POST";
      await fetchController(urlHit, method, body);

      toast.success(data.id ? "Contact Updated" : "Contact Added");
      !data.id && reset();
      fetchContact();
      if (onGotofirstPage) onGotofirstPage();
      !data.id && onClose();
    } catch (error) {
      console.error(error);
      toast.error("Some error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (contactToEdit) {
      if (Object.keys(contactToEdit?.custom_fields || {}).length) {
        const obj = Object.keys(contactToEdit.custom_fields).reduce(
          (acc, item) => {
            acc[item] = {
              value: contactToEdit.custom_fields[item],
              isDisabled: true,
            };
            return acc;
          },
          {}
        );

        setAddedCustomFields(obj);
      }

      setValue("email", contactToEdit.email || "");
      setValue("first_name", contactToEdit.first_name || "");
      setValue("last_name", contactToEdit.last_name || "");
      setValue("instagram", contactToEdit.channels?.instagram || "");
      setValue(
        "microsoft_teams",
        contactToEdit.channels?.microsoft_teams || ""
      );

      const country = countryList.find(
        (country) => country.code === contactToEdit.channels?.dial_code
      );

      setValue("phoneNumber", contactToEdit?.channels?.telephone);
      setValue("countryCode", country?.code);

      setValue("web", contactToEdit.channels?.web || "");
      setValue("whatsapp", contactToEdit.channels?.whatsapp || "");
      setValue("id", contactToEdit.id || "");
    } else {
      setValue("email", "");
      setValue("first_name", "");
      setValue("last_name", "");
      setValue("instagram", "");
      setValue("microsoft_teams", "");
      setValue("phoneNumber", "");
      setValue("web", "");
      setValue("whatsapp", "");
      setValue("id", undefined);
    }
  }, [contactToEdit]);

  const getCustomFields = async () => {
    const endpoint = "/api/v1/contact_custom_field/list";
    const urlHit = baseUrl + endpoint;
    try {
      const response = await fetchController(urlHit, "GET");
      setCustomFields(response?.data?.items);
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    getCustomFields();
  }, []);

  const handleCustomFieldSelect = (oldItem, data) => {
    let obj = { ...addedCustomFields };
    delete obj[oldItem];
    obj[data.value] = { value: "", isDisabled: false, type: data.type };

    setAddedCustomFields(obj);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let newFields = { ...addedCustomFields };
    newFields[name].value = value;
    setAddedCustomFields(newFields);
  };

  const handleAddCustomField = (id) => {
    let newFields = { ...addedCustomFields };

    delete newFields[""];
    newFields[id].isDisabled = true;

    setAddedCustomFields(newFields);
    setIsCustomFieldOpen(true);
  };

  const handleCustomFieldEdit = (id) => {
    let newFields = { ...addedCustomFields };
    newFields[id].isDisabled = false;
    setAddedCustomFields(newFields);
    setIsCustomFieldOpen(false);
  };

  const handleClose = () => {
    setIsCustomFieldOpen(true);
    setCustomFields([]);
    setAddedCustomFields({});
    onClose();
  };

  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          setValue("name", "");
          handleClose();
        }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent height="auto" width="95%" maxWidth="800px">
          <ModalHeader>
            {watch("id") ? "Update Contact" : "Add Contact"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" mb="4">
                <Box flex="1">
                  <Input placeholder="First Name" {...register("first_name")} />
                </Box>
                <Box flex="1">
                  <Input placeholder="Last Name" {...register("last_name")} />
                </Box>
              </Stack>
              <Box flex="1">
                <Input
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                />
                {errors.email && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    {errors.email.message || "Email is required"}
                  </p>
                )}
              </Box>
              <Box mt={3}>
                {" "}
                <FormLabel>Channels</FormLabel>
              </Box>
              <Stack direction="row" mb="4">
                <Box flex="1">
                  <Input placeholder="WhatsApp" {...register("whatsapp")} />
                </Box>
                <Box flex="1">
                  <Input placeholder="Instagram" {...register("instagram")} />
                </Box>
              </Stack>
              <Stack direction="row" mb="4">
                <Box flex="1">
                  <Input placeholder="Web" {...register("web")} />
                </Box>
                <Box flex="1">
                  <Input
                    placeholder="Microsoft Teams"
                    {...register("microsoft_teams")}
                  />
                </Box>
              </Stack>
              <Stack spacing={0} direction={"row"}>
                <TelephoneContactField errors={errors} register={register} />
              </Stack>

              <Box mt={3} display={"flex"}>
                <FormLabel>Custom fields</FormLabel>
                <IconButton
                  isRound={true}
                  colorScheme="blue"
                  variant="outline"
                  aria-label="Add Field"
                  icon={<AddIcon />}
                  size={"xs"}
                  title={
                    !customFields.length
                      ? "Create Custom field first"
                      : "Add new field"
                  }
                  isDisabled={
                    Object.keys(addedCustomFields).length ===
                      customFields.length || !customFields.length
                      ? true
                      : isCustomFieldOpen === false
                  }
                  onClick={() => {
                    setIsCustomFieldOpen(false);
                    setAddedCustomFields((prevState) => ({
                      ...prevState,
                      "": "",
                    }));
                  }}
                />
              </Box>
              <Flex gap={2} direction="column">
                {(!isCustomFieldOpen ||
                  Object.keys(addedCustomFields).length > 0) && (
                  <>
                    {Object.keys(addedCustomFields).map((item, index) => (
                      <Stack
                        spacing={0}
                        direction={"row"}
                        alignItems={"center"}
                        key={item + index}
                      >
                        <Box width={"50%"}>
                          <Select
                            onChange={(e) => handleCustomFieldSelect(item, e)}
                            options={customFields.map((option) => ({
                              value: option.id,
                              label: option.custom_field_name,
                              isDisabled: addedCustomFields[option.id]
                                ? true
                                : false,
                              type: option.custom_field_type,
                            }))}
                            value={
                              addedCustomFields[item]
                                ? {
                                    value: item,
                                    label: customFields.filter(
                                      (o) => o.id === item
                                    )[0]?.custom_field_name,
                                  }
                                : { value: "", label: "Select..." }
                            }
                            isDisabled={addedCustomFields[item]?.isDisabled}
                          />
                        </Box>
                        <Input
                          placeholder="Name"
                          onChange={handleInputChange}
                          name={item}
                          value={addedCustomFields[item]?.value || ""}
                          isDisabled={addedCustomFields[item] === "" || addedCustomFields[item]?.isDisabled}
                          type={
                            addedCustomFields[item]?.type === "string"
                              ? "text"
                              : "number"
                          }
                          ml={2}
                        />
                        <IconButton
                          isRound={true}
                          colorScheme="blue"
                          variant="outline"
                          aria-label="Add"
                          icon={<CheckIcon />}
                          size={"xs"}
                          title="Add"
                          ml={2}
                          onClick={() => handleAddCustomField(item)}
                          isDisabled={
                            !addedCustomFields[item]?.value ||
                            addedCustomFields[item].isDisabled
                          }
                        />
                        <IconButton
                          isRound={true}
                          colorScheme="blue"
                          variant="outline"
                          aria-label="update"
                          icon={<EditIcon />}
                          size={"xs"}
                          title="Edit"
                          ml={2}
                          isDisabled={!addedCustomFields[item]?.isDisabled}
                          onClick={() => handleCustomFieldEdit(item)}
                        />
                      </Stack>
                    ))}
                  </>
                )}
              </Flex>

              <Button
                isLoading={isLoading}
                colorScheme="blue"
                type="submit"
                mt="4"
              >
                Submit
              </Button>

              {formError && (
                <Box color="red" mt="4">
                  {formError}
                </Box>
              )}
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddContact;
