import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";

export default function useRecaptcha() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getRecaptchaToken = async (action) => {
    if (!executeRecaptcha) {
      toast.error("reCAPTCHA verification failed");
      return;
    }

    const token = await executeRecaptcha(action);
    return token;
  };

  return getRecaptchaToken
}
