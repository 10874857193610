import { Box, Text } from "@chakra-ui/react";
import { formatDateWithAmPm } from "../../../utils/DateFormat/dateFormatWithTimeAmPm";

export default function WebhookLog({ webhookLog }) {
  const body = JSON.stringify(webhookLog.body, null, 2);
  return (
    <Box>
      <Text>
        <strong>Event Name :</strong> {webhookLog.event_name}
      </Text>
      <Text>
        <strong>Webhook ID :</strong> {webhookLog.webhook_id}
      </Text>
      <Text>
        <strong>Log ID :</strong> {webhookLog.id}
      </Text>
      <Text>
        <strong>Created At :</strong> {formatDateWithAmPm(webhookLog.created_at)}
      </Text>
      {webhookLog.response_code ? (
        <Text
          title="Response code"
          color={webhookLog.response_code < 400 ? "green" : "red"}
        >
          HTTP Status Code {webhookLog.response_code}
        </Text>
      ) : null}
      {webhookLog.body ? (
        <Box
          marginBlock={3}
          paddingBlock={2}
          borderBlock={"1px solid lightgray"}
        >
          <Text fontWeight={"bold"}>Response Body : </Text>
          <pre
            style={{
              fontSize: "small",
            }}
          >
            {body}
          </pre>
        </Box>
      ) : null}
    </Box>
  );
}
