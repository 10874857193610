import { Box, Button, Flex, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import WebhookModal from "./WebhookModal";
import useWebhooks from "./useWebhooks";
import Table from "../../Tables/Table";
import Loader from "../../../utils/Loader/Loader";
import { useContext } from "react";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
export default function Webhooks() {
  const { fetchWebhooks, webhookTable, status } = useWebhooks();
  const {
    isOpen,
    onOpen: openWebhookModal,
    onClose: closeWebhookModal,
  } = useDisclosure();
  const loading = status === "loading";
  const agency=useContext(AgencyContext)
  const { textColor, buttonColorScheme, colorPreset } = agency;
  return (
    <Box
      border="1px"
      h="81vh"
      overflowY="auto"
      p="5"
      borderRadius="md"
    >
      <Flex justifyContent={"flex-end"} alignItems={"center"}>
        <Button
          colorScheme={buttonColorScheme}
          onClick={() => openWebhookModal()}
          size={"sm"}
        >
          Add Webhook
        </Button>
      </Flex>
      <WebhookModal
        isOpen={isOpen}
        fetchWebhooks={fetchWebhooks}
        onClose={closeWebhookModal}
      />
      <Box marginBlock={2}>
        {loading ? (
          <Loader />
        ) : (
          <Table
            columns={webhookTable.heads}
            table={"webhooks"}
            fetch={fetchWebhooks}
            data={webhookTable.tableData}
          />
        )}
      </Box>
    </Box>
  );
}
