import { Tooltip } from "@chakra-ui/react";
import { BsDatabase, BsDatabaseCheck, BsDatabaseExclamation } from "react-icons/bs";
import { TbDatabaseCog } from "react-icons/tb";
import { VscDatabase } from "react-icons/vsc";
export default function DataSourceStatusIcon({ ds_status }) {
  const iconProperties = {
    empty: { label: "Empty", children: <VscDatabase /> },
    initilized: { label: "Initializing", children: <BsDatabase /> },
    in_process: { label: "Indexing", children: <TbDatabaseCog /> },
    ready_for_use: { label: "Ready to use", children: <BsDatabaseCheck color="green"/> },
    failed: {
      label: "Failed",
      children: <BsDatabaseExclamation color="red" />,
    },
  };
  return (
    <Tooltip label={iconProperties[ds_status].label || ""}>
      <div style={{ marginLeft: "8px" }}>
        {iconProperties[ds_status].children || undefined}
      </div>
    </Tooltip>
  );
}
