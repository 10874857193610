import {
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import { AddIcon } from "@chakra-ui/icons";
import { Fragment } from "react";
import PropertyFields from "../PropertyFields";
import { getHostDomain } from "../../../utils/utils";

export default function CreateToolFunctionModal({
  isOpen,
  onClose,
  toolId,
  loadToolFunction,
  preparePayloadDetails,
}) {
  const baseUrl = getHostDomain();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      method: "GET",
      endPoint: "",
      contentType: "",
      functionName: "",
      functionDescription: "",
      properties: [{ name: "", type: "", description: "", isRequired: true }],
    },
  });

  const onSubmit = async (data) => {
    try {
      const payloadDetails = preparePayloadDetails(data);

      const response = await fetchController(
        baseUrl + "/api/v1/toolfunction",
        "POST",
        {
          name: data.functionName,
          description: data.functionDescription,
          tool_function_type: "curl",
          details: payloadDetails,
          tool_id: toolId,
          is_enabled: true,
        }
      );
      toast.success("Tool function created successfully");
      reset();
      onClose();
      loadToolFunction();
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong");
    }
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent maxWidth="1200px">
        <ModalHeader>Create Tool function</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Flex gap={4}>
              <Stack width={"50%"}>
                <FormLabel>Endpoint</FormLabel>
                <Stack spacing={0} direction={"row"}>
                  <Select
                    borderRight={0}
                    borderRightRadius={0}
                    {...register("method")}
                    width={"20%"}
                    variant="filled"
                  >
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                  </Select>

                  <Input
                    placeholder={"Enter endpoint"}
                    {...register("endPoint", {
                      required: "Endpoint is required",
                    })}
                    borderLeft={0}
                    borderLeftRadius={0}
                  />
                </Stack>
                {errors.endPoint && (
                  <Text color={"red"}>{errors.endPoint.message}</Text>
                )}

                <FormControl isInvalid={errors.contentType}>
                  <FormLabel>Content Type</FormLabel>
                  <Select
                    {...register("contentType", {
                      required: "Content type is required",
                    })}
                  >
                    <option value={""}>Select...</option>
                    <option value="application/json">application/json</option>
                    <option value="application/x-www-form-urlencoded">
                      application/x-www-form-urlencoded
                    </option>
                  </Select>
                  {errors.contentType && (
                    <FormErrorMessage>
                      {errors.contentType.message}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={errors.functionName}>
                  <FormLabel>Function Name</FormLabel>
                  <Input
                    placeholder={"Enter tool function name"}
                    {...register("functionName", {
                      required: "Function name is required",
                    })}
                  />
                  {errors.functionName && (
                    <FormErrorMessage>
                      {errors.functionName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={errors.functionDescription}>
                  <FormLabel>Function Description</FormLabel>
                  <Textarea
                    placeholder={"Enter tool function description"}
                    {...register("functionDescription", {
                      required: "Function description is required",
                    })}
                  />
                  {errors.functionDescription && (
                    <FormErrorMessage>
                      {errors.functionDescription.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Stack>

              <Stack width={"50%"}>
                <Box display={"flex"}>
                  <FormLabel>Properties</FormLabel>
                  <IconButton
                    isRound={true}
                    colorScheme="blue"
                    variant="outline"
                    aria-label="Add Field"
                    icon={<AddIcon />}
                    size={"xs"}
                    title={"Add new field"}
                    onClick={() => {
                      setValue("properties", [
                        ...getValues("properties"),
                        {
                          name: "",
                          type: "",
                          description: "",
                          isRequired: true,
                        },
                      ]);
                    }}
                  />
                </Box>

                <Card
                  variant={"elevated"}
                  maxHeight={"70svh"}
                  overflowY={"auto"}
                >
                  <CardBody px={4} py={0}>
                    {watch("properties")?.map(
                      (_, index) => (
                        <Fragment key={index}>
                          <PropertyFields settings={{register, errors, watch, setValue}} index={index} />
                          <Divider />
                        </Fragment>
                      )
                    )}
                  </CardBody>
                </Card>
              </Stack>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent={"flex-end"} gap={2}>
              <Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
                Create
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
