import { Box, Button, Flex, Link, Text, useDisclosure } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FaCode, FaDatabase } from "react-icons/fa";
import { MdAssistant, MdKeyboardVoice } from "react-icons/md";
import { SiJquery, SiTheconversation } from "react-icons/si";
import AnalyticsCard from "../../utils/AnalyticsCard/AnalyticsCard";
import WaveChart from "../../utils/Charts/ConversationChart";
import dateFormat from "../../utils/DateFormat/dateFormat";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import AuthContext from "../Auth/AuthContext";
import Layout from "../Layout/Layout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { WrappedModal } from "../WithInstructionModal/WrappedModal";
import Announcements from "./Announcements";
import DashboardTour from "./DashboardTour";
import { getHostDomain } from "../../utils/utils";
import CustomDateRangePicker from "../../utils/CustomDateRange/CustomDateRangePicker";
import moment from "moment";
import CalendlyWidgetModal from "./CalendlyWidgetModal";

const Dashboard = () => {
  const baseUrl = getHostDomain();
  const { authUser } = useContext(AuthContext);
  const [chats, setChats] = useState([]);
  const [analytics, setAnalytics] = useState();
  const [loading, setLoading] = useState(true);
  const endpoint = "/api/v1/analytics";
  const urlHit = baseUrl + endpoint;

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(7, "d").toDate(),
    endDate: moment().toDate(),
  });

  const handleDateChange = (range) => {
    setDateRange({
      startDate: range.startDate,
      endDate: range.endDate,
    });
  };

  const fetchAnalyticsData = async () => {
    setLoading(true);
    try {
      const getData = await fetchController(urlHit, "GET");
      if (getData) {
        setAnalytics(getData.data);
      }
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setLoading(false);
    }
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg, agencyData, myDetails } =
    agency;
  const isAgencyLoaded = !agency.loading && !myDetails?.agency_id;

  useEffect(() => {
    fetchAnalyticsData();
  }, [authUser]);

  useEffect(() => {
    if (!localStorage.getItem("calendly")) onCalendlyToggle();
    const fetchData = async () => {
      const accessToken = localStorage.getItem("token");
      if (accessToken) {
        const getData = await fetchController(
          `${baseUrl}/api/v1/analytics/chat_timeseries_daterange?date_from=${dateFormat(
            dateRange.startDate
          )}&date_to=${dateFormat(dateRange.endDate)}`,
          "GET"
        );
        setChats(getData);
      }
    };

    fetchData();
  }, [dateRange]);

  const [dashboardTourVisibility, setDashboardTourVisibility] = useState(
    localStorage.getItem("dashTourVisibility") ? false : true
  );
  const onCloseDashtour = () => {
    localStorage.setItem("dashTourVisibility", false);
    setDashboardTourVisibility(false);
  };
  const { onToggle: onCalendlyToggle, isOpen: isCalendlyOpen } =
    useDisclosure();
  const toggleCalendly = () => {
    localStorage.setItem("calendly", !isCalendlyOpen);
    onCalendlyToggle();
  };

  return (
    <Layout>
      {loading && <Loader />}
      <Box border="1px" h="90svh" p="5" overflowY={"auto"} borderRadius="md">
        <Text fontSize="xl" fontWeight="bold" color={textColor} p={2}>
          Hi{" "}
          {authUser?.first_name ||
            authUser?.name?.split(" ")[0] ||
            authUser?.nickname ||
            authUser?.given_name ||
            authUser?.email}
          !
        </Text>
        {dashboardTourVisibility ? (
          <DashboardTour onCloseDashtour={onCloseDashtour} />
        ) : null}
        <Flex mt={3} gap={1}>
          <Box flex={6}>
            <Box
              width="100%"
              p={3}
              border={"none"}
              borderRadius="xl"
              boxShadow="md"
              background={cardBg}
            >
              <Flex gap={3} justifyContent={"space-between"}>
                <Text
                  fontSize="xl"
                  fontWeight={"bold"}
                  color={textColor}
                  mb="10px"
                  flex={1}
                >
                  Conversations per day
                </Text>

                <Flex flex={1} justifyContent={"end"}>
                  <CustomDateRangePicker
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    onChange={handleDateChange}
                  />
                </Flex>
              </Flex>

              <WaveChart chats={chats} />
            </Box>
          </Box>

          <Box flex={6}>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              flexWrap={"wrap"}
              flex={6}
              gap={4}
            >
              <AnalyticsCard
                title="Assistants"
                value={analytics?.assistants_count}
                color="green"
                width="calc(50% - 12px)" // Adjust the width based on your design
                icon={MdAssistant}
              />
              <AnalyticsCard
                title="Data Sources"
                value={analytics?.data_sources_count}
                color="pink"
                width="calc(50% - 12px)" // Adjust the width based on your design
                icon={FaDatabase}
              />
              <AnalyticsCard
                title="Widgets"
                value={analytics?.widgets_count}
                color="red"
                width="calc(50% - 12px)" // Adjust the width based on your design
                icon={FaCode}
              />
              <AnalyticsCard
                title="Conversations"
                value={analytics?.conversations_count}
                color="grey"
                width="calc(50% - 12px)" // Adjust the width based on your design
                // icon={<SiTheconversation size={30} color="#1675e0" />}
                icon={SiTheconversation}
              />
              <AnalyticsCard
                title="Queries"
                value={analytics?.queries_count}
                width="calc(50% - 12px)" // Adjust the width based on your design
                icon={SiJquery}
              />
              <AnalyticsCard
                title="Voice Queries"
                value={analytics?.voice_queries_count}
                color="orange"
                width="calc(50% - 12px)" // Adjust the width based on your design
                icon={MdKeyboardVoice}
              />
            </Flex>
          </Box>
        </Flex>
        <Flex
          justifyContent={"flex-end"}
          alignItems={"center"}
          mt={6}
          color="#00008B"
        >
          {isAgencyLoaded && (
            <>
              <Button colorScheme={buttonColorScheme}>
                <Link
                  // colorScheme="blue"
                  // colorScheme={buttonBgColor}
                  target="_blank"
                  href={`mailto:${
                    myDetails?.branding?.agency_mail || "support@insighto.ai"
                  }`}
                  style={{ textDecoration: "none" }}
                >
                  Queries? We’re here to help!
                </Link>
              </Button>
            </>
          )}
        </Flex>
        {isCalendlyOpen && isAgencyLoaded && (
          <CalendlyWidgetModal
            isOpen={isCalendlyOpen}
            onClose={toggleCalendly}
          />
        )}
      </Box>
    </Layout>
  );
};

export default WrappedModal(Dashboard);
