import { Box, Grid, Tag, TagLabel, TagLeftIcon, Text } from "@chakra-ui/react";
import { IoMdClose } from "react-icons/io";

export default function DaySlots({
  onRemoveAvailability,
  slots,
  day,
  onClickDay,
}) {
  return (
    <Grid gap={2}>
      <Box
        bg={"gray.100"}
        p={1}
        cursor={"pointer"}
        onClick={onClickDay}
        textAlign={"center"}
        boxShadow={"md"}
        fontWeight={"bold"}
        borderRadius={"md"}
        border={"1px solid lightgray"}
      >
        <Text textTransform={"uppercase"}>{day}</Text>
      </Box>
      {slots.map((slot, slotIndex) => (
        <Tag size={"sm"} key={slotIndex}>
          <TagLeftIcon
            boxSize="12px"
            as={IoMdClose}
            onClick={() => {
              onRemoveAvailability(day, slotIndex);
            }}
          />
          <TagLabel>{slot}</TagLabel>
        </Tag>
      ))}
    </Grid>
  );
}
