import axios from "axios";
import { getHostDomain } from "../utils";

const axiosInstance = axios.create({
  baseURL: getHostDomain(),
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 403) {
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        try {
          const refreshedTokenResponse = await axios.post(
            `${getHostDomain()}/api/v1/login/refresh_token`,
            { refresh_token: refreshToken }
          );
          const access_token = refreshedTokenResponse.data.data.access_token;
          localStorage.setItem(
            "token",
            refreshedTokenResponse.data.data.access_token
          );
          originalRequest.headers.Authorization = `Bearer ${access_token}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

export const fetchController = async (
  endPoint,
  method,
  body,
  customHeader = null
) => {
  try {
    const accessToken = localStorage.getItem("token");
    const fetchOptions = {
      method: method,
      headers: customHeader || {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    if (body) {
      fetchOptions.data = body;
    }

    const hitFetch = await axiosInstance(endPoint, fetchOptions);
    const data = hitFetch.data;
    return data;
  } catch (error) {
    throw error;
  }
};
