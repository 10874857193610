import { Box, Button, Input, Stack } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import FormLabelWithInfoTooltip from "../Widgets/FormLabelWithInfoTooltip";

const Social = ({ brandingForm }) => {
  const baseUrl = getHostDomain();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = brandingForm;
  const agency = useContext(AgencyContext);
  const { textColor, agencyData, buttonColorScheme, colorPreset } = agency;

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await fetchController(
        baseUrl + `/api/v1/agency/${agencyData.id}`,
        "PUT",
        data
      );
      toast.success("Social profiles updated successfully");
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wront...");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          spacing={4}
          mb="4"
          width={"50%"}
          justifyContent={"center"}
        >
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Twitter"} isRequired={true} />
            <Input
              placeholder="Twitter"
              type="url"
              {...register("branding.socials.twitter", { required: true })}
            />
            {errors?.branding?.socials?.twitter && (
              <p style={{ color: "red", fontSize: "11px" }}>
                Twitter is required
              </p>
            )}
          </Box>
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Facebook"} isRequired={true} />
            <Input
              type="url"
              placeholder="Facebook"
              {...register("branding.socials.facebook", { required: true })}
            />
            {errors?.branding?.socials?.facebook && (
              <p style={{ color: "red", fontSize: "11px" }}>
                Facebook is required
              </p>
            )}
          </Box>
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Linkedin"} isRequired={true} />
            <Input
              type="url"
              placeholder="Linkedin"
              {...register("branding.socials.linkedin", { required: true })}
            />
            {errors?.branding?.socials?.linkedin && (
              <p style={{ color: "red", fontSize: "11px" }}>
                LinkedIn is required
              </p>
            )}
          </Box>
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Instagram"} isRequired={true} />
            <Input
              type="url"
              placeholder="Instagram"
              {...register("branding.socials.instagram", { required: true })}
            />
            {errors?.branding?.socials?.instagram && (
              <p style={{ color: "red", fontSize: "11px" }}>
                Instagram is required
              </p>
            )}
          </Box>
        </Stack>

        <Button
          isLoading={isLoading}
          width="80px"
          colorScheme={buttonColorScheme}
          type="submit"
          mt="4"
          isDisabled={!agencyData}
        >
          Submit
        </Button>
      </form>
    </>
  );
};

export default Social;