import {
  Box,
  Progress,
  Text
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchController } from "../../utils/FetchController/fetchController";
import { showToast } from "../../utils/Toast/Toast";
import { getHostDomain } from "../../utils/utils";

const AddWidgetBegginer = ({
  activeIndex,
  onClose,
  assistantId,
  setActiveIndex
}) => {
  const baseUrl = getHostDomain();
  const navigate = useNavigate();
  const url = baseUrl;
  const [progress, setProgress] = useState(0); // Progress state


 
  useEffect(() => {
    const launchBot = async () => {
      const widget = await AddWidget();
       if(widget.data){
        window.open(
          `https://cdn.insighto.ai/widget.html?widgetId=${widget?.data.id}`
        );
       }
      
      setTimeout(() => {
      window.location.reload();
      setActiveIndex(0)
        onClose();
      }, 1000);
    };

    const simulateProgressBar = async () => {
      for (let i = 0; i <= 100; i += 1) {
        await new Promise((resolve) => setTimeout(resolve, 50)); // Adjust the duration
        setProgress(i);
      }
      launchBot();
    };

    if (activeIndex === 2) {
      simulateProgressBar();
    }

    return () => {
      setProgress(0); // Reset progress when component unmounts or changes
    };
  }, [activeIndex]);

  const endpoint = "/api/v1/widget";
  const AddWidget = async (data) => {
    const urlHit = baseUrl + endpoint;
    const body = {
      widget_type: "chat",
      name: "Guide-Widget",
      description: "This is guide Description",
      assistant_id: assistantId,
      attributes: {},
      user_opening_messages: ["How can I assist you ?"],
    };
    const getData = await fetchController(urlHit, "POST", body);
    if (getData?.message) {
      return getData;
    } else {
      showToast("Oops! Something went wrong.", "error");
    }
  };

  return (
    <Box>
      {activeIndex === 2 && (
        <>
          <Progress
            value={progress}
            size="xs"
            colorScheme="green"
            mt="2"
            height="28px"
            isAnimated
          />
          <Text mt="1" fontSize="lg" fontWeight="bold">
            {progress}%
          </Text>
        </>
      )}
      <Box mt="4">
        <Text fontSize="md" fontWeight="bold" color="#3182ce">
          Did you know?
        </Text>
        <Text fontSize="sm" color="gray.500">
          1) You can also set custom colors for your chatbot as per your brand
          guidelines. Go to Dashboard -&gt; Widgets -&gt; Customize Widgets (3rd
          one)
        </Text>
        <Text fontSize="sm" color="gray.500">
          2) You can also create advance intents. Go to Dashboard -&gt; Intents
          -&gt; Setup (available for Diamond & above plans only)
        </Text>
      </Box>
    </Box>
  );
};

export default AddWidgetBegginer;
