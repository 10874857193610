import { useContext } from "react";
import { Image } from "@chakra-ui/react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

export default function AgencyLogo({ size="150px" }) {
  const agency = useContext(AgencyContext);
  const { agencyData, myDetails } = agency;
  const agency_id = myDetails ? myDetails.agency_id : null;

  const agencyLogoUrl = myDetails?.branding?.logo_url;
  return !agency.loading ? (
    agency_id ? (
      agencyLogoUrl ? (
        <Image
          width={size}
          src={
            agencyLogoUrl.includes(window.origin)
              ? agencyLogoUrl
              : `${window.location.origin}${agencyLogoUrl}`
          }
          alt={`${myDetails?.branding?.agency_name}-logo`}
        />
      ) : null
    ) : (
      <Image
        width={size}
        src="https://cdn.insighto.ai/bot.png"
        alt="Insighto.ai"
      />
    )
  ) : null;
}
