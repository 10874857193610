import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import TourLayout from "../Layout/TourLayout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { useContext } from "react";

export default function IntentTour({ onCloseIntentTour }) {
    const agency = useContext(AgencyContext);
    const { textColor } = agency;
  return (
    <TourLayout onCloseTour={onCloseIntentTour}>
      <Box fontSize={"md"}>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          What are Intents?
        </Text>
        <Text>
          A Conversation is marked with one of the Intents when it is concluded
          (either closed by the user or stays inactive). It is incredibly
          helpful to route these conversations to the respective team or members
          based on the identified intent.
        </Text>
        <Text>
          You can create a new one to capture an Intent specific to your
          business use case. Use the description field to let LLM do the magic.
        </Text>
        <UnorderedList>
          <ListItem>Click on "Add Intent"</ListItem>
          <ListItem>
            Enter the intent name, and description in the dialogue box & Click
            "Submit"
          </ListItem>
          <ListItem>
            Go back to the first tab to assign intent along with the Email
          </ListItem>
        </UnorderedList>
      </Box>
    </TourLayout>
  );
}
