import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import { useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";

export default function ConfigurationFormOptions({ optionField, control }) {
  const { option, onChangeOption, clearOption } = optionField;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });
  return (
    <>
      <FormControl>
        <FormLabel>Option</FormLabel>
        <HStack justifyContent={"center"} alignItems={"center"}>
          <Input
            placeholder="Option"
            onChange={onChangeOption}
            value={option}
          />
          <Button
            onClick={() => {
              if (fields.find((fieldOption) => fieldOption.option === option)) {
                toast.error("Option already added");
                return;
              }
              append({ option });
              clearOption();
            }}
            margin={"auto"}
          >
            Add Option
          </Button>
        </HStack>
      </FormControl>
      <Flex
        gap={4}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {fields.map((field, index) => (
          <Tag size={"md"}>
            <TagLabel key={field.id}>{field.option}</TagLabel>
            <TagCloseButton onClick={() => remove(index)} />
          </Tag>
        ))}
      </Flex>
    </>
  );
}
