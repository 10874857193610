import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Card,
  Flex,
} from "@chakra-ui/react";
import AgencyLogo from "./AgencyLogo";

export default function DesktopBrowserRecommended() {
  return (
    <Flex
      height={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
    >
      <Card
        mx={4}
        justifyContent="center"
        alignItems={"center"}
        variant={"elevated"}
      >
        <Box p={6}>
          <AgencyLogo size={"100px"} />
        </Box>
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          py={0}
        >
          <AlertIcon />
          <AlertTitle mt={3} mb={2} fontSize="lg">
            Desktop Browsers Recommended
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Our app works best on desktop. Please open this link on a desktop
            computer to continue.
          </AlertDescription>
        </Alert>
      </Card>
    </Flex>
  );
}
