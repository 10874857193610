import { Box, Flex, Heading } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import { getHostDomain } from "../../utils/utils";
import Layout from "../Layout/Layout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import IframeWidget from "./IframeWidget";
import { PiFileHtml } from "react-icons/pi";
import IconWidgetOption from "./IconWidgetOption";
import {
  MdOutlineLeaderboard,
  MdOutlineWebAsset,
  MdOutlineWifiCalling3,
} from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import {
  FaFacebookMessenger,
  FaInstagram,
  FaMixcloud,
  FaPhoneAlt,
  FaSms,
} from "react-icons/fa";
import { FiPhoneMissed } from "react-icons/fi";
import { TbMessageChatbot } from "react-icons/tb";
export default function PlaygroundPage() {
  const baseUrl = getHostDomain();
  const [widgets, setWidgets] = useState([]);
  const [status, setStatus] = useState("idle");
  useEffect(() => {
    (async () => {
      setStatus("loading");
      const endpoint = "/api/v1/widget/get_my_details?page=1&size=50";
      const urlHit = baseUrl + endpoint;
      const response = await fetchController(urlHit, "GET");
      setWidgets(response.data.items);
      setStatus("success");
    })();
  }, []);
  const loading = status === "loading";
  const [widget, setWidget] = useState({
    id: "",
    type: "",
  });
  const onChangeWidget = (widget) => {
    setWidget({
      id: widget.id,
      type: widget.type,
    });
  };
  const selectPlaceholder = widgets.length
    ? "Select widget to start the chat"
    : "Please create a widget first";

  const agency = useContext(AgencyContext);
  const { textColor } = agency;
  const widgetIcons = {
    html: PiFileHtml,
    html_call: MdOutlineWebAsset,
    whatsapp: IoLogoWhatsapp,
    phone: FaPhoneAlt,
    sms: FaSms,
    leadconnector: MdOutlineLeaderboard,
    leadconnector_call: FiPhoneMissed,
    fb_messenger: FaFacebookMessenger,
    web_call: MdOutlineWifiCalling3,
    instagram: FaInstagram,
    plivo_call: FaMixcloud,
  };

  const widgetOptions = widgets
    .filter((widget) =>
      ["web_call", "html_call", "chat"].includes(widget.widget_type)
    )
    .map((widget) => ({
      label: `${widget.name} [${widget.id}]`,
      value: widget.id,
      isDisabled: ["phone", "whatsapp"].includes(widget.widget_type),
      type: widget.widget_type,
      Icon: widgetIcons[widget.widget_type] || TbMessageChatbot,
    }));
  return (
    <Layout>
      <Box border="1px" h="90svh" p="5" borderRadius="md" overflowY="auto">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Flex justifyContent="start" alignItems="center">
              <Heading mb={2} color={textColor} fontSize="xl">
                Playground
              </Heading>
            </Flex>
            <Select
              disabled={widgets.length === 0}
              placeholder={selectPlaceholder}
              onChange={(widget) => {
                onChangeWidget({
                  id: widget.value,
                  type: widget.type,
                });
              }}
              options={widgetOptions}
              components={{ Option: IconWidgetOption }}
              value={widgetOptions.find((w) => w.id === widget.id)}
            />
            {widget.id ? (
              <Flex alignItems="center" justifyContent="center" mt={2}>
                <IframeWidget widgetId={widget.id} widgetType={widget.type} />
              </Flex>
            ) : null}
          </>
        )}
      </Box>
    </Layout>
  );
}
