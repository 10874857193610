import "./DateField.css";
export default function DateField({ field }) {
  return (
    <label className="date__field">
      <div className="field__label">
        <span>{field.label}</span>
        {field.required ? <span className="required">*</span> : null}
      </div>
      <div className="date__fieldInput">
        <input type="date" name={field.name} />
      </div>
    </label>
  );
}
