import { Box, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchController } from "../../../utils/FetchController/fetchController";
import Loader from "../../../utils/Loader/Loader";
import { getHostDomain } from "../../../utils/utils";
const endpoint = "/api/v1/billing/create-checkout-session";

const BillingSuccess = () => {
  const baseUrl = getHostDomain();
  const urlHit = baseUrl + endpoint;
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Get the value of the 'lookup_key' query parameter
  const lookupKey = queryParams.get("lookup_key");

  useEffect(() => {
    planUpdating();
  }, []);

  const planUpdating = async () => {
    setIsFullScreenLoading(true);
    try {
      const body = {
        lookup_key: lookupKey,
      };
      const getData = await fetchController(urlHit, "POST", body);
      if (getData.checkout_url) {
        window.open(getData.checkout_url, "_blank");
      } else {
        setTimeout(() => {
          navigate("/settings");
          window.location.reload();
          setIsFullScreenLoading(false);
        }, 5000);
      }
    } catch (error) {
      console.error("Error occour while upgrading plan: ", error);
       setIsFullScreenLoading(false);
    } finally {
      setIsFullScreenLoading(false);
    }
  };

  return (
    <Box textAlign="center" marginTop="50px">
      {isLoading ? (
        <Loader text="We are changing your plan, please wait…" />
      ) : (
        <div>BillingCancel - Redirecting to /settings</div>
      )}
    </Box>
  );
};

export default BillingSuccess;
