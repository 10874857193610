import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Assistants from "../Assistants/Assistants";
import DataSources from "../DataSources/DataSources";
import Layout from "../Layout/Layout";
import Widgets from "../Widgets/Widgets";
import Tools from "../Tools";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
const AiAgents = () => {
  const { name = "data-sources" } = useParams();
  const tabs = [
    {
      route: "/ai-agents/data-sources",
      tabName: "Data Sources",
      children: <DataSources tab={"Data Sources"} />,
    },
    {
      route: "/ai-agents/assistants",
      tabName: "Assistants",
      children: <Assistants tab={"Assistants"} />,
    },
    {
      route: "/ai-agents/widgets",
      tabName: "Widgets",
      children: <Widgets tab={"Widgets"} />,
    },
  ];

  const defaultIndex = tabs
    .map((tab) => tab.route)
    .indexOf(`/ai-agents/${name}`);
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/ai-agents/${name}`);
  }, []);
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, colorPreset } = agency;
  return (
    <Layout>
      <Box>
        <Tabs isLazy index={defaultIndex < 0 ? 0 : defaultIndex}>
          <TabList>
            {tabs.map((tab) => (
              <Tab onClick={() => navigate(tab.route)} key={tab.route} color={textColor}>
                {tab.tabName}
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel key={tab.route}>
                <Box mt={4}>{tab.children}</Box>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
};

export default AiAgents;
