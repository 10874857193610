import { AddIcon, CheckIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const defaultContactFields = [
  { value: "first_name", label: "First Name" },
  { value: "last_name", label: "Last Name" },
  { value: "email", label: "Email" },
  { value: "channels.whatsapp", label: "WhatsApp" },
  { value: "channels.web", label: "Web" },
  { value: "channels.instagram", label: "Instagram" },
  { value: "channels.telephone", label: "Telephone" },
  { value: "channels.microsoft_teams", label: "Microsoft Teams" },
  { value: "channels.dial_code", label: "Dial Code" },
];

export default function ConfigureContactFieldsModal({
  formId,
  isOpen,
  onClose,
}) {
  const baseUrl = getHostDomain();

  const {
    unregister,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    watch,
    getValues,
  } = useForm({});

  const [isDisableNewFieldBtn, setIsDisableNewFieldBtn] = useState(true);
  const [contactFields, setContactFields] = useState([...defaultContactFields]);
  const [formFields, setFormFields] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({ "": "" });
  const [loading, setLoading] = useState(false);

  const getFormFields = async () => {
    try {
      setLoading(true);
      const response = await fetchController(
        baseUrl + `/api/v1/form/${formId}`
      );
      const resFields = response.data.fields.map((field) =>
        field.type === "select"
          ? {
              ...field,
              options: field.options.map((fieldOption) => ({
                option: fieldOption,
              })),
            }
          : field
      );

      if (resFields) {
        const formOptions = resFields.map((field) => ({
          value: field.name,
          label: field.label,
        }));
        setFormFields(formOptions);
      }

      setValue("formData", { ...response.data });
    } catch (e) {
      console.error(e);
      toast.error(e?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const getCustomFields = async () => {
    try {
      const response = await fetchController(
        baseUrl + "/api/v1/contact_custom_field/list",
        "GET"
      );
      const customOptions = response?.data?.items.map((item) => ({
        value: `custom_fields.${item.id}`,
        label: item.custom_field_name,
      }));
      setContactFields([...defaultContactFields, ...customOptions]);
    } catch (e) {
      console.error(e);
      toast.error(e?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    getFormFields();
    getCustomFields();
  }, []);

  const handleClose = () => {
    setIsDisableNewFieldBtn(true);
    setContactFields([]);
    setFormFields([]);
    setFieldMapping({ "": "" });
    onClose();
  };

  const handleContactFieldSelect = (oldItem, data) => {
    let obj = { ...fieldMapping };
    delete obj[oldItem];
    obj[data.value] = { value: "", isDisabled: false };

    setFieldMapping(obj);
  };

  const handleFormFieldSelect = (item, data) => {
    let newFields = { ...fieldMapping };
    newFields[item].value = data.value;
    setFieldMapping(newFields);
    setValue(`formData.contact_mapping.field_mappings.${item}`, data.value);
  };

  const handleAddContactField = (item) => {
    let newFields = { ...fieldMapping };

    delete newFields[""];
    newFields[item].isDisabled = true;

    setFieldMapping(newFields);
    setIsDisableNewFieldBtn(false);
  };

  const handleContactFieldEdit = (item) => {
    let newFields = { ...fieldMapping };
    newFields[item].isDisabled = false;
    setFieldMapping(newFields);
    setIsDisableNewFieldBtn(true);
  };

  const handleDeleteField = (item) => {
    let newFields = { ...fieldMapping };
    delete newFields[item];
    unregister(`formData.contact_mapping.field_mappings.${item}`);
    setFieldMapping(newFields);

    setIsDisableNewFieldBtn(!Object.keys(newFields).length === 0);
  };

  useEffect(() => {
    if (watch("formData.contact_mapping.field_mappings")) {
      const editFieldMappingObj = {
        ...getValues("formData.contact_mapping.field_mappings"),
      };
      const newObj = {};
      Object.keys(editFieldMappingObj).reduce((acc, item) => {
        if (item !== "channels" && item !== "custom_fields") {
          acc[item] = {
            value: editFieldMappingObj[item],
            isDisabled: true,
          };
        }
        return acc;
      }, newObj);

      if (editFieldMappingObj?.channels) {
        Object.keys(editFieldMappingObj?.channels).reduce((acc, item) => {
          acc[`channels.${item}`] = {
            value: editFieldMappingObj.channels[item],
            isDisabled: true,
          };
          return acc;
        }, newObj);
      }

      if (editFieldMappingObj?.custom_fields) {
        Object.keys(editFieldMappingObj?.custom_fields).reduce((acc, item) => {
          acc[`custom_fields.${item}`] = {
            value: editFieldMappingObj.custom_fields[item],
            isDisabled: true,
          };
          return acc;
        }, newObj);
      }

      setFieldMapping(newObj);
      setIsDisableNewFieldBtn(false);
    }
  }, [watch("formData.contact_mapping")]);

  const onSubmit = async () => {
    try {
      await fetchController(baseUrl + `/api/v1/form/${formId}`, "PUT", {
        ...getValues("formData"),
      });
    } catch (e) {
      console.error(e);
      toast.success("Trigger contact field created successfully")
    } finally {
      handleClose()
    }
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={() => {
        handleClose();
      }}
    >
      <ModalOverlay />
      <ModalContent height="auto" width="95%" maxWidth="800px">
        <ModalHeader>
          <Box mt={3} display={"flex"}>
            <FormLabel>Configure contact fields</FormLabel>
            <IconButton
              isRound={true}
              colorScheme="blue"
              variant="outline"
              aria-label="Add Field"
              icon={<AddIcon />}
              size={"xs"}
              title={"Add new field"}
              isDisabled={isDisableNewFieldBtn}
              onClick={() => {
                setIsDisableNewFieldBtn(true);
                setFieldMapping((prevState) => ({
                  ...prevState,
                  "": "",
                }));
              }}
            />
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          {loading ? (
            <Flex justifyContent={"center"}>
              <Spinner size='lg'/>
            </Flex>
          ) : (
            <ModalBody>
              <Flex gap={2} direction="column">
                {Object.keys(fieldMapping).length > 0 && (
                  <>
                    {Object.keys(fieldMapping).map((item, index) => (
                      <Stack
                        spacing={0}
                        direction={"row"}
                        alignItems={"center"}
                        key={item + index}
                        gap={2}
                      >
                        <Box width={"50%"}>
                          <Select
                            onChange={(selectedOption) =>
                              handleContactFieldSelect(item, selectedOption)
                            }
                            options={contactFields.map((option) => ({
                              value: option.value,
                              label: option.label,
                              isDisabled: fieldMapping[option.value]
                                ? true
                                : false,
                            }))}
                            value={
                              fieldMapping[item]
                                ? {
                                    value: item,
                                    label: contactFields.filter(
                                      (o) => o.value === item
                                    )[0]?.label,
                                  }
                                : { value: "", label: "Select contact field" }
                            }
                            isDisabled={fieldMapping[item]?.isDisabled}
                          />
                        </Box>

                        <Box width={"50%"}>
                          <Select
                            onChange={(selectedOption) =>
                              handleFormFieldSelect(item, selectedOption)
                            }
                            options={formFields}
                            value={
                              fieldMapping[item]?.value
                                ? {
                                    value: fieldMapping[item].value,
                                    label: formFields.filter(
                                      (o) =>
                                        o.value === fieldMapping[item].value
                                    )[0]?.label,
                                  }
                                : { value: "", label: "Select form field" }
                            }
                            isDisabled={
                              fieldMapping[item] === "" ||
                              fieldMapping[item]?.isDisabled
                            }
                            name={item}
                          />
                        </Box>

                        <IconButton
                          isRound={true}
                          colorScheme="blue"
                          variant="outline"
                          aria-label="Add"
                          icon={<CheckIcon />}
                          size={"xs"}
                          title="Add"
                          ml={2}
                          onClick={() => handleAddContactField(item)}
                          isDisabled={
                            !fieldMapping[item]?.value ||
                            fieldMapping[item].isDisabled
                          }
                        />
                        <IconButton
                          isRound={true}
                          colorScheme="blue"
                          variant="outline"
                          aria-label="Edit"
                          icon={<EditIcon />}
                          size={"xs"}
                          title="Edit"
                          ml={2}
                          isDisabled={!fieldMapping[item]?.isDisabled}
                          onClick={() => handleContactFieldEdit(item)}
                        />
                        <IconButton
                          isRound={true}
                          colorScheme="red"
                          variant="outline"
                          aria-label="Delete"
                          icon={<DeleteIcon />}
                          size={"xs"}
                          title="Delete"
                          ml={2}
                          onClick={() => handleDeleteField(item)}
                        />
                      </Stack>
                    ))}
                  </>
                )}
              </Flex>
            </ModalBody>
          )}
          <ModalFooter justifyContent={"flex-end"}>
            <Button
              isLoading={isSubmitting}
              colorScheme="blue"
              type="submit"
              mt="4"
            >
              Submit
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
