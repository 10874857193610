import { Box, FormControl, FormLabel, Grid, Input } from "@chakra-ui/react";
import React from "react";
import Select from "react-select";
export default function CustomDuration({ toolUserForm }) {
  const { setValue, register, watch } = toolUserForm;
  const { is_custom_duration = false, credentials = {} } = watch();
  const { configuration = {} } = credentials;
  const durationOptions = [
    {
      value: "30",
      label: "30 min",
    },
    {
      value: "60",
      label: "60 min",
    },
    {
      value: "120",
      label: "120 min",
    },
    {
      value: "custom",
      label: "Custom",
    },
  ];
  const selectedDuration = durationOptions.find(
    (durationOption) =>
      durationOption.value === configuration?.appointment_duration
  );
  return (
    <FormControl isRequired>
      <FormLabel fontWeight={"bold"}>Duration</FormLabel>
      <Grid
        gap={2}
        gridTemplateColumns={is_custom_duration ? "1fr auto" : null}
      >
        <Box>
          <Select
            required
            value={
              is_custom_duration
                ? durationOptions.find(
                    (durationOption) => durationOption.value === "custom"
                  )
                : selectedDuration
            }
            onChange={({ value }) => {
              setValue("is_custom_duration", value === "custom");
              if (value !== "custom")
                setValue(
                  "credentials.configuration.appointment_duration",
                  value
                );
            }}
            options={durationOptions}
          />
        </Box>
        {is_custom_duration ? (
          <Input
            type="number"
            placeholder="Duration"
            required
            {...register("credentials.configuration.appointment_duration")}
          />
        ) : null}
      </Grid>
    </FormControl>
  );
}
