/* eslint-disable no-useless-concat */
import { Box, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

const ProgressBar = ({ allocated, utilized }) => {
  const [utilizationPercentages, setUtilizationPercentages] = useState({});
  const formatKey = (key) => {
    // Remove underscores and add space
    const words = key.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  };
  useEffect(() => {
    const calculateUtilizationPercentages = () => {
      const percentages = {};
      Object.keys(allocated).forEach((key) => {
        const totalAllocated = allocated[key];
        const totalUtilized = utilized[key];
        const percentage = (totalUtilized / totalAllocated) * 100;
        percentages[key] = percentage;
      });
      setUtilizationPercentages(percentages);
    };

    calculateUtilizationPercentages();
  }, [allocated, utilized]);

  const getProgressBarColor = (percentage) => {
    if (percentage <= 80) {
      return "#3182ce";
    } else if (percentage <= 100) {
      return "#fdfd69";
    } else {
      return "#ff6767"; // or any other color for limit exceeded
    }
  };
  return (
    <Box style={{ width: "100%" }}>
      {Object.keys(utilizationPercentages).map((key) => (
        <Box key={key} display="flex" gap={2} alignItems="end">
          <Text width="110px" fontWeight="bold">
            {formatKey(key)}
          </Text>
          <Box
            style={{
              backgroundColor: "#3182ce24",
              width: "50%",
              height: "20px",
              marginTop: "12px",
            }}
            borderRadius={10}
          >
            <Box
              style={{
                width: `${utilizationPercentages[key]}%`,
                maxWidth: "100%",
                height: "20px",
                backgroundColor: getProgressBarColor(
                  utilizationPercentages[key]
                ),
              }}
              borderRadius={10}
            />
          </Box>

          <Text width="25%" fontSize="16px">
            {"Used" +
              ":" +
              (utilized[key] ? utilized[key].toLocaleString() : "0") +
              "/" +
              allocated[key].toLocaleString()}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default ProgressBar;
