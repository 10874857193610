import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
export default function WebhookForm({ webhookForm, buttonColorScheme }) {
  const { register, formState } = webhookForm;
  return (
    <>
      <Stack spacing={3}>
        <FormControl isRequired isInvalid={formState.name}>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            placeholder="Name"
            {...register("name", {
              required: true,
            })}
          />
          <FormErrorMessage>
            {formState.name && formState.name.type === "required"
              ? "Name is required"
              : null}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={formState.endpoint}>
          <FormLabel>Endpoint</FormLabel>
          <Input
            type="url"
            placeholder="Endpoint"
            {...register("endpoint", {
              required: true,
            })}
          />
          <FormErrorMessage>{formState.endpoint}</FormErrorMessage>
        </FormControl>
        <Checkbox
          name="enabled"
          onChange={(e) =>
            webhookForm.setValue("enabled", e.currentTarget.checked)
          }
          isChecked={webhookForm.watch("enabled")}
          colorScheme={buttonColorScheme}
        >
          Enabled
        </Checkbox>
      </Stack>
    </>
  );
}
