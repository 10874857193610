import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function useToolUserFunctions({ tool }) {
  const baseUrl = getHostDomain();
  const [toolFunctions, setToolFunctions] = useState([]);
  const [status, setStatus] = useState("idle");
  useEffect(() => {
    (async () => {
      if (!tool) return;
      setStatus("loading");
      const response = await fetchController(
        baseUrl +
          `/api/v1/tool/${tool.id}/tool_function/list`
      );
      setToolFunctions(response.data.items);
      setStatus("idle");
    })();
  }, [tool]);
  return { toolFunctions, status };
}
