import "../../Styles/DocumentTile.css";
import { MdImage, MdPictureAsPdf } from "react-icons/md";
import { TbWorldWww } from "react-icons/tb";
import { FaFileAlt } from "react-icons/fa";
import { Box } from "@chakra-ui/react";

const RenderIcon = ({ file_type, iconSize }) => {
  const FILE_ICON_SIZE = iconSize || 20;
  const FILE_ICON_COLOR = "gray";
  return file_type === "pdf" ? (
    <MdPictureAsPdf color={FILE_ICON_COLOR} size={FILE_ICON_SIZE} />
  ) : file_type === "http" ? (
    <TbWorldWww color={FILE_ICON_COLOR} size={FILE_ICON_SIZE} />
  ) : file_type === "image" ? (
    <MdImage color={FILE_ICON_COLOR} size={FILE_ICON_SIZE} />
  ) : (
    <FaFileAlt size={FILE_ICON_SIZE} color={FILE_ICON_SIZE} />
  );
};
/**
 *
 * @param {{tile : {label : string; href : string ; file_type : "pdf" | "http"}}} param0
 * @returns {JSX.Element}
 */
export default function DocumentTile({ tile, bgColor }) {
  const title = tile.label
    ? tile.label
    : tile.href.split("/")[tile.href.split("/").length - 1] || "";
  return (
    <Box title={title} className="document__tile" bg={bgColor}>
      <RenderIcon file_type={tile.file_type} />
      <a href={tile.href} target="_blank">
        {title.length > 20 ? `${title.slice(0, 20)}...` : title}
      </a>
    </Box>
  );
}
