import { Flex } from "@chakra-ui/react";
import React from "react";
import { components } from "react-select";
const { Option } = components;
const IconWidgetOption = (props) => {
  const { Icon } = props.data;
  return (
    <Option {...props}>
      <Flex justifyContent={"flex-start"} gap={3} alignItems={"center"}>
        <Icon size={15}/>
        {props.data.label}
      </Flex>
    </Option>
  );
};

export default IconWidgetOption;
