import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
  Switch,
  Textarea,
} from "@chakra-ui/react";

export default function PropertyFields({
  settings,
  index,
  isEditing=null,
}) {
  const handleRemove = (index) => {
    settings.watch("properties").splice(index, 1);
    settings.setValue("properties", settings.watch("properties"));
  };

  return (
    <Flex gap={2} wrap={"wrap"} py={4}>
      <Stack width={"100%"} direction={"row"}>
        <FormControl
          isInvalid={
            settings.errors?.properties ? settings.errors?.properties[index]?.type : false
          }
          width={"30%"}
        >
          <FormLabel>Type</FormLabel>
          <Select
            {...settings.register(`properties.${[index]}.type`, {
              required: "Type is required",
            })}
            isDisabled={isEditing === null ? false : !isEditing}
          >
            <option value="">Select...</option>
            <option value="string">String</option>
            <option value="integer">Integer</option>
          </Select>
          {settings.errors?.properties && settings.errors?.properties[index]?.type && (
            <FormErrorMessage>
              {settings.errors?.properties[index]?.type?.message}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          isInvalid={
            settings.errors?.properties ? settings.errors?.properties[index]?.name : false
          }
        >
          <FormLabel>Name</FormLabel>
          <Input
            placeholder={"Enter property name"}
            {...settings.register(`properties.${[index]}.name`, {
              required: "Name is required",
            })}
            isDisabled={isEditing === null ? false : !isEditing}
          />
          {settings.errors?.properties && settings.errors?.properties[index]?.name && (
            <FormErrorMessage>
              {settings.errors?.properties[index]?.name?.message}
            </FormErrorMessage>
          )}
        </FormControl>
      </Stack>

      <FormControl>
        <FormLabel>Description</FormLabel>
        <Textarea
          placeholder={"Enter description"}
          {...settings.register(`properties.${[index]}.description`)}
          isDisabled={isEditing === null ? false : !isEditing}
        />
      </FormControl>

      <FormControl display={"flex"} justifyContent={"space-between"}>
        <FormLabel>Is required?</FormLabel>
        <Switch
          id={`isRequired-${index}`}
          size={"md"}
          {...settings.register(`properties.${[index]}.isRequired`)}
          isDisabled={isEditing === null ? false : !isEditing}
        />
      </FormControl>

      {settings.watch("properties")?.length > 1 && (
        <Button
          width={"100%"}
          color={"red"}
          onClick={() => handleRemove(index)}
          isDisabled={isEditing === null ? false : !isEditing}
        >
          Remove
        </Button>
      )}
    </Flex>
  );
}
