import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import CampaignFields from "./CampaignFields";
import { getHostDomain } from "../../utils/utils";
export default function StartCampaignModal({
  isOpen,
  onClose,
  contacts,
  clearStatusFromTable,
}) {
  const baseUrl = getHostDomain();
  const { register, watch, setValue, handleSubmit, formState, reset } = useForm(
    {
      defaultValues: {
        widget: undefined,
        message: "",
      },
    }
  );
  const { errors, isSubmitting } = formState;
  const onSubmit = async (data) => {
    try {
      const contact_ids = contacts.map((contact) => contact.id);
      const currentWidget = data.widget.value;
      const response = await fetchController(
        baseUrl +
          `/api/v1/campaign/run/${currentWidget.id}`,
        "POST",
        {
          contact_ids,
          message: data.message,
        }
      );
      reset();
      toast.success(response.status);
      clearStatusFromTable();
      onClose();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader>Start campaign</ModalHeader>
          <ModalBody>
            <ModalCloseButton />
            <CampaignFields
              errors={errors}
              register={register}
              setValue={setValue}
              watch={watch}
            />
          </ModalBody>

          <ModalFooter>
            <Button type="button" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button isLoading={isSubmitting} type="submit" colorScheme="blue">
              Start
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
