import { Box, Link, Text } from "@chakra-ui/react";
import TourLayout from "../Layout/TourLayout";

export default function ToolsTourGuide({ onCloseTour }) {
  return (
    <TourLayout onCloseTour={onCloseTour}>
      <Text color="#3182ce" fontWeight={"bold"} fontSize={"xl"}>
        What are tools?
      </Text>
      <Box fontSize={"md"}>
        <Text>
          Tools allow your Assistants to invoke any action or interface with any
          3rd-party application already in use by your organization. It could be
          anything like creating a support ticket on Zendesk, querying the
          number of leaves an employee has or wants to apply for, making
          reservations and so much more!
        </Text>
        <Text>
          Get started by using any of the prebuilt Tool or creating a custom one
          integrated with any REST API
        </Text>
        <Text>
          Once you set up the Tool, proceed to link it up with your Assistant.
          Once linked, your Assistant can be instructed through Prompt to invoke
          different functions to be able to fetch or push information to that
          specific Tool in real-time.
        </Text>
        <Text>
          You can also link multiple Tool of the same type and fine-tuning the
          Functions’ instructions. It has multiple use-cases.
        </Text>
        <Text>
          For example: creating multiple calendars and using Function’s
          description to identify each calendar distinctly, such as “to book an
          appointment for Dr. Alex”, “to book an appoint for Dr. John”
        </Text>
      </Box>
    </TourLayout>
  );
}
