import { FormControl, FormLabel, Input } from "@chakra-ui/react";

export default function SimpleTokenFields({ key_fields = [], toolUserForm }) {
  const { register } = toolUserForm;
  return key_fields.map((key_field, index) => (
    <FormControl key={index}>
      <FormLabel>{key_field.label}</FormLabel>
      <Input
        placeholder={key_field.label}
        {...register(`credentials.${key_field.name}`)}
      />
    </FormControl>
  ));
}
