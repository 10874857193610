import { useEffect, useState } from "react";
import Select from "react-select";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
export default function FormSelect({ onChange, selectedForm }) {
  const baseUrl = getHostDomain();
  const [forms, setForms] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await fetchController(
        baseUrl + `/api/v1/form/list`
      );
      setForms(response.data.items);
    })();
  }, []);
  const options = forms.map((form) => ({
    value: form,
    label: form.name,
  }));
  const formCurrentlySelectedValue = options.find(
    (form) => form?.value.id === selectedForm?.id
  );
  return (
    <Select
      isClearable
      options={options}
      onChange={onChange}
      value={formCurrentlySelectedValue}
    />
  );
}
