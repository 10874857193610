import moment from "moment";

export function formatDateWithAmPm(timestamp, monthLength = "full") {
  if (!timestamp) return "-";

  // Parse the timestamp as UTC using Moment.js
  const date = moment.utc(timestamp);

  // Convert to browser's timezone
  const browserTimezoneDate = date.clone().local();

  // Determine the month format based on the passed monthLength argument
  const monthFormat = monthLength === "short" ? "MMM" : "MMMM";

  // Format the date/time in the browser's timezone
  const formattedDate = browserTimezoneDate.format(`${monthFormat} Do, h:mm a`);

  return formattedDate;
}