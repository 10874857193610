import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import AlerDeleteAddon from "./AlertDeleteAddon";
import { getHostDomain } from "../../../utils/utils";

export default function PurchasedAddonItem({ addon, fetchCurrentUserAddons }) {
  const baseUrl = getHostDomain();
  const [beingHovered, setBeingHovered] = useState(false);
  const [status, setStatus] = useState("idle");
  const onRemoveAddon = async () => {
    try {
      setStatus("deleting");
      await fetchController(
        baseUrl + "/api/v1/billing/remove_addon",
        "POST",
        {
          lookup_key: addon.plan_lookup_key,
          qty: addon.qty || 1,
        }
      );
      fetchCurrentUserAddons();
      toast.success("Addon removed");
      closeDeleteAddon();
    } catch (error) {
      toast.error("Some error occured ");
    } finally {
      setStatus("idle");
    }
  };
  const {
    isOpen,
    onOpen: openDeleteAddonModal,
    onClose: closeDeleteAddon,
  } = useDisclosure();
  return (
    <Box
      position={"relative"}
      minH={120}
      onMouseEnter={() => {
        setBeingHovered(true);
      }}
      onMouseLeave={() => {
        setBeingHovered(false);
      }}
      width={150}
      borderRadius={"md"}
      boxShadow={"sm"}
      border={"1px solid lightgray"}
      key={addon.id}
    >
      <Flex
        height={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={"column"}
        p={2}
      >
        <Text textAlign={"center"}>{addon.name}</Text>
        <Box borderRadius={"md"} bg={"blue.100"} p={1}>
          <Text fontSize={"xl"}>{`${
            addon.voice_seconds * (addon.qty || 1)
          } sec`}</Text>
        </Box>
      </Flex>
      {beingHovered ? (
        <Box cursor={"pointer"} position={"absolute"} top={-2} right={-2}>
          <RiDeleteBin6Line size={20} onClick={openDeleteAddonModal} />
        </Box>
      ) : null}
      <AlerDeleteAddon
        status={status}
        isOpen={isOpen}
        onClose={closeDeleteAddon}
        onConfirmDelete={onRemoveAddon}
      />
    </Box>
  );
}
