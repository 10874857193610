import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";
import { AgencyContext } from "../../Settings/AgencyProvider/AgencyProvider";

const DOMAIN_REGEX =
  /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;

export default function DomainPlan() {
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const { textColor, colorPreset, buttonColorScheme, agencyData, getAgencies } = agency;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {

    try {
      const payload = {
        domain: {
          appDomain: data.appDomain,
          apiDomain: data.apiDomain,
          chatbotDomain: data.chatbotDomain,
        },
      };
      await fetchController(
        baseUrl + `/api/v1/agency/${agencyData.id}`,
        "PUT",
        payload
      );
      toast.success("Domains updated");
      await getAgencies()
    } catch (error) {
      toast.error("Something went wrong!");
      console.log({ error });
    }
  };

  useEffect(() => {
    setValue(
      "appDomain",
      agencyData?.domain?.appDomain || ""
    );
    setValue(
      "apiDomain",
      agencyData?.domain?.apiDomain || ""
    );
    setValue(
      "chatbotDomain",
      agencyData?.domain?.chatbotDomain || ""
    );
  }, [agencyData]);


  return (
    <Box border="1px" p="5" borderRadius="md" h="81vh" overflowY="auto">
      <Card variant={"elevated"}>
        <Alert status="info">
          <AlertIcon />
          {"Once the domain names are saved, they CANNOT be changed."}
        </Alert>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction="column" gap={4}>
              <FormControl isInvalid={errors.appDomain}>
                <FormLabel>App Domain</FormLabel>
                <Input
                  variant="outline"
                  isDisabled={!!agencyData?.domain?.appDomain}
                  placeholder="app.insighto.ai"
                  {...register("appDomain", {
                    required: "App Domain is required",
                    pattern: {
                      value: DOMAIN_REGEX,
                      message: "Enter a valid domain",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.appDomain && errors.appDomain.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.apiDomain}>
                <FormLabel>API Domain</FormLabel>
                <Input
                  variant="outline"
                  isDisabled={!!agencyData?.domain?.apiDomain}
                  placeholder="api.insighto.ai"
                  {...register("apiDomain", {
                    required: "API Domain is required",
                    pattern: {
                      value: DOMAIN_REGEX,
                      message: "Enter a valid domain",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.apiDomain && errors.apiDomain.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.chatbotDomain}>
                <FormLabel>Chatbot Domain</FormLabel>
                <Input
                  variant="outline"
                  isDisabled={!!agencyData?.domain?.chatbotDomain}
                  placeholder="cdn.insighto.ai"
                  {...register("chatbotDomain", {
                    required: "Chatbot Domain is required",
                    pattern: {
                      value: DOMAIN_REGEX,
                      message: "Enter a valid domain",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.chatbotDomain && errors.chatbotDomain.message}
                </FormErrorMessage>
              </FormControl>


              <Box>
                <Text fontWeight="bold" mb={1}>
                  Please make sure to point your above subdomain to the
                  following IP address
                </Text>
                <Text color="#b1a5a5f5" mt={0} mb={0}>
                  1. DNS A Record for App & API Domain : 57.151.120.174
                </Text>
                <Text color="#b1a5a5f5" mt={0}>2. Chatbot Domain : 172.174.188.46</Text>
              </Box>

              <Button type="submit" isDisabled={!!agencyData?.domain?.appDomain} colorScheme={buttonColorScheme}>
                Submit
              </Button>
            </Flex>
          </form>
        </CardBody>
      </Card>
    </Box>
  );
}
