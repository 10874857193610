import { Box, Flex, FormControl, FormLabel, Spinner } from "@chakra-ui/react";
import React from "react";
import Select from "react-select";
import useExternalAuth from "../Tools/useExternalAuth";
import useToolUserRunStaticFunction from "../Tools/useToolUserRunStaticFunction";
import FormLabelWithInfoTooltip from "./FormLabelWithInfoTooltip";
export default function Telnyx({ setValue, watch }) {
  const { status, auths } = useExternalAuth({
    auth_provider: "telnyx",
  });
  const telnyxAccountOptions = auths.map((auth) => ({
    value: auth.id,
    label: auth.name,
  }));
  const widget = watch();
  const { response } = useToolUserRunStaticFunction({
    tool_user_id: widget?.attributes?.tool_user_id,
    staticFnPayload: {
      function_name: "get_phone_numbers",
    },
  });
  const phoneNumberOptions = (response || []).map(
    ({ phone_number_id, phone_number }) => ({
      value: phone_number_id,
      label: phone_number,
    })
  );
  const findOption = (attributesKey) => (o) =>
    o.value === widget?.attributes?.[attributesKey];
  const onChangeTelnyxAttribute =
    (attributesKey) =>
    ({ value }) =>
      setValue(`attributes.${attributesKey}`, value);
  return status === "loading" ? (
    <Flex justifyContent={"center"} alignItems={"center"}>
      <Spinner margin={"auto"} />
    </Flex>
  ) : (
    <Box>
      <FormControl isRequired>
        <FormLabelWithInfoTooltip
          tip={"Go to Tools section first to create your Telnyx configuration"}
          label={"Select Telnyx account"}
        />
        <Select
          required
          options={telnyxAccountOptions}
          onChange={onChangeTelnyxAttribute("tool_user_id")}
          value={telnyxAccountOptions.find(findOption("tool_user_id"))}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Select phone number</FormLabel>
        <Select
          required
          options={phoneNumberOptions}
          onChange={onChangeTelnyxAttribute("phone_number_id")}
          value={phoneNumberOptions.find(findOption("phone_number_id"))}
        />
      </FormControl>
    </Box>
  );
}
