import React, { useState, useRef } from "react";
import {
  Box,
  Input,
  Button,
  Flex,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  InputGroup,
  InputRightElement,
  IconButton,
  Stack,
  useOutsideClick,
  useColorMode,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { DateRange } from "react-date-range";
import {
  format,
  subDays,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
} from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./customdaterangepicker.css";

const CustomDateRangePicker = ({ startDate, endDate, onChange }) => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [state, setState] = useState([
    {
      startDate: startDate || new Date(),
      endDate: endDate || new Date(),
      key: "selection",
    },
  ]);
  const [showPicker, setShowPicker] = useState(false);
  const popoverRef = useRef();

  useOutsideClick({
    ref: popoverRef,
    handler: () => setShowPicker(false),
  });

  const handleSelect = (ranges) => {
    setState([ranges.selection]);
  };

  const handleOkClick = () => {
    setShowPicker(false);
    onChange(state[0]);
  };

  const handleClearClick = () => {
    const clearedRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    setState([clearedRange]);
    onChange(clearedRange);
  };

  const setPredefinedRange = (range) => {
    const today = new Date();
    let startDate, endDate;

    switch (range) {
      case "last7Days":
        startDate = subDays(today, 7);
        endDate = today;
        break;
      case "last30Days":
        startDate = subDays(today, 30);
        endDate = today;
        break;
      case "thisMonth":
        startDate = startOfMonth(today);
        endDate = endOfMonth(today);
        break;
      case "lastMonth":
        startDate = startOfMonth(subDays(today, 30));
        endDate = endOfMonth(subDays(today, 30));
        break;
      case "thisYear":
        startDate = startOfYear(today);
        endDate = endOfYear(today);
        break;
      case "lastYear":
        startDate = startOfYear(subDays(today, 365));
        endDate = endOfYear(subDays(today, 365));
        break;
      default:
        startDate = new Date();
        endDate = new Date();
    }

    setState([
      {
        startDate,
        endDate,
        key: "selection",
      },
    ]);
    onChange({ startDate, endDate });
  };

  return (
    <Box>
      <Flex alignItems="center">
        <Popover
          isOpen={showPicker}
          onClose={() => setShowPicker(false)}
          placement="bottom-start"
        >
          <PopoverTrigger>
            <InputGroup>
              <Input
                readOnly
                value={`${format(state[0].startDate, "MM/dd/yyyy")} - ${format(
                  state[0].endDate,
                  "MM/dd/yyyy"
                )}`}
                onClick={() => setShowPicker(!showPicker)}
              />
              <InputRightElement>
                <IconButton
                  icon={<CloseIcon />}
                  size="sm"
                  onClick={handleClearClick}
                  aria-label="Clear date range"
                />
              </InputRightElement>
            </InputGroup>
          </PopoverTrigger>
          <PopoverContent width="500px" ref={popoverRef}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <VStack align="start" spacing={4}>
                <DateRange
                  editableDateInputs={true}
                  onChange={handleSelect}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                  months={2}
                  direction="horizontal"
                  showDateDisplay={false} // Hides the top header
                  className={colorMode === "light" ? "custom-date-range" : "custom-date-range darkMode"} // Custom class for additional styling
                />
              </VStack>
              <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }}>
                <Button
                  isFullWidth
                  variant="ghost"
                  onClick={() => setPredefinedRange("last7Days")}
                >
                  Last 7 Days
                </Button>
                <Button
                  isFullWidth
                  variant="ghost"
                  onClick={() => setPredefinedRange("last30Days")}
                >
                  Last 30 Days
                </Button>
                <Button
                  isFullWidth
                  variant="ghost"
                  onClick={() => setPredefinedRange("thisMonth")}
                >
                  This Month
                </Button>
                <Button
                  isFullWidth
                  variant="ghost"
                  onClick={() => setPredefinedRange("lastMonth")}
                >
                  Last Month
                </Button>
                <Button
                  isFullWidth
                  variant="ghost"
                  onClick={() => setPredefinedRange("thisYear")}
                >
                  This Year
                </Button>
                <Button
                  isFullWidth
                  variant="ghost"
                  onClick={() => setPredefinedRange("lastYear")}
                >
                  Last Year
                </Button>
              </Stack>
              <Box>
                <Stack direction={"row"} justifyContent={"end"}>
                  <Button colorScheme="blue" onClick={handleOkClick}>
                    Ok
                  </Button>
                </Stack>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    </Box>
  );
};

export default CustomDateRangePicker;
