import axios from "axios";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_SECRET;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
const SCOPE = "email https://www.googleapis.com/auth/calendar";

export const getTimeDifference = (timestamp) => {
  // Split the timestamp into hours, minutes, and AM/PM
  const [time, period] = timestamp.split(" ");
  const [hours, minutes] = time.split(":");

  // Create a new Date object with today's date and the given time
  const today = new Date();
  today.setHours(hours);
  today.setMinutes(minutes);

  // Check if the time is in PM and adjust accordingly
  if (period === "PM" && parseInt(hours) !== 12) {
    today.setHours(today.getHours() + 12);
  }

  // Calculate the time difference
  const currentTime = new Date();
  const difference = Math.abs(currentTime - today);
  const minutesDifference = Math.floor(difference / (1000 * 60)); // Convert milliseconds to minutes
  return `${minutesDifference} minutes ago`;
};

export const handleLogin = () => {
  const authorizationUrl =
    `https://accounts.google.com/o/oauth2/v2/auth?` +
    `scope=${encodeURIComponent(SCOPE)}&` +
    `access_type=offline&` +
    `include_granted_scopes=true&` +
    `response_type=code&` +
    `redirect_uri=${encodeURIComponent(REDIRECT_URI)}&` +
    `client_id=${CLIENT_ID}`;

  window.location.href = authorizationUrl;
};
export const exchangeCodeForToken = (code) => {
  const formData = new URLSearchParams();
  formData.append("code", code);
  formData.append("client_id", CLIENT_ID);
  formData.append("client_secret", CLIENT_SECRET);
  formData.append("redirect_uri", REDIRECT_URI);
  formData.append("grant_type", "authorization_code");

  fetch("https://oauth2.googleapis.com/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formData.toString(),
  })
    .then((response) => response.json())
    .then((data) => {
      localStorage.setItem("calendarTokens", JSON.stringify(data));
      localStorage.setItem(
        "google_refresh_token",
        data.refresh_token || localStorage.getItem("google_refresh_token")
      );
      window.location.href = `${REDIRECT_URI}?calendarSetup=${true}`;
    })
    .catch((error) => {
      console.error("Error exchanging code for token:", error);
    });
};
const calandarInstance = axios.create({
  baseURL: "https://www.googleapis.com",
});
calandarInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      const response = await axios.post("https://oauth2.googleapis.com/token", {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        refresh_token: localStorage.getItem("google_refresh_token"),
        grant_type: "refresh_token",
      });
      error.config.headers.Authorization = `Bearer ${response.data.access_token}`;
      return calandarInstance.request(error.config);
    } else {
      localStorage.removeItem("calendarTokens");
      return Promise.reject(error);
    }
  }
);
export const fetchCalendars = async (accessToken) => {
  try {
    const response = await calandarInstance.get(
      "/calendar/v3/users/me/calendarList",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
      }
    );
    return response.data.items;
  } catch (error) {
    console.error("Error fetching calendars:", error);
  }
};

export const getCalendarNames = async () => {
  const calendarTokensJSON = localStorage.getItem("calendarTokens");
  const calendarTokens = JSON.parse(calendarTokensJSON);
  return await fetchCalendars(calendarTokens.access_token);
};
export const defaultAvailability = {
  Sunday: [{ startTime: "00:00", endTime: "00:00" }],
  Monday: [{ startTime: "09:00", endTime: "17:00" }],
  Tuesday: [{ startTime: "09:00", endTime: "17:00" }],
  Wednesday: [{ startTime: "09:00", endTime: "17:00" }],
  Thursday: [{ startTime: "09:00", endTime: "17:00" }],
  Friday: [{ startTime: "09:00", endTime: "17:00" }],
  Saturday: [{ startTime: "00:00", endTime: "00:00" }],
};

function formatTimeRange(startTime, endTime) {
  return `${startTime}-${endTime}`;
}

// Function to format schedule for each day
export function formatSchedule(schedule) {
  const formattedSchedule = {};
  for (const day in schedule) {
    const timeRanges = schedule[day];
    const formattedTimeRanges = timeRanges.map((timeRange) =>
      formatTimeRange(timeRange.startTime, timeRange.endTime)
    );
    formattedSchedule[day] = formattedTimeRanges;
  }
  return formattedSchedule;
}

export const convertAvailabilityData = (availabilityData) => {
  const convertedAvailability = {};
  for (const day in availabilityData) {
    convertedAvailability[day] = availabilityData[day].map((timeRange) => {
      const [startTime, endTime] = timeRange.split("-");
      return { startTime, endTime };
    });
  }
  return convertedAvailability;
};
export const findOldestDateItem = (items) => {
  return items.reduce((oldest, current) => {
    const currentDate = new Date(current.created_at);
    const oldestDate = new Date(oldest.created_at);
    return currentDate < oldestDate ? current : oldest;
  }, items[0]);
};
export function convertToCamelCase(key) {
  // Split the key by uppercase letters
  const parts = key.split(/(?=[A-Z])/);
  
  // Convert the first part to lowercase
  parts[0] = parts[0].toLowerCase();
  
  // Join the parts with no delimiter and return
  return parts.join('');
}

export const exportData = (data, fileName, type) => {
  const blob = new Blob([data], { type });
   const urll = window.URL.createObjectURL(blob);
   const a = document.createElement("a");
   a.href = urll;
   a.download = "conversations.csv";
   a.click();
   window.URL.revokeObjectURL(urll);
 };