import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import CustomDuration from "../CustomDuration";
import useToolUserRunStaticFunction from "../useToolUserRunStaticFunction";
import SelectTimzone from "../SelectTimezone";
import { getHostDomain } from "../../../utils/utils";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ModMedSelectField from "../ModMedSelectField";

export default function ModeMedModal({ toolUserForm, onClose }) {
  const restModSelectFieldsNames = Object.freeze({
    LOCATION_ID: "location_id",
    PRACTIONER_ID: "practitioner_id",
    APPOINTMENT_TYPE: "appointment_type",
  });
  const { watch, setValue, handleSubmit, formState, register } = toolUserForm;
  const { tool_user_id, credentials = {} } = watch();
  const { configuration = {} } = credentials;
  const getStaticFunctionParams = (function_name) => {
    return {
      tool_user_id,
      staticFnPayload: {
        function_name,
      },
    };
  };
  const locationParms = getStaticFunctionParams("get_locations");
  const practitionerParms = getStaticFunctionParams("get_practitioners");
  const appointmentTypesParams = getStaticFunctionParams(
    "get_appointment_types"
  );
  const { status: locationFetchStatus, response: locationFetchResponse } =
    useToolUserRunStaticFunction(locationParms);
  const { status: practionersFetchStatus, response: practionersFetchResponse } =
    useToolUserRunStaticFunction(practitionerParms);
  const {
    status: appointmentTypesFetchStatus,
    response: appointmentTypeFetchResponse,
  } = useToolUserRunStaticFunction(appointmentTypesParams);
  const loading = [
    locationFetchStatus,
    practionersFetchStatus,
    appointmentTypesFetchStatus,
  ].includes("loading");
  const baseUrl = getHostDomain();
  const navigate = useNavigate();
  const onSubmitModeMedConf = async (data) => {
    try {
      await fetchController(
        baseUrl + `/api/v1/tool/${data.tool_user_id}/LinkToolUser`,
        "PUT",
        {
          credentials,
        }
      );
      toast.success("Tool setup done");
      onClose();
      navigate(`/tools`);
    } catch (error) {
      toast.error("Some error occured");
    }
  };

  const getPractionerOptions = (response = []) =>
    response
      ? response.map(({ resource }) => {
          const practioner_name = resource.name
            .map(({ family, given }) => `${family} ${given.join(":")}`)
            .join(",");
          return {
            label: practioner_name,
            value: resource.id,
            practioner_name,
          };
        })
      : [];
  const getAppointmentTypeOptions = (response) =>
    response
      ? response?.compose?.include.length
        ? response?.compose?.include[0]?.concept.map((item) => ({
            label: item.display,
            value: item.code,
          }))
        : []
      : [];
  const getLocationOptions = (response = []) =>
    response
      ? response.map(({ resource }) => ({
          label: resource.name,
          value: resource.id,
        }))
      : [];
  const onChangePractionerId = (configurationName) => (field) => {
    onChangeDefaultModSelectField(configurationName)(field);
    setValue(
      `credentials.configuration.practioner_name`,
      field.practioner_name
    );
  };
  const restModMedSelectFields = [
    {
      name: restModSelectFieldsNames.LOCATION_ID,
      label: "Select location",
      response: locationFetchResponse,
      onChange: onChangeDefaultModSelectField,
      getOptions: getLocationOptions,
    },
    {
      name: restModSelectFieldsNames.PRACTIONER_ID,
      label: "Select practitioner",
      response: practionersFetchResponse,
      onChange: onChangePractionerId,
      getOptions: getPractionerOptions,
    },
    {
      name: restModSelectFieldsNames.APPOINTMENT_TYPE,
      label: "Select appointment type",
      response: appointmentTypeFetchResponse,
      onChange: onChangeDefaultModSelectField,
      getOptions: getAppointmentTypeOptions,
    },
  ];

  const renderSelectFields = (field, index) => {
    return (
      <ModMedSelectField
        key={index}
        field={field}
        value={configuration[field.name]}
      />
    );
  };
  return (
    <form onSubmit={handleSubmit(onSubmitModeMedConf)}>
      <ModalBody>
        {loading ? (
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Spinner />
          </Flex>
        ) : (
          <Stack spacing={2}>
            <CustomDuration toolUserForm={toolUserForm} />
            <SelectTimzone toolUserForm={toolUserForm} />
            {restModMedSelectFields.map(renderSelectFields)}
            <FormControl isRequired>
              <FormLabel fontWeight={"bold"}>Practitioner Name</FormLabel>
              <Input
                {...register("credentials.configuration.practioner_name")}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontWeight={"bold"}>Email</FormLabel>
              <Input
                type="email"
                {...register("credentials.configuration.email")}
              />
            </FormControl>
          </Stack>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          isLoading={formState.isSubmitting}
          colorScheme="blue"
          mr={3}
          type="submit"
        >
          Finish
        </Button>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </form>
  );

  function onChangeDefaultModSelectField(configurationName) {
    return ({ value }) => {
      setValue(configurationName, value);
    };
  }
}
