import { Box, Flex, FormLabel, Tooltip } from "@chakra-ui/react";
import { IoInformationCircleOutline } from "react-icons/io5";

export default function FormLabelWithInfoTooltip({ label, tip, isRequired }) {
  return (
    <Flex alignItems={"center"} justifyContent={"flex-start"}>
      <FormLabel>{label} <Box as="span" color="red">{isRequired ? "*" : ""}</Box></FormLabel>
      {tip ? <Tooltip aria-label="Info" hasArrow label={tip}>
        <span>
          <IoInformationCircleOutline size={15} />
        </span>
      </Tooltip> :"" }
     
    </Flex>
  );
}
