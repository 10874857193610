import React, { useState, useEffect, useContext } from "react";
import Layout from "../Layout/Layout";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue } from "@chakra-ui/react";
import Conversations from "./../Conversations/Conversations";
import { useParams, useNavigate } from "react-router-dom";
import ConversationsUtility from "../Conversations/ConversationsUtility";
import ActivityTable from "./ActivityTable";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
const Activity = () => {
  const [tab, setTab] = useState("conversations");
  const params = useParams();
  const [defaultTab, setDefaultTab] = useState(
    params.name === "conversations"
      ? 0
      : params.name === "captured-forms"
      ? 1
      : 0
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (tab !== "conversations") {
      navigate(`/engagements/${tab.toLowerCase().split(" ").join("-")}`);
    }
  }, [tab]);
  const agency = useContext(AgencyContext);
  const { textColor, colorPreset } = agency;
  return (
    <Layout>
      <Box
        border="1px"
        borderRadius="md"
        h="90svh"
        padding={4}
        overflowY={"auto"}
      >
        <Tabs defaultIndex={defaultTab}>
          <TabList
            onClick={(e) => {
              setTab(e.target.innerText);
            }}
           
          >
            <Tab color={textColor}>Conversations</Tab>
            <Tab color={textColor}>Captured Forms</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Box>
                <ConversationsUtility
                  tab={tab}
                  isActive={defaultTab == 0 ? true : false}
                />
              </Box>
            </TabPanel>
            <TabPanel>
              <Box>
                <ActivityTable tab={tab} />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
};

export default Activity;
