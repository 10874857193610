/* eslint-disable no-useless-computed-key */
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Select,
  useColorModeValue,
  theme
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import Table from "../Tables/Table";
// import Select from "react-select";
import CapturedFormsTour from "./CapturedFormsTour";
import moment from "moment";
import { CSVLink } from "react-csv";
import { FaFileDownload } from "react-icons/fa";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import CustomSelect from "../../utils/CustomSelect/CustomSelect";
import { getHostDomain } from "../../utils/utils";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "42px",
    width: "200px",
  }),
};

const ActivityTable = () => {
  const baseUrl = getHostDomain();
  const [isLoading, setIsLoading] = useState(false);
  const [allFormsData, setAllFormsData] = useState([]);
  const [formsOptions, setFormsOptions] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [heads, setHeads] = useState([]);
  const [tableData, setTableData] = useState([]);
 
  const [capturedFormsTourVisibility, setCapturedFormsTourVisibility] =
    useState(localStorage.getItem("capturedFormsTourVisibility") ? false : true);

  const onCloseCapturedFormsTour = () => {
    localStorage.setItem("capturedFormsTourVisibility", false);
    setCapturedFormsTourVisibility(false);
  };

  const hitFetch = async (formId) => {
    const endpoint = `/api/v1/capturedform/${formId}?page=1&size=50`;
    try {
      setIsLoading(true);
      const urlHit = baseUrl + endpoint;
      const getData = await fetchController(urlHit, "GET");

      if (getData?.data) {
        const updatedData = getData.data.items.map((item) => {
          let obj = {
            id: item.id,
            ["Created at"]: moment(item.created_at).format("YYYY-MM-DD HH:mm"),
          };
          for (let key in item.field_values) {
            obj[key] = item.field_values[key];
          }
          return obj;
        });
        setTableData(updatedData);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const loadForm = async () => {
    try {
      setIsLoading(true);
      const endpoint = "/api/v1/form/list";
      const urlHit = baseUrl + endpoint;
      const getForm = await fetchController(urlHit, "GET");

      setAllFormsData(getForm.data.items);
      const options = getForm.data.items.map((val) => {
        return { label: val.name, value: val.id };
      });
      setFormsOptions(options);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadForm();
  }, []);

  const handleOnChange = (value) => {
    console.log(value)
    setIsLoading(true);
    setSelectedFormId(value);
    if (value) {
      const fieldOfSelectedForm = allFormsData?.filter(
        (val) => val.id === value.value
      )[0];
      setSelectedForm(fieldOfSelectedForm);
      const columns = [...fieldOfSelectedForm?.fields, "Created at"].map(
        (key) => ({
          Header: typeof key === "object" ? key.name : key,
          accessor: typeof key === "object" ? key.name : key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        })
      );
      setHeads(columns);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedForm) {
      hitFetch(selectedForm.id);
    }
  }, [selectedForm]);

  const csvHeaders = heads.map((col) => ({
    label: col.Header,
    key: col.accessor,
  }));
  const filename = "capturedforms.csv";
  // const tableBg = useColorModeValue("white", theme.colors.gray[700]);
  const agency = useContext(AgencyContext);
  // const color_preset = agency?.data?.branding?.color_preset;
  // const textColor = useColorModeValue(color_preset || "#3182ce", "#3182ce");
  const { textColor, colorPreset } = agency;
  return (
    <>
      <Box h="75vh" p="5" >
        <Heading flex={2} color={textColor} fontSize="xl">
          Captured Forms
        </Heading>
        {capturedFormsTourVisibility ? (
          <CapturedFormsTour
            onCloseCapturedFormsTour={onCloseCapturedFormsTour}
          />
        ) : null}
        <Flex justifyContent="space-between" mb="4">
          <Spacer />
          <Flex gap={2}>
            {selectedForm && tableData.length > 0 && (
              <Button colorScheme="blue" variant="outline">
                <CSVLink data={tableData} headers={csvHeaders} filename={filename}>
                <FaFileDownload />
                </CSVLink>
              </Button>
            )}
            {/* <Select
              value={selectedFormId}
              onChange={handleOnChange}
              options={formsOptions}
              placeholder="Select a Form"
              isClearable
              styles={customStyles}
            /> */}
        
            <CustomSelect
              options={formsOptions}
              value={selectedFormId?.value}
              onChange={(selectedOption) => {
                handleOnChange(selectedOption?.value ? selectedOption :null)
              }}
              placeholder="Select a Form"
            />
          </Flex>
        </Flex>
        {isLoading ? (
          <Loader />
        ) : (
            // <Box height="auto" overflowY="scroll">
            <Table
              fetch={hitFetch}
              columns={heads}
              data={tableData}
              table="capturedForm"
              selectedForm={selectedForm}
            />
          // </Box>
        )}
      </Box>
    </>
  );
};

export default ActivityTable;
