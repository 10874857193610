import { Flex, Grid, Stack, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FormFieldGenerator from "./FormFieldGenerator";
export default function ActualFormAfterConfiguration({
  fields,
  onSelectFieldFromListOfFields,
  selectedField,
  onSetFormFields,
  submitLabel = "Submit",
}) {
  const moveField = useCallback((dragIndex, hoverIndex) => {
    onSetFormFields((prevFields) => {
      const draggedField = prevFields[dragIndex];
      const newFields = [...prevFields];
      newFields.splice(dragIndex, 1);
      newFields.splice(hoverIndex, 0, draggedField);
      return newFields;
    });
  }, []);
  const renderFields = useCallback(
    (field, index) => {
      return (
        <FormFieldGenerator
          field={field}
          index={index}
          key={field.name}
          moveField={moveField}
          selectedField={selectedField}
          onSelectFieldFromListOfFields={onSelectFieldFromListOfFields}
        />
      );
    },
    [selectedField]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid borderRadius={"lg"} gap={3} padding={2} border={"1px solid lightgray"} backgroundColor={"white"}>
        <Text textAlign={"center"}>
          <i>Click on field to update.</i>
        </Text>
        <Stack spacing={1}>
          {fields.map((field, index) => renderFields(field, index))}
        </Stack>

        <Flex justifyContent={"center"} alignItems={"center"}>
          <button
            style={{
              backgroundColor: "#f1f1f1",
              padding: "0.5rem",
              borderRadius: "0.5rem",
              fontWeight: "bold",
              cursor : "auto"
            }}
            margin={"auto"}
            colorScheme="blue"
            title="Submit button for the form"
            size={"md"}
          >
            {submitLabel}
          </button>
        </Flex>
      </Grid>
    </DndProvider>
  );
}
