import { Box, Flex, Heading } from "@chakra-ui/react";

import { BsTools } from "react-icons/bs";
export default function ToolsNotFound() {
  return (
    <Box height={"50svh"}>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"100%"}
      >
        <BsTools size={100} />
        <Heading>No tools found!</Heading>
      </Flex>
    </Box>
  );
}
