import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import ApiKey from "./APIKey/ApiKey";
import Billing from "./Billing/Billing";
import Channels from "./Channels";
import Profile from "./Profile/Profile";
import Usage from "./Usage/Usage";
import Webhooks from "./Webhooks";
import ManageTeams from "./Manage-Teams/ManageTeams";
import YourKeys from "./YourKeys/YourKeys";
import { fetchController } from "../../utils/FetchController/fetchController";
import { AgencyContext } from "./AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";
import Agent from "./Agent/Agent";
import AuthContext from "../Auth/AuthContext";

const Settings = () => {
  const baseUrl = getHostDomain();
  const navigate = useNavigate();
  const { name = "profile" } = useParams();
  // const orgsEndPoint = `/api/v1/user/list/orgs`;

  const [tabs, setTabs] = useState([]);
  const { authUser } = useContext(AuthContext);
  const userRoles = authUser?.roles || []
  const isSuperAdmin = userRoles.includes("superadmin") || authUser?.is_superuser
  
  useEffect(() => {
    const getOrgs = async () => {
      try {
        // const response = await fetchController(url + orgsEndPoint, "GET");
        // const orgs = response.data.items;
        let defaultTabs = [
          {
            name: "Profile",
            route: "/settings/profile",
            children: <Profile tab={"Profile"} activeTab={name} />,
          },
          {
            name: "Channels",
            route: "/settings/channels",
            children: <Channels tab={"Channels"} activeTab={name} />,
          },
          {
            name: "Webhooks",
            route: "/settings/webhooks",
            children: <Webhooks tab={"webhooks"} activeTab={name} />,
          },
          {
            name: "Usage",
            route: "/settings/usage",
            children: <Usage tab={"Usage"} activeTab={name} />,
          },
          {
            name: "Billing",
            route: "/settings/billing",
            children: <Billing tab={"Billing"} activeTab={name} />,
          },
          {
            name: "API Keys",
            route: "/settings/api-keys",
            children: <ApiKey tab={"API Keys"} activeTab={name} />,
          },
          {
            name: "Your Keys",
            route: "/settings/Your-Keys",
            children: <YourKeys tab={"Your-Keys"} activeTab={name} />,
          },
        ];
        if (isSuperAdmin){
          defaultTabs = [...defaultTabs, {
            name: "Human Agents",
            route: "/settings/agents",
            children: <Agent tab={"Agents"} activeTab={name} />,
          },]
        }
        // if (orgs.length > 0) {
        //   setTabs([
        //     ...defaultTabs,

        //     {
        //       name: "Manage-Teams",
        //       route: "/settings/manage-teams",
        //       children: <ManageTeams tab={"Manage-Teams"} activeTab={name} />,
        //     },
        //   ]);
        // } else {
        setTabs(defaultTabs);
        // }
      } catch (error) {
        console.error("Error fetching orgs:", error);
        // Handle error
      }
    };

    getOrgs();
  }, [name]); // Added name to dependency array to trigger effect on route change

  const defaultTab = tabs.map((val) => val.route).indexOf(`/settings/${name}`);
  const agency = useContext(AgencyContext);
  // const color_preset = agency?.data?.branding?.color_preset
  // const textColor = useColorModeValue(color_preset || "#3182ce", "#3182ce")

  const { textColor, buttonColorScheme, colorPreset } = agency;
  return (
    <Layout>
      <Box minH={"81svh"}>
        <Box>
          <Tabs isLazy index={defaultTab < 0 ? 0 : defaultTab}>
            <TabList>
              {tabs.map((val) => (
                <Tab
                  key={val.route}
                  onClick={() => {
                    navigate(val.route);
                  }}
                  color={textColor}
                >
                  {val.name}
                </Tab>
              ))}
            </TabList>

            <TabPanels>
              {tabs.map((val) => (
                <TabPanel key={val.route}>
                  <Box mt={4}>{val.children}</Box>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </Layout>
  );
};

export default Settings;
