import { FormControl, FormLabel } from "@chakra-ui/react";
import Select from "react-select";
import useToolUserRunStaticFunction from "../Tools/useToolUserRunStaticFunction";
import FormLabelWithInfoTooltip from "./FormLabelWithInfoTooltip";
export default function LeadConnectorFields({
  leadConnectorAuths = [],
  setValue,
  watch,
}) {
  const widget_type = watch("widget_type") || "leadconnector";
  const callStatusOptions = [
    {
      value: "busy",
      label: "Busy",
    },
    {
      value: "canceled",
      label: "Cancelled",
    },
    {
      value: "voicemail",
      label: "Voicemail",
    },
    {
      value: "no-answer",
      label: "No-Answer",
    },
  ];
  const selectedCallStatusMap = (watch("call_status") || []).reduce(
    (prev, current) => {
      prev[current] = true;
      return prev;
    },
    {}
  );
  const toolUserOptions = leadConnectorAuths.map((leadConnectorAuth) => ({
    value: leadConnectorAuth.id,
    label: leadConnectorAuth.name,
  }));
  const channels = ["SMS", "Email", "WhatsApp", "GMB", "IG", "FB", "Live_Chat"];
  const makeChannelOptions = (channel) => ({
    value: channel,
    label: channel,
  });
  const onChangeChannels = (selectedChannelOptions) => {
    setValue(
      "channels",
      selectedChannelOptions.map(
        (selectedChannelOption) => selectedChannelOption.value
      )
    );
  };
  const onChangeToolUser = ({ value }) => {
    setValue("tool_user_id", value);
  };
  const { response } = useToolUserRunStaticFunction({
    tool_user_id: watch("tool_user_id"),
    staticFnPayload: {
      function_name: "get_location_id",
    },
  });
  const locations = response?.location_id || [];
  const locationOptions = locations.map((location) => ({
    value: location,
    label: location,
  }));
  return (
    <>
      <FormControl isRequired>
        <FormLabelWithInfoTooltip
          label={"GoHighLevel Config"}
          tip={"Incoming calls for this account will now be monitored"}
        />

        <Select
          value={toolUserOptions.find(
            (toolUserAuthOption) =>
              toolUserAuthOption.value === watch("tool_user_id")
          )}
          options={toolUserOptions}
          required
          onChange={onChangeToolUser}
        />
      </FormControl>
      {widget_type === "leadconnector" ? (
        <FormControl isRequired>
          <FormLabel>Select channels</FormLabel>
          <Select
            isMulti
            required
            value={(watch("channels") || []).map(makeChannelOptions)}
            onChange={onChangeChannels}
            options={channels.map(makeChannelOptions)}
          />
        </FormControl>
      ) : (
        <FormControl>
          <FormLabelWithInfoTooltip
            label={"GoHighLevel Call Status"}
            tip={
              "AI Voice call will be triggered when any call has one of these statuses"
            }
          />
          <Select
            options={callStatusOptions}
            value={callStatusOptions.filter(
              (callStatusOption) =>
                callStatusOption.value in selectedCallStatusMap
            )}
            isMulti
            onChange={(selectedCallStatusList) => {
              setValue(
                "call_status",
                selectedCallStatusList.map(
                  (callStatusOption) => callStatusOption.value
                )
              );
            }}
          />
        </FormControl>
      )}
      <FormControl isRequired>
        <FormLabelWithInfoTooltip
          label={"GoHighLevel Location ID"}
          tip={"Location ID of the monitored account"}
        />
        <Select
          required
          value={locationOptions.find(
            (locationOption) => locationOption.value === watch("location_id")
          )}
          onChange={({ value }) => {
            setValue("location_id", value);
          }}
          options={locationOptions}
        />
      </FormControl>
    </>
  );
}
