import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import Select from "react-select";
import useToolUserRunStaticFunction from "./useToolUserRunStaticFunction";

export default function SelectPostgresColName({
  getStaticFunctionParams,
  credentials,
  toolUserForm,
}) {
  const { setValue, register, formState, watch } = toolUserForm;
  const { configuration } = credentials;

  const selectedColNameMap = (watch("credentials.configuration.lookup_columns") || []).reduce(
    (prev, current) => {
      prev[current] = true;
      return prev;
    },
    {}
  );

  const colNameParams = getStaticFunctionParams({
    function_name: "get_column_names",
    arguments: {
      table_name: configuration?.lookup_table || "",
    },
  });

  const { status: colNameFetchStatus, response: colNameFetchResponse } =
    useToolUserRunStaticFunction(colNameParams);

  const colNameOptions = colNameFetchResponse
    ? colNameFetchResponse.map((item) => ({
        value: item,
        label: item,
      }))
    : [];

  return (
    <FormControl
      isInvalid={formState.errors?.credentials?.configuration?.lookup_columns}
      isRequired
    >
      <FormLabel>Columns to look-up into</FormLabel>
      <Select
        value={colNameOptions.filter(
          (colName) => colName.value in selectedColNameMap
        )}
        {...register("credentials.configuration.lookup_columns", {
            required: "Select at least one column",
          })}
        onChange={(selectedOptions) => {
          setValue(
            "credentials.configuration.lookup_columns",
            selectedOptions.map(({ value }) => value)
          );
        }}
        options={colNameOptions}
        isMulti
        isLoading={colNameFetchStatus === "loading"}
      />
      {formState.errors?.credentials?.configuration?.lookup_columns && (
        <FormErrorMessage>
          {formState.errors?.credentials?.configuration?.lookup_columns.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
