import { Box, Button, Flex, Input, Spacer, Stack } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import FormLabelWithInfoTooltip from "../Widgets/FormLabelWithInfoTooltip";
const Smtp = ({ brandingForm }) => {
  const baseUrl = getHostDomain();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("idle")
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = brandingForm;
  const agency = useContext(AgencyContext);
  const { textColor, agencyData, buttonColorScheme } = agency;

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await fetchController(
        baseUrl + `/api/v1/agency/${agencyData.id}`,
        "PUT",
        data
      );
      toast.success("SMTP data updated successfully");
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wront...");
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickOnTestSmtp=async()=>{
    try {
      setStatus("submitting")
      const response= await fetchController(
        baseUrl + `/api/v1/login/${agencyData?.id}/test_email/`,
        "POST",
        {}
      );
      toast.success(response?.message);
      setStatus("idle")
    } catch (error) {
      setStatus("failed")
      toast.error("Something went wrong...");
    }
  }
 const isTestSmtpBtnLoading= status === "submitting"
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          spacing={4}
          mb="4"
          width={"50%"}
          justifyContent={"center"}
        >
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"SMTP host"} />
            <Input
              placeholder="SMTP host"
              {...register("branding.smtp.host", { required: true })}
            />
            {errors?.branding?.smtp?.host && (
              <p style={{ color: "red", fontSize: "11px" }}>Host is required</p>
            )}
          </Box>
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"SMTP port"} />
            <Input
              placeholder="SMTP port"
              {...register("branding.smtp.port", { required: true })}
            />
            {errors?.branding?.smtp?.port && (
              <p style={{ color: "red", fontSize: "11px" }}>Port is required</p>
            )}
          </Box>
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Username"} />
            <Input
              placeholder="Username"
              {...register("branding.smtp.username", { required: true })}
            />
            {errors?.branding?.smtp?.username && (
              <p style={{ color: "red", fontSize: "11px" }}>
                Username is required
              </p>
            )}
          </Box>
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Password"} />
            <Input
              placeholder="Password"
              type="password"
              {...register("branding.smtp.password", { required: true })}
            />
            {errors?.branding?.smtp?.password && (
              <p style={{ color: "red", fontSize: "11px" }}>
                Password is required
              </p>
            )}
          </Box>
          <Box flex="1">
            <FormLabelWithInfoTooltip label={"Email address"} />
            <Input
              placeholder="Email address"
              {...register("branding.smtp.email", { required: true })}
            />
            {errors?.branding?.smtp?.email && (
              <p style={{ color: "red", fontSize: "11px" }}>
                Email is required
              </p>
            )}
          </Box>
          <Flex>
            <Button
              isLoading={isLoading}
              width="80px"
              colorScheme={buttonColorScheme}
              type="submit"
              mt="4"
            >
              Submit
            </Button>
            <Spacer />
            <Button
              isLoading={isTestSmtpBtnLoading}
              width="100px"
              colorScheme={"red"}
              mt="4"
              onClick={handleClickOnTestSmtp}
            >
              Test SMTP
            </Button>
          </Flex>
         
           
        </Stack>
       
      </form>
    </>
  );
};

export default Smtp

