import { useCallback, useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function useTwilioAccounts({ widgetType, twilio_auth_id }) {
  const baseUrl = getHostDomain();
  const [accounts, setAccounts] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const getTwlioAccounts = useCallback(async () => {
    const response = await fetchController(
      baseUrl + "/api/v1/channel/twilio/list"
    );
    setAccounts(response.data.items);
  }, []);
  const getPhoneNumbers = useCallback(async () => {
    const response = await fetchController(
      baseUrl + `/api/v1/channel/twilio/${twilio_auth_id}/phone_numbers`
    );
    setPhoneNumbers(response);
  }, [twilio_auth_id]);

  useEffect(() => {
    if (
      widgetType === "phone" ||
      widgetType === "html_call" ||
      widgetType === "leadconnector_call"
    )
      getTwlioAccounts();
  }, [widgetType]);
  useEffect(() => {
    if (twilio_auth_id) getPhoneNumbers();
    else setPhoneNumbers([]);
  }, [twilio_auth_id]);
  return { accounts, phoneNumbers };
}
