import { Badge, Box, Flex, Stack, Text } from "@chakra-ui/react";
import { CiChat1 } from "react-icons/ci";

export default function LiveUsers({ activeUsers, currentUserProps }) {
  const { currentUser, onSetCurrentUser } = currentUserProps;
  const users = Object.keys(activeUsers);
  return (
    <Stack spacing={2}>
      {users.map((activeUser, index) => (
        <Box
          cursor={"pointer"}
          p={2}
          onClick={() =>
            onSetCurrentUser({
              user: activeUser,
              messages: activeUsers[activeUser].messages,
              accepted: activeUsers[activeUser].accepted,
            })
          }
          bg={currentUser?.user === activeUser ? "gray.200" : "white"}
          border={"1px solid lightgray"}
          borderRadius={"md"}
          key={index}
        >
          <Text>Visitor {index + 1}</Text>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Badge
              colorScheme={activeUsers[activeUser].accepted ? "green" : "gray"}
            >
              {activeUsers[activeUser].accepted ? "Accepted" : "Not Accepted"}
            </Badge>
            <Badge
              colorScheme={activeUsers[activeUser].active ? "green" : "gray"}
            >
              {activeUsers[activeUser].active ? "Online" : "Offline"}
            </Badge>
            <Flex justifyContent={"center"} alignItems={"center"} gap={1}>
              <Text>{activeUsers[activeUser].messages?.length || 0}</Text>{" "}
              <CiChat1 />
            </Flex>
          </Flex>
        </Box>
      ))}
    </Stack>
  );
}
