import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import useRecaptcha from "../Auth/useRecaptcha";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const AddSubAccount = ({ isOpen, onOpen, onClose, loadSubAccount }) => {
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const {  buttonColorScheme,agencyData } = agency;
  const agency_id = agencyData?.id
  const [status, setStatus] = useState("idle");
  const [show, setShow] = useState(false);
  const getRecaptchaToken = useRecaptcha();
  const defaultSubAcc = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: defaultSubAcc,
  });

  const onSubmit = async (data) => {
    try {
      setStatus("loading");
      const reCaptchaToken = await getRecaptchaToken("subaccountSignup");
      data.captcha_response = reCaptchaToken;
      const endpoint = `/api/v1/register?agency_id=${agency_id}`;
      const url = baseUrl + endpoint;
      const payload = {
        ...data,
        is_active:false
      };
      const method = "POST";
      await fetchController(url, method, payload);
      toast.success("Sub account Added successfully");
      reset();
      onClose();
      setStatus("success");
      await loadSubAccount()
    } catch (error) {
      console.error({error});
      toast.error("Some error occurred");
      setStatus("failed");
    } 
  };
  const handleClose = () => {
    reset();
    onClose();
  };
  const generateRandomPassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+";
    const passwordLength = 8;
    let randomPassword = "";
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomPassword += characters[randomIndex];
    }
    setValue("password", randomPassword);
  };
  return (
    <>
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={() => {
          handleClose();
        }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent height="auto" width="95%" maxWidth="600px">
          <ModalHeader>{"Add Sub-account"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" mb="4">
                <Box flex="1">
                  <Input
                    type="text"
                    placeholder="First Name"
                    {...register("first_name", {
                      required: "First Name is required.",
                    })}
                  />
                  {errors.first_name && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      {errors?.first_name?.message}
                    </p>
                  )}
                </Box>

                <Box flex="1">
                  <Input
                    placeholder="Last Name"
                    {...register("last_name", {
                      required: "Last Name is required.",
                    })}
                  />
                  {errors.last_name && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      {errors?.last_name?.message}
                    </p>
                  )}
                </Box>
              </Stack>
              <Box flex="1" mb="4">
                <Input
                  type="email"
                  placeholder="Email"
                  {...register("email", { required: "Email is required." })}
                />
                {errors.email && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    {errors?.email?.message}
                  </p>
                )}
              </Box>
              <Box flex="1">
                <InputGroup size="md">
                  <Input
                    type={show ? "text" : "password"}
                    placeholder="Click to generate password"
                    onClick={generateRandomPassword}
                    {...register("password", {
                      required: "Password is required.",
                    })}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShow(!show)}
                    >
                      {show ? <IoEyeOffOutline /> : <IoEyeOutline />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    Password is required
                  </p>
                )}
              </Box>
              <Button
                isLoading={status === "loading"}
                colorScheme={buttonColorScheme}
                type="submit"
                mt="4"
              >
                Submit
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddSubAccount;
