import {
  Box,
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import { FileUploader } from "react-drag-drop-files";
import DragAndDropFileButton from "./DragDropFileButton";

export default function FileDragUploader({
  handleChange,
  fileTypes,
  requriedMessage,
  files,
  handleRemove,
  placeholder,
}) {
  return (
    <Box marginTop={2}>
      {files[0]?.type !== "text/csv" && (
        <>
          <FileUploader
            style={{
              maxWidth: "none",
            }}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            multiple={true}
            required
          >
            <DragAndDropFileButton placeholder={placeholder} />
          </FileUploader>
          {files.length === 0 && (
            <Text color={"red"} fontSize={"xsm"}>
              {requriedMessage}
            </Text>
          )}
        </>
      )}

      <Flex
        marginBlock={2}
        gap={2}
        justifyContent={"center"}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        {files.map((file, index) => (
          <Tag
            key={index}
            size={"md"}
            borderRadius="full"
            variant="solid"
            colorScheme="blue"
          >
            <TagLabel>
              {file.name.length > 22
                ? `${file.name.slice(0, 22)}...`
                : file.name}
            </TagLabel>
            <TagCloseButton onClick={() => handleRemove(index)} />
          </Tag>
        ))}
      </Flex>
    </Box>
  );
}
