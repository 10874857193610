import { addEdge, applyEdgeChanges, applyNodeChanges } from "@xyflow/react";
import { useCallback, useState } from "react";
export default function useAppFlowNodes() {
  const initialNodes = [
    {
      id: "1",
      type: "input",
      data: { label: "Start Conversation" },
      position: { x: 250, y: 0 },
    },
    {
      id: "2",
      data: { label: "User Input: Hello" },
      position: { x: 150, y: 100 },
    },
    {
      id: "3",
      data: { label: "User Input: Hi" },
      position: { x: 350, y: 100 },
    },
    {
      id: "4",
      data: { label: "Bot Response: Hello! How can I assist you?" },
      position: { x: 100, y: 200 },
    },
    {
      id: "5",
      data: { label: "Bot Response: Hi there! What can I do for you?" },
      position: { x: 300, y: 200 },
    },
    {
      id: "6",
      data: { label: "User Input: Need help with an order" },
      position: { x: 100, y: 300 },
    },
    {
      id: "7",
      data: { label: "User Input: Just browsing" },
      position: { x: 300, y: 300 },
    },
    {
      id: "8",
      type: "output",
      data: { label: "Bot Response: Sure, let me help with your order." },
      position: { x: 50, y: 400 },
    },
    {
      id: "9",
      type: "output",
      data: { label: "Bot Response: Feel free to ask if you need anything." },
      position: { x: 350, y: 400 },
    },
  ];
  const initialEdges = [
    {
      id: "e1-2",
      source: "1",
      target: "2",
      type: "smoothstep",
      animated: true,
    },
    {
      id: "e1-3",
      source: "1",
      target: "3",
      type: "smoothstep",
      animated: true,
    },
    {
      id: "e2-4",
      source: "2",
      target: "4",
      type: "smoothstep",
      animated: true,
    },
    {
      id: "e3-5",
      source: "3",
      target: "5",
      type: "smoothstep",
      animated: true,
    },
    {
      id: "e4-6",
      source: "4",
      target: "6",
      type: "smoothstep",
      animated: true,
    },
    {
      id: "e5-7",
      source: "5",
      target: "7",
      type: "smoothstep",
      animated: true,
    },
    {
      id: "e6-8",
      source: "6",
      target: "8",
      type: "smoothstep",
      animated: true,
    },
    {
      id: "e7-9",
      source: "7",
      target: "9",
      type: "smoothstep",
      animated: true,
    },
  ];
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );
  const onAddNewNode = useCallback((values) => {
    const node = makeNodeByData(values);
    setNodes((prev) => [...prev, node]);
  }, []);
  const onEditNode = useCallback((values) => {
    setNodes((prev) =>
      prev.map((prevNode) => (prevNode.id === values.id ? values : prevNode))
    );
  }, []);
  const onDeleteEdge = useCallback((id) => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
  }, []);
  const operations = {
    onAddNewNode,
    onDeleteEdge,
    onEditNode,
  };
  const makeNodeByData = (values) => {
    const id = `${Date.now()}`;
    return {
      ...values,
      id,
      position: { x: 0, y: 0 },
    };
  };

  return {
    onConnect,
    onEdgesChange,
    onNodesChange,
    edges,
    nodes,
    operations,
  };
}
