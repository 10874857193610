import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useClipboard,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { LiaToolsSolid } from "react-icons/lia";
import useLog from "./useLog";
function Field({ label, value }) {

  return value ? (
    <Text>
      <strong>{label}</strong> - {value}
    </Text>
  ) : null;
}

function ShowLogTabs(props) {
  return (
    <Tabs fontSize={"sm"} size="sm" variant="enclosed">
      <TabList>
        {props.tabsData.map((tabData, index) => (
          <Tab key={index}>{tabData.tab}</Tab>
        ))}
          {props.jsonTabData.map((tabData, index) => (
          <Tab key={index}>{tabData.tab}</Tab>
        ))}
      </TabList>
      
      <TabPanels>
        {props.tabsData.map((tabData, index) => (
          <TabPanel key={index}>
            <Stack spacing={1}>
              {tabData.fields.map((field, idx) => (
                <Field key={idx} label={field.label} value={field.value} />
              ))}
            </Stack>
          </TabPanel>
        ))}
        {props.jsonTabData.map((tabData, index) => (
          <TabPanel key={tabData.value}>
            <ShowJson value={tabData.value}/>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}

export default function ShowModal({ isOpen, onClose, selectedLog }) {
  const { isLoading, hasError, log } = useLog({
    selectedLog,
  });
  const tabsData = [
    {
      tab: "Widget",
      fields: [
        { label: "Name", value: log.widget?.name },
        { label: "Type", value: log.widget?.widget_type },
        { label: "Description", value: log.widget?.description },
      ],
    },
    {
      tab: "Tool",
      fields: [
        { label: "Name", value: log.tool?.name },
        { label: "Description", value: log.tool?.description },
        { label: "Category", value: log.tool?.category },
      ],
    },
    {
      tab: "Assistant",
      fields: [
        { label: "Name", value: log.assistant?.name },
        { label: "Description", value: log.assistant?.description },
        { label: "LLM Model", value: log.assistant?.llm_model },
      ],
    },
  ];
const jsonTabData=[{
  tab: "Call response",
  value: JSON.stringify(selectedLog.call_response, null, 2) 
},
{
  tab: "Arguments",
  value: JSON.stringify(selectedLog.call_arguments, null, 2)
},]
  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isLoading ? null : log?.tool_function?.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Flex marginTop={2} justifyContent={"center"} alignItems={"center"}>
              <Spinner />
            </Flex>
          ) : hasError ? (
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Box borderRadius={"full"} p={3}>
                <LiaToolsSolid size={60} />
              </Box>
            </Flex>
          ) : (
            <Stack spacing={2}>
              <Field
                label={"Function description"}
                value={
                  log.tool_function?.details?.openai_spec?.function.description
                }
              />
              <Field
                label={"Function Type"}
                value={log.tool_function?.tool_function_type}
              />
              <ShowLogTabs tabsData={tabsData} jsonTabData={jsonTabData}/>
            </Stack>
          )}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}



const ShowJson=({value})=>{
  const color = useColorModeValue("gray.50", "gray.600");
  const copiedColor = useColorModeValue("gray.100", "gray.700");
  const { onCopy, hasCopied } = useClipboard(value);
  return  <Box
  overflow={"scroll"}
  onClick={onCopy}
  cursor={"pointer"}
  border={"1px solid lightgray"}
  bg={hasCopied ? copiedColor : color}
  title="Click to copy"
  minH={"50px"}
>
  <pre
    style={{
      borderRadius: "3px",
      backgroundColor: color,
      padding: "5px",
      fontSize: "small",
    }}
  >
    {value}
  </pre>
</Box>
}