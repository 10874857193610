import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import Select from "react-select";
import useToolUserRunStaticFunction from "../useToolUserRunStaticFunction";
import { getHostDomain } from "../../../utils/utils";
export default function TwilioConfig({ toolUserForm, onClose }) {
  const baseUrl = getHostDomain();
  const { handleSubmit, formState, watch } = toolUserForm;
  const { credentials = {}, tool_user_id } = watch();
  const onSubmit = async (data) => {
    try {
      await fetchController(
        baseUrl +
          `/api/v1/tool/${data.tool_user_id}/LinkToolUser`,
        "PUT",
        {
          credentials,
        }
      );
      toast.success("Tool setup done");
      onClose();
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const { response, status } = useToolUserRunStaticFunction({
    tool_user_id,
    staticFnPayload: {
      function_name: "get_phone_numbers",
    },
  });
  const options = response
    ? response.map((phoneNumber) => ({
        value: phoneNumber.sid,
        label: phoneNumber.friendly_name,
      }))
    : [];
  const loading = status === "loading";
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        {loading ? (
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Spinner />
          </Flex>
        ) : (
          <Stack>
            <FormControl>
              <FormLabel>Default Phone number</FormLabel>
              <Select
                required
                value={options.find(
                  (option) =>
                    option.label ===
                    (credentials?.configuration || {}).default_phone_number
                )}
                options={options}
                onChange={({ label }) => {
                  toolUserForm.setValue(
                    "credentials.configuration.default_phone_number",
                    label
                  );
                }}
              />
              <FormErrorMessage>
                {formState.errors.type === "required"
                  ? "Field is required"
                  : null}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            colorScheme="blue"
            size={"sm"}
            isLoading={formState.isSubmitting}
            type="submit"
          >
            Finish
          </Button>
          <Button size={"sm"} onClick={onClose}>
            Close
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
}
