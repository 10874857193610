import { useState } from "react";

export default function useFilterTool() {
  const [typeOfTool, setTypeOfTool] = useState("all");
  const onSetTypeOfTool = (tool_type) => {
    setTypeOfTool(tool_type);
  };
  const [search, setSearch] = useState("");
  const onChangeSearch = (e) => {
    setSearch(e.currentTarget.value);
  };
  const filter = {
    typeOfTool,
    onSetTypeOfTool,
    search,
    onChangeSearch,
  };
  return filter;
}
