import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Tag,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { fetchController } from "../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import { getHostDomain } from "../../utils/utils";
import ContactFieldsList from "./ContactList";

const AddPromptModal = ({ isOpen, onClose, refreshTable, selectedPrompt }) => {
  const baseUrl = getHostDomain();
  const [isLoading, setIsLoading] = useState(false);
  const [templateFieldTags, setTemplateFieldTags] = useState([]);

  const finalRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      prompt_template: "",
    },
  });

  const {
    isOpen: isPromptTemplateOpen,
    onOpen: onOpenPromptTemplate,
    onClose: onClosePromptTemplate,
  } = useDisclosure();

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const url = selectedPrompt?.id
        ? `/api/v1/prompt/${selectedPrompt.id}`
        : "/api/v1/prompt";
      const payload = {
        ...data,
      };
      const response = await fetchController(
        baseUrl + url,
        selectedPrompt?.id ? "PUT" : "POST",
        payload
      );

      const customMsg = selectedPrompt?.id
        ? "Prompt Updated Successfully"
        : "Prompt Added Successfully";
      toast.success(response?.message || customMsg);
      refreshTable();
    } catch (error) {
      console.error(error);
      toast.error(error?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (selectedPrompt) {
      setValue("name", selectedPrompt.name);
      setValue("description", selectedPrompt.description);
      setValue("prompt_template", selectedPrompt.prompt_template);
    }
  }, [selectedPrompt]);

  const handlePromptTemplateChange = (e) => {
    const value = e.currentTarget.value;
    setValue("prompt_template", value);

    if (value.includes("/")) onOpenPromptTemplate();
  };

  const extractCustomFieldKeywords = (text) => {
    const regex = /\{{(.*?)\}}/g;
    let matches = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      matches.push(match[1]);
    }
    return matches;
  };

  useEffect(() => {
    const keywords = extractCustomFieldKeywords(watch("prompt_template"));
    setTemplateFieldTags(keywords);
  }, [watch("prompt_template")]);

  return (
    <Modal
      size="xl"
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent height="auto" width="95%" maxWidth="800px">
        <ModalHeader>Add a Prompt</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Stack spacing={2}>
              <Box marginBottom={2}>
                {templateFieldTags.map((keyword, index) => (
                  <Tag key={index} colorScheme="blue" mr={2} mb={2}>
                    {keyword}
                  </Tag>
                ))}
              </Box>
              <FormControl isRequired isInvalid={errors.name}>
                <FormLabel>{"Name"}</FormLabel>
                <Input
                  placeholder="Enter name"
                  {...register("name", { required: "Name is required" })}
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={errors.description}>
                <FormLabel>{"Description"}</FormLabel>
                <Textarea
                  placeholder="Enter description"
                  {...register("description", {
                    required: "Description is required",
                  })}
                  resize="vertical"
                  minHeight="80px"
                />
                <FormErrorMessage>
                  {errors.description && errors.description.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={errors.prompt_template}>
                <FormLabel>{"Prompt template"}</FormLabel>
                <Textarea
                  placeholder="Enter prompt template"
                  {...register("prompt_template", {
                    required: "Prompt template is required",
                  })}
                  resize="vertical"
                  minHeight="300px"
                  onChange={handlePromptTemplateChange}
                />
                <FormErrorMessage>
                  {errors.prompt_template && errors.prompt_template.message}
                </FormErrorMessage>
              </FormControl>
              <Text fontSize="sm" fontStyle="italic" mt={1}>
                {`To personalize, use your contact, dynamic, or your CRM variables within your prompt. Dynamic variables can just be enclosed within double curly braces. For rest, start by pressing "/"`}
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button width="80px" colorScheme="blue" type="submit">
              {isLoading ? <Spinner size="sm" /> : "Submit"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>

      <ContactFieldsList
        isOpen={isPromptTemplateOpen}
        onClose={onClosePromptTemplate}
        getValue={getValues("prompt_template")}
        setValue={setValue}
        watch={watch}
        title="Prompt Template Fields"
        valueKey="prompt_template"
      />
    </Modal>
  );
};

export default AddPromptModal;
