import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { IoMdArrowRoundBack } from "react-icons/io";
import { fetchController } from "../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import PasswordInput from "../Forms/PasswordInput";
import { getHostDomain } from "../../utils/utils";
export default function Twillio({ isOpen, onClose }) {
  const baseUrl = getHostDomain();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: undefined,
  });
  const [status, setStatus] = useState("idle");
  const [currentTwilioAcc, setCurrentTwilioAcc] = useState();
  const onSubmitTwilioAccountDetails = async (values) => {
    try {
      setStatus("loading");
      const { id, ...data } = values;
      await fetchController(
        baseUrl +
          `/api/v1/channel/twilio${id ? `/${id}` : ""}`,
        id ? "PUT" : "POST",
        data
      );
      toast.success(id ? "Channel updated " : "Channel created");
      setCreatingNewAccount(false);
      fetchTwilioAccounts();
      reset();
      setCurrentTwilioAcc(undefined);
      onClose();
    } catch (error) {
      toast.error("Some error might have occured");
    } finally {
      setStatus("idle");
    }
  };
  const loading = status === "loading";
  const [twilioAccounts, setTwilioAccounts] = useState([]);
  const fetchTwilioAccounts = useCallback(async () => {
    try {
      setStatus("accountsLoading");
      const { data } = await fetchController(
        baseUrl + "/api/v1/channel/twilio/list"
      );
      setTwilioAccounts(data.items);
    } catch (error) {
      setTwilioAccounts([]);
    } finally {
      setStatus("idle");
    }
  }, []);
  useEffect(() => {
    fetchTwilioAccounts();
  }, []);
  const twilioOptions = twilioAccounts.map((account) => ({
    value: account,
    label: account.name || "Twilio Account",
  }));
  const [creatingNewAccount, setCreatingNewAccount] = useState(false);
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent height="auto" width="95%" maxWidth="600px">
        <form onSubmit={handleSubmit(onSubmitTwilioAccountDetails)}>
          <ModalHeader>Twilio Details </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              {creatingNewAccount ? (
                <Flex justifyContent={"flex-start"}>
                  <IoMdArrowRoundBack
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      setCreatingNewAccount(false);
                      reset({
                        name: "",
                        twilio_account_sid: "",
                        twilio_auth_token: "",
                      });
                      setCurrentTwilioAcc(undefined);
                    }}
                  />
                </Flex>
              ) : (
                <FormControl>
                  <FormLabel>Twilio Accounts Linked</FormLabel>
                  <Select
                    value={currentTwilioAcc}
                    options={twilioOptions}
                    onChange={({ value, label }) => {
                      reset(value);
                      setCurrentTwilioAcc({ label, value });
                    }}
                  />
                </FormControl>
              )}
              {currentTwilioAcc || creatingNewAccount ? (
                <>
                  {creatingNewAccount ? null : (
                    <Divider marginBlock={0.5} color={"black"} />
                  )}
                  <FormControl isInvalid={errors.name}>
                    <FormLabel>Name</FormLabel>
                    <Input
                      {...register("name", {
                        required: true,
                      })}
                      placeholder="Name"
                    />
                    <FormErrorMessage>
                      {errors.name && errors.name.type === "required"
                        ? "Field is required "
                        : null}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.twilio_account_sid}>
                    <FormLabel>Twilio Account SID</FormLabel>
                    <Input
                      {...register("twilio_account_sid", {
                        required: true,
                      })}
                      placeholder="Twilio Account SID"
                    />
                    <FormErrorMessage>
                      {errors.twilio_account_sid &&
                      errors.twilio_account_sid.type === "required"
                        ? "Field is required "
                        : null}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.twilio_auth_token}>
                    <FormLabel>Twilio Auth Token</FormLabel>
                    <PasswordInput
                      placeholder={"Twillio Auth Token"}
                      register={{
                        ...register("twilio_auth_token", {
                          required: true,
                        }),
                      }}
                    />
                    <FormErrorMessage>
                      {errors.twilio_auth_token &&
                      errors.twilio_auth_token.type === "required"
                        ? "Field is required "
                        : null}
                    </FormErrorMessage>
                  </FormControl>
                </>
              ) : null}
              <Flex justifyContent={"flex-start"}>
                <Link
                  color={"blue.500"}
                  href="https://www.twilio.com/en-us/blog/better-twilio-authentication-csharp-twilio-api-keys"
                  isExternal
                >
                  Get Keys <ExternalLinkIcon mx="2px" />
                </Link>
              </Flex>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex
              gap={2}
              width={"100%"}
              justifyContent={"center"}
              marginBlock={2}
              alignItems={"center"}
            >
              <Button isLoading={loading} type="submit" colorScheme="blue">
                Submit
              </Button>
              {creatingNewAccount ? null : (
                <Button
                  onClick={() => {
                    setCreatingNewAccount(true);
                    reset({
                      name: "",
                      twilio_account_sid: "",
                      twilio_auth_token: "",
                    });
                  }}
                >
                  Create New
                </Button>
              )}
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
