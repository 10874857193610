import {
  Box,
  Flex,
  Skeleton,
  Spinner,
  Stack,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import moment from "moment";
import { FaMicrophone } from "react-icons/fa";
import { FiMessageSquare } from "react-icons/fi";
import { LuContact } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import { PiRobotLight } from "react-icons/pi";
import { SlCallIn, SlCallOut } from "react-icons/sl";
import { TbDeviceDesktop } from "react-icons/tb";

export default function Conversations({
  loading,
  conversations,
  skeletonMap,
  selectedConversation,
  setSelectedConversation,
  selectedChatBg,
  chatBg,
  chatColor,
  onOpenDeleteConfirmationModal,
  setDeleteChatId,
  deleteChatId,
}) {
  if (loading.conversation) {
    return (
      <Stack spacing="1">
        {skeletonMap.map((item) => (
          <Skeleton
            key={item}
            startColor="#3182ce6e"
            endColor="##3182ce6e"
            height="36px"
          />
        ))}
      </Stack>
    );
  }

  if (!conversations?.length) {
    return (
      <Box
        px={3}
        py={2}
        borderRadius="5px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        No conversation
      </Box>
    );
  }

  return (
    <Stack spacing="1">
      {conversations.map((conversation) => {
        const {
          id,
          assistant_name,
          updated_at,
          first_message,
          intent_name,
          contact_first_name,
          contact_last_name,
          includes_voice,
          chat_count,
          device_type,
          attributes: {
            call_direction,
            from,
            to,
            from_formatted,
            to_formatted,
          },
        } = conversation;

        const isSelected = selectedConversation.id === id;

        return (
          <Flex key={id}>
            <Box
              bg={isSelected ? selectedChatBg : chatBg}
              color={isSelected ? "white" : chatColor}
              px={3}
              py={2}
              borderRadius="5px"
              flex={1}
              cursor="pointer"
              onClick={() => setSelectedConversation(conversation)}
            >
              <Box>
                <Flex justifyContent="space-between">
                  <Flex alignItems="center" gap={1}>
                    <PiRobotLight width={20} height={20} />
                    <Text fontSize="xs">{assistant_name}</Text>
                  </Flex>
                  <Text fontSize="xs">{moment.utc(updated_at).fromNow()}</Text>
                </Flex>

                <Flex justifyContent="space-between" mt="2px" mb="2px">
                  <Text fontSize="xs">{first_message}</Text>
                  {intent_name && <Tag>{intent_name}</Tag>}
                </Flex>
              </Box>

              <Flex justifyContent="space-between" mt={2}>
                <Flex alignItems="center" gap={1}>
                  {contact_first_name || contact_last_name ? (
                    <LuContact />
                  ) : null}
                  <Tooltip label={call_direction === "inbound" ? from : to}>
                    <Text fontSize="10px" fontWeight="bold" as="i">
                      {`${contact_first_name || ""} ${contact_last_name || ""}`}
                    </Text>
                  </Tooltip>
                </Flex>

                <Flex alignItems="center" gap={1}>
                  {deleteChatId === id && loading.delete ? (
                    <Spinner size="sm" />
                  ) : (
                    <Box as="span" title="Delete conversation">
                      <MdDelete
                        color="red"
                        cursor="pointer"
                        size="1rem"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteChatId(id);
                          onOpenDeleteConfirmationModal();
                        }}
                      />
                    </Box>
                  )}

                  {includes_voice && <FaMicrophone />}
                  {device_type === "phone" && call_direction === "inbound" && (
                    <SlCallIn
                      title={`From: ${from_formatted || "Phone number N/A"}`}
                    />
                  )}
                  {device_type === "phone" && call_direction === "outbound" && (
                    <SlCallOut
                      title={`To: ${to_formatted || "Phone number N/A"}`}
                    />
                  )}
                  {device_type === "desktop" && <TbDeviceDesktop />}

                  <Flex alignItems="center" gap={1}>
                    <FiMessageSquare />
                    <Text fontSize="xs">{chat_count}</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        );
      })}
    </Stack>
  );
}
