import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function useExternalAuth({ auth_provider }) {
  const baseUrl = getHostDomain();
  const [auths, setAuths] = useState([]);
  const [status, setStatus] = useState("idle");
  const fetchToolUsers = async () => {
    if (!auth_provider) {
      setAuths([]);
      return;
    }
    setStatus("loading");
    const response = await fetchController(
      baseUrl +
        `/api/v1/external_auth/provider_auth?auth_provider=${auth_provider}&size=100`
    );
    setAuths(response);
    setStatus("idle");
  };
  useEffect(() => {
    fetchToolUsers();
  }, [auth_provider]);
  return { auths, refreshAuths: fetchToolUsers, status };
}
