import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
export default function AssignToolModal(props) {
  const baseUrl = getHostDomain();
  const { isOpen, onClose, table, selectedAssistant } = props;
  const [status, setStatus] = useState("idle");
  const loading = status === "loading";
  const [toolUsers, setToolUsers] = useState([]);
  const fetchLinkedToolUsersToAssistant = useCallback(async () => {
    setStatus("loading");
    const responseLinkedAssistantTools = await fetchController(
      baseUrl +
        `/api/v1/assistant/${selectedAssistant.id}/debe98da-a3a7-4a89-af93-e70cbd4e3902/LinkAssistantTool`
    );

    const linkedAssistantToolsMap =
      responseLinkedAssistantTools.data.items.reduce((map, curr) => {
        map[curr.tool_user_id] = curr;
        return map;
      }, {});
    const responseLinkedToolUsers = await fetchController(
      baseUrl +
        `/api/v1/tool/debe98da-a3a7-4a89-af93-e70cbd4e3902/LinkToolUser`
    );
    setToolUsers(
      responseLinkedToolUsers.data.items.map((item) => ({
        ...item,
        tool_attached: item.id in linkedAssistantToolsMap,
        linkedToolUser: linkedAssistantToolsMap[item.id],
      }))
    );
    setStatus("idle");
  }, [selectedAssistant]);
  useEffect(() => {
    if (selectedAssistant && table === "assistant" && isOpen) {
      fetchLinkedToolUsersToAssistant();
    }
  }, [selectedAssistant]);
  const onChangeAssign = async (assigned, item) => {
    try {
      await fetchController(
        baseUrl +
          `/api/v1/assistant/${
            selectedAssistant.id
          }/debe98da-a3a7-4a89-af93-e70cbd4e3902/LinkAssistantTool${
            assigned ? "" : `/${item.linkedToolUser.id}`
          }`,
        assigned ? "POST" : "DELETE",
        assigned && {
          org_id: null,
          tool_id: "debe98da-a3a7-4a89-af93-e70cbd4e3902",
          assistant_id: selectedAssistant.id,
          tool_user_id: item.id,
        }
      );

      fetchLinkedToolUsersToAssistant();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal isOpen={isOpen} scrollBehavior="horizontal" onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Assign Tool</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            {loading ? (
              <Flex justifyContent={"center"} alignItems={"center"}>
                <Spinner />
              </Flex>
            ) : (
              <>
                <TableContainer height="50vh" overflowY="Scroll">
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th>Assign</Th>
                        <Th>Name</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {toolUsers.map((item) => {
                        return (
                          <Tr key={item.id}>
                            <Td>
                              <Checkbox
                                isChecked={item.tool_attached}
                                onChange={(e) =>
                                  onChangeAssign(e.currentTarget.checked, item)
                                }
                              />
                            </Td>
                            <Td>{item.name}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </>
            )}
            <Link to={`/tools?calendarSetup=${true}`}>Create tool</Link>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
