import {
  Modal,
  Spinner,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import WebhookSelect from "../../Forms/WebhookSelect";
import { useEffect, useState } from "react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { Link } from "react-router-dom";
import { getHostDomain } from "../../../utils/utils";
export default function AssignWebhookModal(props) {
  const baseUrl = getHostDomain();
  const { isOpen, onClose, selectedAssistant } = props;
  const [webhook, setWebhook] = useState("");
  const [status, setStatus] = useState("idle");
  useEffect(() => {
    (async () => {
      if (!selectedAssistant) return;
      setStatus("loading");
      const response = await fetchController(
        baseUrl +
          `/api/v1/assistant/${selectedAssistant.id}`
      );
      setWebhook(response.data?.webhook_id || "");
      setStatus("idle");
    })();
  }, [selectedAssistant]);
  const loading = status === "loading";
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setStatus("assigning");
          await fetchController(
            baseUrl +
              `/api/v1/assistant/${selectedAssistant.id}`,
            "PUT",
            { webhook_id: webhook }
          );
          toast.success(webhook ? "Webhook assigned" :"Webhook un-assigned");
          onClose();
          setStatus("idle");
        }}
      >
        <ModalContent>
          <ModalHeader>Webhook</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              {loading ? (
                <Flex justifyContent={"center"} alignItems={"center"}>
                  <Spinner />
                </Flex>
              ) : (
                <FormControl>
                  <FormLabel>Select webhook</FormLabel>
                  <WebhookSelect
                    webhook_id={webhook}
                    onChangeWebhook={(value) => {
                      value ? setWebhook(value.value) : setWebhook(null)
                    }}
                  />
                </FormControl>
              )}
              <Link to={"/settings/webhooks"}>Create webhook</Link>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              isLoading={status === "assigning"}
              isDisabled={status === "loading"}
              type="submit"
              colorScheme="blue"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
