import React, { useContext, useState } from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Input,
  Button,
} from "@chakra-ui/react";
import { AgencyContext } from '../Settings/AgencyProvider/AgencyProvider';

const ExternalModal = ({ isOpen, onClose, onAddIntent }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleAddIntent = () => {
    // Perform any validation if needed
    // Call onAddIntent with the name and email
    onAddIntent({ name, email });

    // Clear the form after adding intent
    setName('');
    setEmail('');
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg: bg } = agency;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Intent</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb="4">
            <Input
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box mb="4">
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Button colorScheme={buttonColorScheme} onClick={handleAddIntent}>
            Add Intent
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ExternalModal;
