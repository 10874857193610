import React from 'react'
import Layout from '../Layout/Layout';
import ContactTable from './ContactTable';
import { Box } from '@chakra-ui/react';

const Contact = () => {
  return (
    <Layout>
      <Box
        border="1px"
        borderColor={"black"}
        borderRadius={"md"}
        h="90svh"
        p={5}
        overflowY={"auto"}
        
      >
        <ContactTable />
      </Box>
    </Layout>
  );
}

export default Contact