import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Text,
  // Select
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { fetchController } from "../../../utils/FetchController/fetchController";
import AlertPayment from "./AlertPayment";
import PurchasedAddons from "./PurchasedAddons";
import CustomSelect from "../../../utils/CustomSelect/CustomSelect";
import { getHostDomain } from "../../../utils/utils";
export default function AddonsList({ userDetails, buttonColorScheme }) {
  const baseUrl = getHostDomain();
  const [status, setStatus] = useState("idle");
  const [addonsList, setAddonsList] = useState([]);
  const [currentUserAddons, setCurrentUserAddons] = useState([]);
  const { watch, setValue, register, handleSubmit, reset } = useForm({
    defaultValues: {
      lookup_key: "",
      qty: 1,
    },
  });
  useEffect(() => {
    (async () => {
      try {
        setStatus("loadingAddons");
        const activeAddonsResponse = await fetchController(
          baseUrl + "/api/v1/billing/get_active_addons"
        );
        setAddonsList(activeAddonsResponse.data);
        if (userDetails.stripe_has_payment_method) fetchCurrentUserAddons();
        setStatus("idle");
      } catch (error) {
        toast.error(error);
      } finally {
        setStatus("idle");
      }
    })();
  }, []);
  const fetchCurrentUserAddons = async () => {
    try {
      setStatus("loading");
      const userAddons = await fetchController(
        baseUrl + "/api/v1/billing/get_my_addons"
      );
      setCurrentUserAddons(userAddons);
      setStatus("idle");
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const loading = status === "loadingAddons";
  const purchasingAddon = status === "purchasingAddon";
  const hasAddedPaymentMethod = userDetails
    ? userDetails.stripe_has_payment_method
    : null;
  const onBuyAddons = async (data) => {
    try {
      setStatus("purchasingAddon");
      await fetchController(
        baseUrl + "/api/v1/billing/buy_addon",
        "POST",
        data
      );
      toast.success("Purchased");
      reset({
        qty: 1,
      });
      fetchCurrentUserAddons();
    } catch (_) {
      toast.error("Some error occured");
    } finally {
      setStatus("idle");
    }
  };

  const addonOptionMapper = (addon) => ({
    value: addon.plan_lookup_key,
    label: addon.name,
  });
  const seletedAddon = addonsList
    .map(addonOptionMapper)
    .find((addon) => addon.value === watch("lookup_key"));
  return (
    <Box p={4}>
      {loading ? null : (
        <>
          <PurchasedAddons
            addonsList={currentUserAddons}
            fetchCurrentUserAddons={fetchCurrentUserAddons}
          />
          {currentUserAddons.length ? <Divider /> : null}
        </>
      )}

      <form onSubmit={handleSubmit(onBuyAddons)}>
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBlock={2}
        >
          <Text fontSize={"md"} fontWeight={"bold"}>
            Addons
          </Text>
          <Button
            type="submit"
            isLoading={purchasingAddon}
            isDisabled={!watch("lookup_key") || !watch("qty")}
            size={"sm"}
            colorScheme={buttonColorScheme}
          >
            Buy Addon
          </Button>
        </Flex>
        {loading ? (
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Spinner />
          </Flex>
        ) : (
          <Box>
            <Grid gap={2}>
              <FormControl>
                <FormLabel>Select Addon</FormLabel>
                {/* <Select
                  required
                  isOptionDisabled={(_) => !hasAddedPaymentMethod}
                  value={seletedAddon}
                  {...register("lookup_key", {
                    required: true,
                  })}
                  onChange={({ value }) => {
                    setValue("lookup_key", value);
                  }}
                  options={addonsList.map(addonOptionMapper)}
                /> */}
                <CustomSelect
                  required
                  value={seletedAddon?.value}
                  {...register("lookup_key", {
                    required: true,
                  })}
                  onChange={({ value }) => {
                    setValue("lookup_key", value);
                  }}
                  options={addonsList.map(addonOptionMapper)}
                  isOptionDisabled={!hasAddedPaymentMethod}
                />
              </FormControl>
              {watch("lookup_key") ? (
                <FormControl>
                  <FormLabel>Addon Qty</FormLabel>
                  <NumberInput min={1} allowMouseWheel>
                    <NumberInputField
                      {...register("qty", {
                        valueAsNumber: true,
                        required: true,
                        min: 1,
                      })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              ) : null}
            </Grid>
          </Box>
        )}
        <Box marginBlock={2}>
          {hasAddedPaymentMethod ? null : <AlertPayment />}
        </Box>
      </form>
    </Box>
  );
}
