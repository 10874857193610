import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";

export default function useToolModal() {
  const sessionToolId = sessionStorage.getItem("toolId");

  const [toolId, setToolId] = useState(sessionToolId);
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: sessionToolId,
  });
  const openToolModal = (tool) => {
    setToolId(tool.id);
    sessionStorage.setItem("toolId", tool.id);
    onOpen();
  };
  const closeToolModal = () => {
    setToolId(null);
    sessionStorage.removeItem("toolId");
    onClose();
  };
  return { isOpen, openToolModal, closeToolModal, toolId };
}
