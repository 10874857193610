import { Box } from "@chakra-ui/react";
import "../../Styles/TilesList.css";
import DocumentTile from "./DocumentTile";

export default function TilesList({ bgColor, tiles, isMyMessage }) {
  return (
    <section className="tiles__list">
      <Box className="tiles" justifyContent={isMyMessage ? "flex-end" : "flex-start"} gap={2}>
        {tiles.map((tile, index) => (
          <DocumentTile key={tile.label + index} tile={tile} bgColor={bgColor} />
        ))}
      </Box>
    </section>
  );
}