import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";

import Select from "react-select";
import DaySlots from "./DaySlots";
import useSlotAvailabilityForm from "./useSlotAvailabilityForm";
export default function SelectAvailability({ isOpen, onClose, toolUserForm }) {
  const { watch } = toolUserForm;
  const { credentials = {} } = watch();
  const { configuration = {} } = credentials;
  const { onRemoveAvailability, closeModal, onSubmitAvailability, days, form } =
    useSlotAvailabilityForm({
      onClose,
      toolUserForm,
    });
  const dayOptions = days.map((day) => ({
    value: day,
    label: day,
  }));
  return (
    <Modal size={"3xl"} isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Availability</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box marginBlock={2}>
            <form onSubmit={form.handleSubmit(onSubmitAvailability)}>
              <Stack spacing={2}>
                <FormControl isRequired>
                  <FormLabel>Select day</FormLabel>
                  <Select
                    required
                    onChange={({ value }) => {
                      form.setValue("day", value);
                    }}
                    options={dayOptions}
                    value={dayOptions.find(
                      (dayOption) => dayOption.value === form.watch("day")
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Duration</FormLabel>
                  <Flex justifyContent={"center"} alignItems={"center"} gap={1}>
                    <Input
                      required
                      flex={1}
                      type="time"
                      {...form.register("start")}
                    />
                    <Input
                      required
                      flex={1}
                      type="time"
                      {...form.register("end")}
                    />
                  </Flex>
                </FormControl>
                <Button type="submit">Submit</Button>
              </Stack>
            </form>
          </Box>
          <Divider />
          <Grid gap={2}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              gap={2}
            >
              {days.map((day, index) => {
                const slots = (configuration.availability || {})[day] || [];
                return (
                  <DaySlots
                    onClickDay={() => {
                      form.setValue("day", day);
                    }}
                    day={day}
                    key={index}
                    onRemoveAvailability={onRemoveAvailability}
                    slots={slots}
                  />
                );
              })}
            </Flex>
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
