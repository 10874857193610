import { Box, Grid, Heading, Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import Loader from "../../utils/Loader/Loader";
import Layout from "../Layout/Layout";
import CallWidgetCustomizeForm from "./CallWidgetCustomizeForm";
import CallVisualRepresentation from "./CallWidgetVisualRepresentation";
import useWidgetForm from "./useWidgetForm";
export default function CallWidgetCustomizePage() {
  const { widgetId } = useParams();
  const { widget, loading, widgetForm } = useWidgetForm(widgetId);
  const fields = widget?.fields || [];
  const restWidgetFields = widgetForm.watch();
  return (
    <Layout>
      <Box
        border={"1px solid black"}
        borderRadius={"md"}
        h={"90svh"}
        p={4}
        overflowY={"scroll"}
      >
        {loading ? (
          <Loader />
        ) : widget ? (
          <Stack>
            <Heading color="#3182ce" fontSize="xl">
              Customize Widget
            </Heading>
            <Grid gap={4} gridTemplateColumns={"1fr 1fr"}>
              <Box>
                <Box bg={"white"} borderRadius={"md"} p={4}>
                  <CallWidgetCustomizeForm form={widgetForm} widget={widget} />
                </Box>
              </Box>
              <Box>
                <Box
                  style={{ ...(restWidgetFields.theme?.app || {}) }}
                  border={"1px solid lightgray"}
             
                  borderRadius={"md"}
                >
                  <CallVisualRepresentation
                    widgetFields={{ fields, ...restWidgetFields }}
                  />
                </Box>
              </Box>
            </Grid>
          </Stack>
        ) : (
          <Box>Widget not found</Box>
        )}
      </Box>
    </Layout>
  );
}
