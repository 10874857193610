export const userActivatedPlan = "userActivatedPlan";

export const initialState = {
  advanced_intents: false,
  auto_intents: false,
  bots_count: 0,
  default_plan: false,
  description: "Insighto.ai - Free Plan",
  id: "4f832d52-14e2-4fc8-beef-184109e8b99c",
  name: "Insighto.ai - Free",
  plan_lookup_key: "insightoai_free",
  premium_support: false,
  queries_count: 100,
  remove_branding: false,
  voice_seconds: 300,
  words_count: 100000,
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActivatedPlan:
      return { ...action.payload };
    default:
      return state;
  }
};
