import { Button, IconButton } from "@chakra-ui/react";
import React from "react";
import { MdSchedule } from "react-icons/md";

export default function ScheduleDemo({ navSize }) {
  const onCLickDemo = () =>
    window.open("https://calendly.com/solutions-insighto/30min", "_blank");
  const icons = {
    small: {
      Button: IconButton,
      children: {
        icon: <MdSchedule />,
        justifyContent: "center",
      },
    },
    large: {
      Button: Button,
      children: {
        rightIcon: <MdSchedule />,
        justifyContent: "space-between",
        children: "Schedule Demo",
      },
    },
  };
  const icon = icons[navSize] || icons.small;
  return <icon.Button {...icon.children} onClick={onCLickDemo} mx={2} />;
}
