import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import { AiOutlineFileSearch } from "react-icons/ai";
import WebhookLogListItem from "./WebhookLogListItem";
export default function WebhookLogsList({
  logsResponseItems,
  selectWebhook,
  selectedWebhookId,
}) {
  const isLogSelected = (log) =>
    selectedWebhookId && selectedWebhookId === log.id;
  return logsResponseItems.length ? (
    <Stack maxH={"65svh"} overflowY={"auto"} marginRight={2} spacing={3}>
      {logsResponseItems.map((log) => (
        <Box
          borderWidth={1}
          borderRightColor={isLogSelected(log) && "blue"}
          p={3}
          boxShadow={"md"}
          borderRightWidth={2}
          bg={isLogSelected(log) ? "gray.200" : null}
          cursor={"pointer"}
          _hover={{
            bg: isLogSelected(log) ? undefined : "gray.100",
          }}
          onClick={() => {
            if (selectedWebhookId === log.id) return;
            selectWebhook(log.id);
          }}
          borderRadius={"md"}
          key={log.id}
        >
          <WebhookLogListItem log={log} />
        </Box>
      ))}
    </Stack>
  ) : (
    <Flex
      gap={5}
      justifyContent={"center"}
      alignItems={"center"}
      flexDir={"column"}
    >
      <Box p={5} borderRadius={"full"} bg={"gray.200"}>
        <AiOutlineFileSearch color="gray" size={80} />
      </Box>
      <Heading fontSize={"xl"}>No logs found !</Heading>
    </Flex>
  );
}
