import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import Loader from "../../utils/Loader/Loader";
import AuthContext from "./AuthContext";
import { useMediaQuery } from "@chakra-ui/react";

const ProtectedRoute = ({ component: Component }) => {
  const { isAuthenticated, isLoading, logout } = useContext(AuthContext);
  const [isSmallerThan800] = useMediaQuery('(max-width: 800px)')

  if(isSmallerThan800) {
    logout()
  } 

  return isLoading ? (
    <Loader />
  ) : isAuthenticated ? (
    <Component />
  ) : (
    <Navigate to={"/login"} />
  );
};

export default ProtectedRoute;
