import { useContext } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

export const authTypeEnum = {
  OAUTH: "oauth",
  SIMPLE_TOKEN: "simple_token",
  OAUTH_KEYS: "oauth_keys",
};
export default function useNewAuthCreation({ tool, toggleKeyFields }) {
  const agencyProvider = useContext(AgencyContext);
  const { redirect_uri, oauthRoute } = getToolRedirections();

  const onOAuthAuthentication = () => window.open(oauthRoute, "_self");

  const onKeysAuthentication = () => toggleKeyFields();

  const getNewAuthFunction = () => {
    const authMap = {
      [authTypeEnum.OAUTH]: onOAuthAuthentication,
      [authTypeEnum.SIMPLE_TOKEN]: onKeysAuthentication,
      [authTypeEnum.OAUTH_KEYS]: onKeysAuthentication,
    };
    return authMap[tool?.authentication?.method] || onKeysAuthentication;
  };
  function getToolRedirections() {
    let redirect_uri = process.env.REACT_APP_REDIRECT_URI;
    let oauthRoute = `${
      tool?.authentication?.oauth_url
    }&redirect_uri=${encodeURIComponent(process.env.REACT_APP_REDIRECT_URI)}`;

    if (agencyProvider?.myDetails?.agency_id) {
      let return_url = `https://${agencyProvider?.myDetails?.domain?.appDomain}/tools`;
      redirect_uri = `https://ragify-be.azurewebsites.net/api/v1/redirect`;
      oauthRoute = `https://ragify-be.azurewebsites.net/api/v1/redirect?redirect_type=outward&auth_url=${encodeURIComponent(
        `${tool?.authentication?.oauth_url}&redirect_uri=${redirect_uri}`
      )}&return_url=${encodeURIComponent(return_url)}`;
    }
    return { redirect_uri, oauthRoute };
  }
  const makeValuesForQueryAccordingToAgency = (value) =>
    agencyProvider?.myDetails?.agency_id ? decodeURIComponent(value) : value;
  return {
    getNewAuthFunction,
    redirect_uri,
    makeValuesForQueryAccordingToAgency,
  };
}
