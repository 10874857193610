import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import Layout from "../Layout/Layout";
import { RiPagesLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
export default function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <Layout>
      <Box
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
        h={"90svh"}
      >
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          gap={4}
          flexDirection={"column"}
        >
          <RiPagesLine size={100} />
          <Heading>Sorry, page not found</Heading>
          <Button
            onClick={() => navigate(`/`)}
            variant={"solid"}
            colorScheme="blue"
          >
            Back to Dashboard
          </Button>
        </Flex>
      </Box>
    </Layout>
  );
}
