import { Center, Th } from "@chakra-ui/react";

export default function RenderOperationTableHeader({ tableBg }) {
  return (
    <>
        <Th
          p={2}
          width={180}
          borderBottom="1px"
          borderColor="gray.300"
          bg={tableBg}
          style={{ textWrap: "nowrap" }}
        >
          <Center>Operations</Center>
        </Th>
    </>
  );
}
