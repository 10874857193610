import {
  Box,
  Card,
  CardBody,
  Divider,
  Flex,
  Grid,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaLightbulb } from "react-icons/fa";

function AgencyCheckboxDescription(props) {
  return (
    <Tr>
      <Td w={10}>
        <FaLightbulb color={props.status} />
      </Td>
      <Td whiteSpace={"wrap"}>{props.description}</Td>
    </Tr>
  );
}

function CategoryStatusTable(props) {
  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Status</Th>
            <Th>Description</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.categoryStatusList.map(({ status, description }, index) => (
            <AgencyCheckboxDescription
              key={index}
              status={status}
              description={description}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function AgencyCheckTableItem(props) {
  const headingBg = useColorModeValue("gray.100", "gray.700");
  const [category, categoryStatusList] = props.agencyCheckEntry;
  return (
    <Box border={"1px solid lightgray"} borderRadius={"md"}>
      <Box bg={headingBg} paddingBlock={2}>
        <Heading
          textTransform={"capitalize"}
          fontWeight={"bold"}
          textAlign={"center"}
          fontSize={"medium"}
        >
          {category}
        </Heading>
      </Box>
      <CategoryStatusTable categoryStatusList={categoryStatusList} />
    </Box>
  );
}

export default function KeyStatusCheck({ checks }) {
  const agencyChecksMapEntries = Object.entries(
    checks.reduce((acc, check) => {
      const currentCategoryStatusList = acc[check.category] || [];
      acc[check.category] = [...currentCategoryStatusList, check];
      return acc;
    }, {})
  );

  return (
    <>
      <Card>
        <CardBody>
          <Box>
            <Grid gap={2}>
              {agencyChecksMapEntries.map((agencyCheckEntry, index) => (
                <AgencyCheckTableItem
                  key={index}
                  agencyCheckEntry={agencyCheckEntry}
                />
              ))}
            </Grid>
            <Divider />
            <Box marginBlock={2}>
              <Legends />
            </Box>
          </Box>
        </CardBody>
      </Card>
    </>
  );
}

function Legends() {
  const legendStatusColorDescription = {
    green: "Setup is complete",
    yellow: "It will work but not ideal",
    red: "Needs your attention before making your Agency functional",
  };
  return (
    <Grid gap={2}>
      {Object.entries(legendStatusColorDescription).map(
        ([status, description], index) => (
          <Flex
            gap={3}
            justifyContent={"flex-start"}
            alignItems={"center"}
            key={index}
          >
            <FaLightbulb size={20} color={status} />
            <Text>{description}</Text>
          </Flex>
        )
      )}
    </Grid>
  );
}
