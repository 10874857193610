import { useEffect, useRef, useState } from "react";

export default function useLiveAgentSocket({
  assistant,
  onSetActiveUsersList,
  onAddNewActiveUser,
  onAddMessagesToActiveUser,
  onAddReceivedMessage,
  onUserDeactivate,
}) {
  const wsRef = useRef(null);
  const [status, setStatus] = useState("loading");
  useEffect(() => {
    if (!assistant) return;
    const responseTypeOperations = {
      active_users_list: onSetActiveUsersList,
      new_user: onAddNewActiveUser,
      exit_user: onUserDeactivate,
      agent_message: onAddReceivedMessage,
      info: onAddMessagesToActiveUser,
    };
    const token = localStorage.getItem("token");
    const agentConnectUrl = `wss://ragify-be.azurewebsites.net/chat/agent/${assistant.id}?token=${token}`;
    wsRef.current = new WebSocket(agentConnectUrl);
    const onConnect = function () {
      setStatus("connected");
      wsRef.current.send(JSON.stringify({ msg_type: "start_session" }));
    };
    wsRef.current.addEventListener("open", onConnect);
    const onMessage = function ({ data }) {
      const response = JSON.parse(data);
      const getOperation = responseTypeOperations[response.msg_type];
      if (getOperation) getOperation(response);
    };
    wsRef.current.addEventListener("message", onMessage);
    const onCloseConnection = function () {
      setStatus("closed");
    };
    wsRef.current.addEventListener("close", onCloseConnection);
    const onErrorConnection = function () {
      setStatus("error");
    };
    wsRef.current.addEventListener("error", onErrorConnection);
    return () => {
      if (wsRef.current) {
        wsRef.current.removeEventListener("open", onConnect);
        wsRef.current.removeEventListener("message", onMessage);
        wsRef.current.removeEventListener("close", onCloseConnection);
        wsRef.current.removeEventListener("error", onErrorConnection);
        wsRef.current.close();
      }
    };
  }, [assistant]);
  function acceptNewUser({ user }) {
    wsRef.current.send(
      JSON.stringify({
        msg_type: "accept_user",
        user_id: user,
        message: "Agent joined",
      })
    );
  }
  function sendMessage({ msg_type = "user_message", user_id, message }) {
    wsRef.current.send(JSON.stringify({ msg_type, user_id, message }));
  }
  return { status, acceptNewUser, sendMessage };
}
