import {
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";

export default function CallToolModal({
  onClose,
  toolUserForm,
}) {
  const baseUrl = getHostDomain();
  const { register, handleSubmit } = toolUserForm;
  const onSubmit = async (data) => {
    try {
      await fetchController(
        baseUrl +
          `/api/v1/tool/${data.tool_user_id}/LinkToolUser`,
        "PUT",
        {
          credentials: data.credentials,
        }
      );
      onClose();
      toast.success("Tool setup done");
    } catch (error) {
      toast.error("Some error occurred");
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <FormControl>
          <FormLabel>Call Forwarding Number</FormLabel>
          <Input
            {...register("credentials.configuration.call_forward_number")}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="blue" type="submit">
          Finish
        </Button>
      </ModalFooter>
    </form>
  );
}
