import React, { useContext, useEffect, useState } from "react";
import AuthContext from "./AuthContext";
import { fetchController } from "../../utils/FetchController/fetchController";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const AuthProvider = ({ children }) => {
  const baseUrl = getHostDomain();
  const [authUser, setAuthUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const agency = useContext(AgencyContext);
  const getUserData = async () => {
    try {
      setIsLoading(true);
      const userResponse = await fetchController(
        `${baseUrl}/api/v1/user`,
        "GET"
      );

      setAuthUser(userResponse?.data);
      return userResponse;
    } catch (e) {
      setAuthUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (data) => {
    try {
      setIsLoading(true);
      const baseURL = agency?.myDetails?.domain?.apiDomain
        ? `https://${agency?.myDetails.domain.apiDomain}`
        : process.env.REACT_APP_SERVER_URL;
      const response = await fetchController(
        `${baseURL}/api/v1/login`,
        "POST",
        data,
        {
          "Content-Type": "application/json",
        }
      );

      const { access_token, refresh_token } = response.data;
      localStorage.setItem("token", access_token);
      localStorage.setItem("refreshToken", refresh_token);
    } catch (e) {
      setError(true);
      toast.error(e.response.data.detail);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      setIsLoading(true);

      await fetchController(`${baseUrl}/api/v1/login/logout`, "POST", {});
    } catch (e) {
      toast.error("Some error occured");
      setError(true);
    } finally {
      setIsLoading(false);

      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      setAuthUser(null);
      navigate("/login", {
        replace: true,
      });
    }
  };
  const exchangeCodeWithToken = async ({
    provider = "google",
    code,
    redirect_uri,
  }) => {
    const response = await fetchController(
      baseUrl + "/api/v1/login/oauth-token",
      "POST",
      {
        code,
        provider,
        redirect_uri,
      }
    );
    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("refreshToken", response.data.refresh_token);
  };
  const getAccessToken = () => {
    return localStorage.getItem("token");
  };

  const getRefreshToken = () => {
    return localStorage.getItem("refreshToken");
  };

  const isAuthenticated = !!authUser;

  return (
    <AuthContext.Provider
      value={{
        authUser,
        isLoading,
        error,
        login,
        logout,
        isAuthenticated,
        getAccessToken,
        getRefreshToken,
        setAuthUser,
        setIsLoading,
        setError,
        getUserData,
        exchangeCodeWithToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
