import { Box, Checkbox } from "@chakra-ui/react";
import TextField from "./TextField";
import SelectField from "./SelectField";
import DateField from "./DateField";
import TextAreaField from "./TextAreaField";
import { useRef } from "react";
import { useDrop, useDrag } from "react-dnd";
import CheckboxField from "./CheckboxField";
import TelephoneField from "./TelephoneField";
export default function FormFieldGenerator({
  field,
  selectedField,
  onSelectFieldFromListOfFields,
  index,
  moveField,
}) {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "field",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveField(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "field",
    item: () => {
      return { name: field.name, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Box
      ref={ref}
      data-handler-id={handlerId}
      borderStyle={field.name === selectedField?.name ? "dotted" : undefined}
      borderWidth={field.name === selectedField?.name ? 3 : 0}
      borderColor={"gray.200"}
      opacity={opacity}
      padding={2}
      cursor={"pointer"}
      key={field.name}
      userSelect={"none"}
      borderRadius={4}
      onClick={() => onSelectFieldFromListOfFields(field)}
    >
      {field.type === "text" ? (
        <TextField field={field} />
      ) : field.type === "select" ? (
        <SelectField field={field} />
      ) : field.type === "checkbox" ? (
        <CheckboxField field={field} />
      ) : field.type === "gdpr" ? (
        <CheckboxField field={field} />
      ) : field.type === "date" ? (
        <DateField field={field} />
      ) : field.type === "textarea" ? (
        <TextAreaField field={field} />
      ) : field.type === "tel" ? (
        <TelephoneField field={field} />
      ) : null}
    </Box>
  );
}
