import { Box, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
export default function FollowUpFieldSelect({
  fields,
  onSelectField,
  closeFieldSelectOptions,
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    function scrollToField(index) {
      if (!stackScroll.current || !stackScroll.current.childNodes[index])
        return;
      stackScroll.current.childNodes[index].scrollIntoView({
        behavior: "smooth",
      });
    }
    const arrowDown = () =>
      setSelectedIndex((prev) => {
        scrollToField(prev + 1);
        return prev + 1;
      });
    const arrowUp = () =>
      setSelectedIndex((prev) => {
        scrollToField(prev - 1);
        return prev - 1;
      });

    const onKeyPress = (e) => {
      switch (e.key) {
        case "ArrowDown":
          if (selectedIndex < fields.length - 1) arrowDown();
          break;
        case "ArrowUp":
          if (selectedIndex > 0) arrowUp();
          break;
        case "Escape":
          closeFieldSelectOptions();
          break;
        case "Enter":
          if (fields[selectedIndex]) onSelectField(fields[selectedIndex]?.name);
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", onKeyPress);
    return () => {
      window.removeEventListener("keydown", onKeyPress);
    };
  }, [selectedIndex]);
  const stackScroll = useRef(null);
  return (
    <Box
      zIndex={1}
      boxShadow={"md"}
      border={"1px"}
      minW={"md"}
      borderColor={"gray.200"}
      bottom={100}
      borderTopRadius={"md"}
      position={"absolute"}
      backgroundColor={"white"}
    >
      <Flex
        padding={2}
        marginBlock={2}
        justifyContent={"flex-start"}
        alignItems={"center"}
        gap={3}
      >
        <Icon
          as={MdClose}
          cursor={"pointer"}
          onClick={closeFieldSelectOptions}
        />
        <Text fontSize={"md"} color="blue">
          Select a field
        </Text>
      </Flex>
      <Stack
        ref={stackScroll}
        borderTop="1px"
        borderColor="gray.200"
        height={200}
        overflowY={"auto"}
        spacing={1}
      >
        {fields.map((field, index) => (
          <Box
            padding={1}
            bg={selectedIndex === index ? "gray.100" : undefined}
            _hover={{
              backgroundColor: "#f1f1f1",
            }}
            cursor={"pointer"}
            onClick={() => onSelectField(field.name)}
            key={field.name}
          >
            <Text fontSize={"md"}>{`${field.name} - ${field.label}`}</Text>
          </Box>
        ))}
        {!fields.length ? <Text size={"md"}>No field found</Text> : null}
      </Stack>
    </Box>
  );
}
