import { Box, Heading, Stack, theme, useColorModeValue } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import ProgressBar from "./ProgressBar";
import { formatDateWithAmPm } from "../../../utils/DateFormat/dateFormatWithTimeAmPm";
import Loader from "../../../utils/Loader/Loader";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../../utils/utils";

const Usage = ({ tab, activeTab }) => {
  const baseUrl = getHostDomain();
  const [loading, setLoading] = useState(true);
  const endpoint = "/api/v1/analytics/user_credits";
  const urlHit = baseUrl + endpoint;
  const [usageData, setUsageData] = useState();
  const [userDetails, setUserDetails] = useState();
  const fetchAnalyticsData = async () => {
    setLoading(true);
    try {
      const getData = await fetchController(urlHit, "GET");
      setUsageData(getData);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };
  const getUserDetails = async () => {
    try {
      const userDetailsRes = await fetchController(
        baseUrl + `/api/v1/user`,
        "GET"
      );

      setUserDetails(userDetailsRes.data);
    } catch (error) {
      console.error("Error fetching user details", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAnalyticsData();
    getUserDetails();
  }, []);
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg } = agency;
  return (
    <Box border="1px" p="5" borderRadius="md" h="81vh" overflowY="auto">
      <Heading fontSize="xl" color={textColor}>
        Your Usage
      </Heading>
      {loading ? (
        <Loader />
      ) : (
        <Box
          mt={3}
          display="flex"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
          background={cardBg}
          padding={10}
          width="100%"
          boxShadow="md"
          borderRadius="8px"
        >
          <Stack width="100%" spacing={4} direction="column">
            {usageData && (
              <>
                <ProgressBar
                  allocated={usageData.allocated}
                  utilized={usageData.utilized}
                />
                <Box mt={4} fontWeight="500" display="flex">
                  Credits will be refreshed on{" "}
                  {formatDateWithAmPm(userDetails?.next_credits_refresh)}
                </Box>
              </>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default Usage;
