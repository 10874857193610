import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import {
  GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import AuthContext from "../Auth/AuthContext";
import useRecaptcha from "../Auth/useRecaptcha";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const AddAgentForm = ({ isOpen, onClose, activeTab, loadAgent}) => {
  const getRecaptchaToken = useRecaptcha();
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    isSubmitting,
    watch,
    reset,
  } = useForm();
  const [show, setShow] = useState(false);
  const { authUser } = useContext(AuthContext);
  const role_id = authUser?.role_id;
  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const endpoint = "/api/v1/user/create_live_agent";
      const url = baseUrl + endpoint;

      const body = {
        ...data,
        role_id,
      };
      const forgotPasswordPayload = {
        email: data.email,
        intiated_by_admin: true,
      };
      await fetchController(url, "POST", body);
      const reCaptchaToken = await getRecaptchaToken("agentCreation");
      forgotPasswordPayload.captcha_response = reCaptchaToken;
      await fetchController(
        `${baseUrl}/api/v1/login/forgot_password`,
        "POST",
        forgotPasswordPayload
      );
      toast.success("Agent created successfully.");
    } catch (error) {
      if (error?.response?.status == 409) {
        toast.error("There is already a user with same email");
      } else {
        toast.error(error?.message);
      }
    } finally {
      onClose();
      reset();
      loadAgent();
      setIsLoading(false);
    }
  };
  const generateRandomPassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+";
    const passwordLength = 8;
    let randomPassword = "";
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomPassword += characters[randomIndex];
    }
    setPassword(randomPassword);
    setValue("password", randomPassword);
  };
  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
    >
      <ModalOverlay />
      <ModalContent height="auto" width="95%" maxWidth="650px">
        <ModalHeader color={textColor}>Agent</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction="column"
              spacing={4}
              mb="4"
              width={"100%"}
              justifyContent={"space-between"}
            >
              <Box flex="1">
                <Input
                  placeholder="First name"
                  {...register("first_name", { required: true })}
                />
                {errors.first_name && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    First name is required
                  </p>
                )}
              </Box>
              <Box flex="1">
                <Input
                  placeholder="Last name"
                  {...register("last_name", { required: true })}
                />
                {errors.last_name && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    Last name is required
                  </p>
                )}
              </Box>
              <Box flex="1">
                <Input
                  type="email"
                  placeholder="Email"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    Email is required
                  </p>
                )}
              </Box>
              <Box flex="1">
                <InputGroup size="md">
                  <Input
                    type={show ? "text" : "password"}
                    placeholder="Click to generate password"
                    onClick={generateRandomPassword}
                    {...register("password", { required: true })}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShow(!show)}
                    >
                      {show ? <IoEyeOffOutline /> : <IoEyeOutline />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    Password is required
                  </p>
                )}
              </Box>
            </Stack>
            <Button
              width="80px"
              colorScheme={buttonColorScheme}
              type="submit"
              mt="4"
              isLoading={isLoading}
            >
              Submit
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default function AddAgent({
  isOpen,
  onOpen,
  onClose,
  activeTab,
  loadAgent,
}) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <AddAgentForm
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        loadAgent={loadAgent}
        activeTab={activeTab}
      />
    </GoogleReCaptchaProvider>
  );
}
