import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function useLog({ selectedLog }) {
  const [status, setStatus] = useState("loading");
  const [log, setLog] = useState({
    tool_function: null,
    tool: null,
    widget: null,
    assistant: null,
  });
  const domain = getHostDomain();
  const fetchToolByFunctionById = () =>
    fetchController(
      domain + `/api/v1/toolfunction/${selectedLog.tool_function_id}`
    );
  const fetchToolByToolId = () =>
    fetchController(domain + `/api/v1/tool/${selectedLog.tool_id}`);
  const fetchWidgetId = () =>
    fetchController(domain + `/api/v1/widget/${selectedLog.widget_id}`);
  const fetchAssistantById = () =>
    fetchController(domain + `/api/v1/assistant/${selectedLog.assistant_id}`);

  const fetchLog = async () => {
    try {
      setStatus("loading");
      const [
        toolFunctionResponse,
        toolResponse,
        widgetResponse,
        assistantResponse,
      ] = await Promise.all([
        fetchToolByFunctionById(),
        fetchToolByToolId(),
        fetchWidgetId(),
        fetchAssistantById(),
      ]);
      setLog({
        tool_function: toolFunctionResponse.data,
        tool: toolResponse.data,
        widget: widgetResponse.data,
        assistant: assistantResponse.data,
      });
      setStatus("success");
    } catch (error) {
      setStatus("error");
    }
  };
  useEffect(() => {
    fetchLog();
  }, [selectedLog]);
  const isLoading = status === "loading";
  const hasError = status === "error";
  return {
    isLoading,
    hasError,
    log,
    status,
  };
}
