import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function useTools() {
  const baseUrl = getHostDomain();
  const [toolsList, setToolsList] = useState([]);
  const [status, setStatus] = useState("idle");

  const getToolList = async () => {
    try {
      setStatus("loading");
      const response = await fetchController(
        baseUrl + `/api/v1/tool/list`
      );
      setToolsList(response.data.items);
    } catch (e) {
      console.error(e)
    } finally {
      setStatus("idle");
    }
  }
  useEffect(() => {
    getToolList()
  }, []);

  const sortByCategory = (category1, category2) =>
    category1.localeCompare(category2);

  const getCategory = (tool) => tool.category;

  const toolsTypes = Array.from(new Set(toolsList.map(getCategory))).sort(
    sortByCategory
  );

  return { toolsList, toolsTypes, status, getToolList };
}
