import { FormControl, FormLabel } from "@chakra-ui/react";
import Select from "react-select";
import useToolUserRunStaticFunction from "../Tools/useToolUserRunStaticFunction";
import FormLabelWithInfoTooltip from "./FormLabelWithInfoTooltip";
export default function SMSFields({ setValue, watch, auths }) {
  const { tool_user_id, sid } = watch();
  const { response } = useToolUserRunStaticFunction({
    tool_user_id,
    staticFnPayload: {
      function_name: "get_phone_numbers",
    },
  });
  const options = response
    ? response.map((phoneNumber) => ({
        value: phoneNumber.sid,
        label: phoneNumber.friendly_name,
      }))
    : [];
  const twilioAccountOptions = auths.map((auth) => ({
    label: auth.name,
    value: auth.id,
  }));
  return (
    <>
      <FormControl isRequired>
        <FormLabelWithInfoTooltip
          label={"Twilio Tool Config"}
          tip={"Setup the Twilio configuration using Tool and select it here"}
        />
        <Select
          required
          options={twilioAccountOptions}
          value={twilioAccountOptions.find(
            (option) => option.value === tool_user_id
          )}
          onChange={({ value }) => {
            setValue("tool_user_id", value);
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabelWithInfoTooltip
          label={"Select Phone number"}
          tip={"To be used for receiving and sending SMS messages"}
        />
        <Select
          options={options}
          value={options.find((option) => option.value === sid)}
          onChange={({ value, label }) => {
            setValue("phone_number", label);
            setValue("sid", value);
          }}
        />
      </FormControl>
    </>
  );
}
