import "./TextField.css";
export default function TextField({ field }) {
  return (
    <label className="text__field field">
      <div className="field__label">
        <span>{field.label}</span>
        {field.required ? <span className="required">*</span> : null}
      </div>
      <input type="text" placeholder={field.description} name={field.name} />
    </label>
  );
}
