import { Box, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { LuFileClock } from "react-icons/lu";

export default function NoLogsFound() {
  return (
    <Box height={"50svh"}>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"100%"}
      >
        <LuFileClock size={100} />
        <Heading>Nothing logged</Heading>
      </Flex>
    </Box>
  );
}
