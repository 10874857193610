import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
import AuthContext from "../../Auth/AuthContext";

const AgentLinkModal = ({
  isOpen,
  onClose,
  agentTobeOperate,
  setAgentTobeOperate,
}) => {
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;
  const [isLoading, setIsLoading] = useState(false);
  const [assistants, setAssistants] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors,isSubmitting },
    setValue,
    watch,
    reset,
    control,
    
  } = useForm();
  const {
    authUser: { is_superuser },
  } = useContext(AuthContext);
  const agent_id = agentTobeOperate?.id;
  const loadAssistant = async () => {
    try {
      const url = baseUrl + `/api/v1/assistant/list`;
      const response = await fetchController(url, "GET");
      const assistantList = response?.data?.items.map((item) => {
        return {
          value: item.id,
          label: `${item.name}
            (${item.description})
            `,
        };
      });
      setAssistants(assistantList);
    } catch (error) {
      console.log({ error });
    }
  };
  const onSubmit = async (data) => {
    try {
      const body = data?.assistant?.map((item) => item.value);
      const endpoint = `/api/v1/user/link-agent-assistants/?agent_id=${agent_id}`;
      const url = baseUrl + endpoint;
      await fetchController(url, "POST", body);
      toast.success("Agent linked successfully.");
    } catch (error) {
      toast.error(error?.message);
    } finally {
      await loadLinkedAssistant();
      onClose();
      reset();
    }
  };
  const loadLinkedAssistant = async () => {
    try {
      setIsLoading(true);
      const endpoint = is_superuser
        ? `/api/v1/user/linked_assistants_with_agent/?agent_user_id=${agentTobeOperate?.id}`
        : `/api/v1/user/linked_assistants_with_agent/`;
      const url = baseUrl + endpoint;
      const response = await fetchController(url, "GET");
      const linkedAssistantList = response?.data?.items.map((item) => {
        return {
          value: item.id,
          label: `${item.name}`,
        };
      });
      setValue("assistant", linkedAssistantList);
    } catch (error) {
      toast.error(error?.message);
    }finally{
        setIsLoading(false);
    }
  };
  useEffect(() => {
    loadAssistant();
  }, []);
  useEffect(() => {
    loadLinkedAssistant();
  }, [agentTobeOperate]);

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setAgentTobeOperate(null);
      }}
    >
      <ModalOverlay />
      <ModalContent height="auto" width="95%" maxWidth="650px">
        <ModalHeader color={textColor}>Link agent</ModalHeader>
        <ModalCloseButton />
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                direction="column"
                spacing={4}
                mb="4"
                width={"100%"}
                justifyContent={"space-between"}
              >
                <Controller
                  name="assistant"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={assistants}
                      isMulti
                      onChange={(selectedOptions) =>
                        field.onChange(selectedOptions)
                      }
                    />
                  )}
                />
              </Stack>
              <Button
                width="80px"
                colorScheme={buttonColorScheme}
                type="submit"
                mt="4"
                isLoading={isSubmitting}
              >
                Submit
              </Button>
            </form>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};


export default AgentLinkModal