import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import Select from "react-select";
import FormLabelWithInfoTooltip from "./FormLabelWithInfoTooltip";
export default function SelectTwilioAccount({
  setValue,
  errors,
  accounts,
  watch,
  name = "twilio_auth_id",
}) {
  return (
    <FormControl isInvalid={errors.twilio_auth_id}>
      <FormLabelWithInfoTooltip
        label={"Select twilio account"}
        tip={"Call will be initiated using this Twilio number"}
      />
      <Select
        placeholder="Select twilio account"
        name={name}
        value={accounts
          .map((account) => ({
            value: account.id,
            label: `${account?.name || ""} - ${account.id}`,
            sid: account.twilio_account_sid,
          }))
          .find((account) => account.value === watch(name))}
        options={accounts.map((account) => ({
          value: account.id,
          label: `${account?.name || ""} (${account.id})`,
        }))}
        onChange={(selectedOption) => {
          setValue(name, selectedOption.value);
        }}
      />
      {errors.twilio_auth_id && (
        <FormErrorMessage>{errors.twilio_auth_id.message}</FormErrorMessage>
      )}
    </FormControl>
  );
}
