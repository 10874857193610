
export const fileUploadFetch = async (formData, endPoint) => {
  try {
    const accessToken = localStorage.getItem("token");
    const fetchOptions = {
      method: "POST", // Assuming you're uploading a file using a POST request
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      credentials: "same-origin",
      mode: "cors",
      body: formData,
    };

    const hitFetch = await fetch(endPoint, fetchOptions);

    if (!hitFetch.ok) {
      throw new Error(`HTTP error! Status: ${hitFetch.status}`);
    }

    const responseData = await hitFetch.json();
    return responseData;
  } catch (error) {
    console.error("Error in fileUploadFetch:", error.message);
    return { error: true, message: error.message };
  }
};