import { InputLeftElement, InputGroup, Input } from "@chakra-ui/react";
import { MdSearch } from "react-icons/md";
export default function SearchInput({ search, onChangeSearch }) {
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <MdSearch color="gray.300" />
      </InputLeftElement>
      <Input
        type="text"
        placeholder="Search tool"
        onChange={onChangeSearch}
        value={search}
      />
    </InputGroup>
  );
}
