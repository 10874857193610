import { Flex, FormControl, FormLabel, Input, Switch } from "@chakra-ui/react";
export default function FunctionCheckboxField({
  toolFunctionOption,
  toolUserForm,
}) {
  const { description, id, isToolFunctionIdPartOfToolUser, name } =
    toolFunctionOption;
  const { watch, setValue } = toolUserForm;
  const { tool_function_ids = {} } = watch();
  const overwrittenInstructions = isToolFunctionIdPartOfToolUser
    ? tool_function_ids[id].overwritten_instructions
    : description;
  const inputKey = `tool_function_ids.${id}.overwritten_instructions`;

  return (
    <FormControl
      border={"1px solid lightgray"}
      p={2}
      key={id}
      borderRadius={"md"}
    >
      <FormLabel>{name}</FormLabel>
      <Flex justifyContent={"center"} alignItems={"center"} gap={2} key={id}>
        <Switch
          onChange={(e) => {
            if (e.currentTarget.checked) {
              setValue(inputKey, e.currentTarget.checked ? description : "");
            } else {
              delete tool_function_ids[id];
              setValue(`tool_function_ids`, tool_function_ids);
            }
          }}
          isChecked={isToolFunctionIdPartOfToolUser}
        />
        <Input
          value={overwrittenInstructions}
          isDisabled={!isToolFunctionIdPartOfToolUser}
          onChange={(e) => {
            setValue(inputKey, e.currentTarget.value);
          }}
        />
      </Flex>
    </FormControl>
  );
}
