import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../utils/Loader/Loader";
import useQueryParams from "../Tools/useQueryParams";
import AuthContext from "./AuthContext";

export default function OAuthRedirectLoginToken() {
  const query = useQueryParams();
  const code = query.get("code");
  const provider = query.get("provider") || "microsoft";
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      if (!code || !provider) return;
      try {
        const providerKey =
          provider === "microsoft" ? "" : `?provider=${provider}`;
        await authContext.exchangeCodeWithToken({
          code,
          provider,
          redirect_uri: `${process.env.REACT_APP_GOOGLE_REDIRECT_URI}${providerKey}`,
        });
        await authContext.getUserData();
        navigate("/dashboard");
      } catch (error) {
        navigate("/login");
      }
    })();
  }, [code, provider]);
  return <Loader />;
}
