import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import TourLayout from "../Layout/TourLayout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { useContext } from "react";

export default function AssistantTour({ onCloseAssistantTour }) {
    const agency = useContext(AgencyContext);
    const { textColor, agencyData, myDetails } = agency;
  return (
    <TourLayout onCloseTour={onCloseAssistantTour}>
      <Box fontSize={"md"}>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          What is Assistant?
        </Text>
        <Text>
          An Assistant is the brain of your AI Agent. It brings together the
          data sources, users' queries, tools, and forms and orchestrates the
          end-to-end conversation with your users.
        </Text>
        <UnorderedList>
          <ListItem>
            <strong>Chatbot : </strong> Use them to deploy QnA chatbots on your
            website, social channels like WhatsApp, or even at physical places
            like clinics, events. They excel at finding comprehensive answers
            based on your data sources.
          </ListItem>
          <ListItem>
            <strong>Phone : </strong>These are AI-enabled phone agents that can
            be used to make or receive phone calls driven by LLM prompts. It can
            be used for making appointments, phone ordering, reminders and so
            much more. Make sure to link the Form to the Assistant if you intend
            to capture information from your customer. Your Prompt should be
            good enough to gather all the details and{" "}
            {myDetails?.branding?.agency_name || "Insighto.ai"} will extract the
            information and convert that into the Form structure
          </ListItem>
        </UnorderedList>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          Create Your AI Assistant
        </Text>
        <Text>Let’s set up your assistant now</Text>
        <UnorderedList>
          <ListItem>
            Click "Add an Assistant", the blue button in the top right corner.
          </ListItem>
          <ListItem>
            Choose a name for your assistant and write a brief description
          </ListItem>
          <ListItem>Select your preferred LLM model</ListItem>
          <ListItem>
            Enable your preferred voice search engine (optional) and desired
            language (max 4).
          </ListItem>
          <ListItem>Click "Submit" to create your assistant.</ListItem>
        </UnorderedList>
      </Box>
    </TourLayout>
  );
}
