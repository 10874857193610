import { useContext } from "react";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

export default function IframeWidget({ widgetId, widgetType }) {
  const agency = useContext(AgencyContext);
  const { myDetails } = agency;
  const agency_id = myDetails ? myDetails?.agency_id : null;
  const agency_cdn = myDetails?.domain?.chatbotDomain;
  const src = generateSrc();
  return (
    <iframe
      src={src}
      allow="microphone"
      style={{
        border: "none",
        width: "100%",
        maxWidth: "800px",
        height: "600px",
      }}
    />
  );
  function generateSrc() {
    const hostname = agency_id && agency_cdn ? agency_cdn : "cdn.insighto.ai";
    const url = new URL(`https://${hostname}`);
    const pathnamesWidgetTypes = {
      web_call: "web-call-iframe.html",
      html_call: "call-iframe.html",
    };
    const pathname = pathnamesWidgetTypes[widgetType] || "bot-iframe.html";
    url.pathname = `/${pathname}`;
    url.searchParams.append("widgetId", widgetId);
    return url.toString();
  }
}
