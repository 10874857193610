import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
} from "@chakra-ui/react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import { getHostDomain } from "../../utils/utils";

const AddEditContactCustomField = ({ isOpen, onClose, reFreshTable, fieldsToEdit, buttonColorScheme }) => {
  const baseUrl = getHostDomain();
  const [isLoading, setIsLoading] = useState(false);
  const [customFields, setCustomFields] = useState({ name: "", type: "" });
  const [isValidate, setIsValidate] = useState(false);

  useEffect(() => {
    if(fieldsToEdit) {
      setCustomFields({name: fieldsToEdit?.custom_field_name, type: fieldsToEdit?.custom_field_type})
      setIsValidate(true)
    }
  }, [fieldsToEdit])

  const onSubmit = async () => {
      setIsValidate(true);
      if(customFields.name === "" || customFields.type === "") return
      try {
      setIsLoading(true);
      const endpoint = fieldsToEdit ? `/api/v1/contact_custom_field/${fieldsToEdit?.id}` : "/api/v1/contact_custom_field";
        const urlHit = baseUrl + endpoint;
      const payload = {
        "custom_field_name": customFields.name,
        "custom_field_type": customFields.type,
      };
      const method = fieldsToEdit ? "PUT" : "POST"
      const response = await fetchController(urlHit, method, payload);
      toast.success(response?.message)
      handleModalClose();
      reFreshTable();
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsValidate(false);
    setCustomFields({ name: "", type: "" });
    onClose();
  };

  return (
    <>
      <Modal size="xl" isOpen={isOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent height="auto" width="95%" maxWidth="600px">
          <ModalHeader>{"Add Custom Field"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap={4}>
              <FormControl
                isRequired
                isInvalid={isValidate && customFields.name === ""}
              >
                <FormLabel fontSize={"small"}>{"Name"}</FormLabel>
                <Input
                  value={customFields.name}
                  onChange={(e) =>
                    setCustomFields((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }))
                  }
                  isRequired
                  variant="outline"
                  placeholder="Name"
                />
                {isValidate && customFields.name === "" && (
                  <FormErrorMessage>{"Name is required"}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                isRequired
                isInvalid={isValidate && customFields.type === ""}
              >
                <FormLabel fontSize={"small"}>{"Type"}</FormLabel>
                <Select
                  placeholder="Select type"
                  value={customFields.type}
                  onChange={(e) =>
                    setCustomFields((prevState) => ({
                      ...prevState,
                      type: e.target.value,
                    }))
                  }
                  isRequired
                  variant={"outline"}
                >
                  <option value={"string"}>{"String"}</option>
                  <option value={"number"}>{"Number"}</option>
                </Select>
                {isValidate && customFields.type === "" && (
                  <FormErrorMessage>{"Type is required"}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>

            <Button
              isLoading={isLoading}
              colorScheme={buttonColorScheme}
              mt="4"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddEditContactCustomField;
