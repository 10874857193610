import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./global-css/global.css";
import SettingProvider from "./components/Settings/Reducer/SettingProvider";
import { AgencyProvider } from "./components/Settings/AgencyProvider/AgencyProvider";
import { ColorModeScript } from '@chakra-ui/react'
import theme from './theme'
import AuthProvider from "./components/Auth/AuthProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <AuthProvider>
      <div className="app">
        <ChakraProvider>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <SettingProvider>
            <AgencyProvider>
              <App />
            </AgencyProvider>
          </SettingProvider>
        </ChakraProvider>
      </div>
    </AuthProvider>
  </BrowserRouter>
);
