import {
  Box,
  Button,
  Center,
  Flex,
  Td,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import Loader from "../../../utils/Loader/Loader";
import { getHostDomain } from "../../../utils/utils";
import AddAgent from "../../Modals/AddAgent";
import Table from "../../Tables/Table";
import { AgencyContext } from "../AgencyProvider/AgencyProvider";
import { SettingContext } from "../Reducer/SettingProvider";
import ConfirmationModal from "../../Modals/Confirmation";
import { toast } from "react-toastify";
import { MdDatasetLinked, MdDelete } from "react-icons/md";
import AgentLinkModal from "./AgentLinkModal";
import Paginator from "../../../utils/Loader/Paginator";
const Agent = ({ activeTab }) => {
  const baseUrl = getHostDomain();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure();
  const {
    isOpen: isOpenLinkAgentModal,
    onOpen: onOpenLinkAgentModal,
    onClose: closeLinkAgentModal,
  } = useDisclosure();
  const [agentTobeOperate, setAgentTobeOperate] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [status, setStatus] = useState("idle");
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);
  const loading = status === "loading" && isInitialLoad;
  const { state: user } = useContext(SettingContext);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const loadAgent = async () => {
    try {
      setStatus("loading");
      const url = baseUrl + `/api/v1/user/get_agent_list/?page=${page}&size=10`;
      const response = await fetchController(url, "GET");
      setPages(response?.data?.pages);
      const tableData = response.data.items.map((item, index) => {
        return {
          ["SNo"]: index + 1,
          id: item.id,
          name: item.first_name + " " + item.last_name,
          email: item.email,
          operation: (
            <Td cursor="pointer" p={2}>
              <Flex gap="2" marginLeft="20px">
                <Tooltip label="Delete agent" aria-label="Delete agent">
                  <Center>
                      <MdDelete
                        onClick={() => {
                          setAgentTobeOperate(item);
                          onOpenConfirmationModal();
                        }}
                        color="red"
                      />
                  </Center>
                </Tooltip>
                <Tooltip label="Link Assistant" aria-label="Link Assistant">
                  <Center>
                    <MdDatasetLinked
                      onClick={() => {
                        setAgentTobeOperate(item);
                        onOpenLinkAgentModal();
                      }}
                    />
                  </Center>
                </Tooltip>
              </Flex>
            </Td>
          ),
        };
      });

      const heads = ["SNo", "name", "email", "operation"];
      const columns = heads.map((key) => ({
        Header: key,
        accessor: key,
        Filter: ({ column }) => <input {...column.filterProps} />,
      }));
      setTableData(tableData);
      setHeads(columns);
      setStatus("success");
    } catch (error) {
      setStatus("failure");
      console.log({error})
    }finally{
      setIsInitialLoad(false);
    }
  };
  useEffect(() => {
    loadAgent();
  }, [page]);
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;
  const onConfirmDelete = async () => {
    if (!agentTobeOperate) return;
    handleDeleteAgent(agentTobeOperate);
    onCloseConfirmationModal();
  };
  const handleDeleteAgent = async (item) => {
    try {
      const endpoint = `/api/v1/user/remove_agent/${item.id}`;
      const url = baseUrl + endpoint;
      await fetchController(url, "DELETE");
      toast.success("Agent deleted successfully.");
      await loadAgent();
    } catch (error) {
      toast.error(error);
    } 
  };
  return (
    <Box border="1px" p="5" borderRadius="md" h="81vh" overflowY="auto">
      {" "}
      <Box h="75vh" p="5" overflowY="auto">
        <Flex justifyContent="end" alignItems="center" mb="4">
          <Tooltip
            label={
              user?.credits?.human_agents <= tableData.length
                ? "All human agent credit are consumed"
                : ""
            }
            aria-label="A tooltip"
          >
            <Button
              colorScheme={buttonColorScheme}
              display="block"
              onClick={() => {
                onOpen();
              }}
              isDisabled={
                user?.credits?.human_agents
                  ? user?.credits?.human_agents <= tableData.length
                  : true
              }
            >
              Add
            </Button>
          </Tooltip>
        </Flex>
        {loading ? (
          <Loader />
        ) : (
          <Box height="auto">
            <Table
              fetch={loadAgent}
              columns={heads}
              data={tableData}
              table="agents"
            />
            <Flex
              justifyContent={"flex-end"}
              alignItems={"center"}
              marginBlock={2}
            >
              <Paginator
                colorScheme={buttonColorScheme}
                onChangePage={setPage}
                page={page}
                pages={pages}
              />
            </Flex>
          </Box>
        )}
      </Box>
      <AddAgent
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        loadAgent={loadAgent}
        activeTab={activeTab}
        buttonColorScheme={buttonColorScheme}
        textColor={textColor}
      />
      <ConfirmationModal
        table={"agents"}
        isOpen={isConfirmationModalOpen}
        onConfirm={onConfirmDelete}
        onClose={onCloseConfirmationModal}
      />
      {isOpenLinkAgentModal ? (
        <AgentLinkModal
          agentTobeOperate={agentTobeOperate}
          setAgentTobeOperate={setAgentTobeOperate}
          isOpen={isOpenLinkAgentModal}
          onClose={closeLinkAgentModal}
        />
      ) : null}
    </Box>
  );
};

export default Agent;
