import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import PurchasedAddonItem from "./PurchasedAddonItem";

export default function PurchasedAddons({
  addonsList,
  fetchCurrentUserAddons,
}) {
  return (
    <Box>
      {addonsList.length ? (
        <Box pb={2}>
          <Text fontSize={"md"} fontWeight={"bold"}>
            Purchased Addons
          </Text>
        </Box>
      ) : null}
      <SimpleGrid minChildWidth={150}>
        {addonsList.map((addon) => (
          <PurchasedAddonItem
            addon={addon}
            key={addon.id}
            fetchCurrentUserAddons={fetchCurrentUserAddons}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
}
