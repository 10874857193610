import React, { useEffect } from "react";
import InstructionModal from "../Modals/InstructionModal";
import { useDisclosure } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

const ModalWrapper = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  useEffect(() => {
    const visitedPages = JSON.parse(localStorage.getItem("visitedPages")) || [];
    const currentPageVisited = visitedPages.includes(location.pathname);
    if (!currentPageVisited) {
      const updatedVisitedPages = [...visitedPages, location.pathname];
      localStorage.setItem("visitedPages", JSON.stringify(updatedVisitedPages));
    }
  }, [location.pathname]);

  return (
    <>
      {children}
      <InstructionModal onClose={onClose} isOpen={isOpen} />
    </>
  );
};

export const WrappedModal = (WrappedComponent) => (props) => {
  return (
    <ModalWrapper>
      <WrappedComponent {...props} />
    </ModalWrapper>
  );
};
