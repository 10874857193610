import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function useToolUser({ tool_user_id, reset }) {
  const baseUrl = getHostDomain();
  const [toolUser, setToolUser] = useState();
  const [status, setStatus] = useState("idle");
  useEffect(() => {
    (async () => {
      if (!tool_user_id) return;
      setStatus("loading");
      const response = await fetchController(
        baseUrl + `/api/v1//tool/LinkToolUser/${tool_user_id}`
      );
     
      reset({
        tool_user_id,
        credentials: response.data.credentials,
        is_custom_duration: !["30", "60", "120"].includes(
          response.data.credentials?.configuration?.appointment_duration || ""
        ),
        tool_function_ids: response.data.attributes?.tool_function_ids,
      });
      setToolUser(response.data);

      setStatus("idle");
    })();
  }, [tool_user_id]);

  return { toolUser, status };
}
