import { useState } from "react";
import { Button, Text, Flex, Box, Tooltip } from "@chakra-ui/react";
import { FaRegCopy } from "react-icons/fa";
const CopyToClipboardButton = ({ text, buttonColorScheme }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <Flex
      direction={"row"}
      //   border={"2px solid red"}
      justifyContent={"space-around"}
      width={"100%"}
      gap={2}
    >
      <Box
        border={"1px solid"}
        borderRadius={5}
        borderColor={"inherit"}
        flex={4}
      >
        <Text
          borderRadius="md"
          display="inline-block"
          transition="background-color 0.3s"
          p={2}
        >
          <Box as={"span"} bg={copied ? "lightblue" : "transparent"}>
            {" "}
            {text}
          </Box>{" "}
        </Text>
      </Box>
      <Box>
        {" "}
        <Tooltip label={copied?"Copied":"Click to copy"} fontSize="md">
          <Button
            colorScheme={buttonColorScheme}
            isDisabled={copied}
            onClick={copyToClipboard}
          >
            <FaRegCopy />
          </Button>
        </Tooltip>
      </Box>
    </Flex>
  );
};

export default CopyToClipboardButton;
