import { Box, Skeleton, Stack } from "@chakra-ui/react";
import Message from "../../Conversations/Message";

export default function Messages({ loading, messages, skeletonMap }) {
  return (
    <Box className="parent" id="parentDiv">
      <>
        {loading.message ? (
          <Box display={"flex"} flexDirection={"column"} spacing={"2"}>
            <Stack spacing="1">
              {skeletonMap.map((item) => {
                return (
                  <>
                    <Skeleton
                      startColor="#3182ce6e"
                      endColor="##3182ce6e"
                      height="36px"
                      bg="green.500"
                      color="white"
                      key={`${item}`}
                    ></Skeleton>
                  </>
                );
              })}
            </Stack>
          </Box>
        ) : (
          <>
            {messages.length ? (
              <>
                {messages?.map((messageData) => {
                  return (
                    <>
                      <Message
                        messageData={messageData?.ConversationChat}
                        key={messageData.id}
                      />
                    </>
                  );
                })}
              </>
            ) : (
              <Box
                px={3}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                py={2}
                padding
                borderRadius="5px"
              >
                No messages{" "}
              </Box>
            )}
          </>
        )}
      </>
    </Box>
  );
}
