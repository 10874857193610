

const useFavicon = (url) => {
        const link = document.querySelector("link[rel*='icon']")
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = url;
        document.head.appendChild(link)
   
}

export default useFavicon