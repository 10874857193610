import {
  Box,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

const WidgetDataSources = ({ isOpen, onOpen, onClose, selectedItem }) => {
  const baseUrl = getHostDomain();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const hitFetch = async () => {
    try {
      const endpoint = `/api/v1/assistant/${selectedItem?.assistant_id}/list_data_sources?page=1&size=50`;
      const urlHit = baseUrl + endpoint;
      setIsLoading(true);
      const getData = await fetchController(urlHit, "GET");
      if (getData?.data?.items) {
        const updatedTableData = getData.data.items.map((item) => ({
          ...item,
          linked: true, // Assuming all items are linked in this context
        }));

        const filteredTableData = updatedTableData.filter(
          (item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setTableData(filteredTableData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      hitFetch();
    }
  }, [isOpen, searchTerm]);

  return (
    <>
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent height="70vh" width="95%" maxWidth="600px">
          <ModalHeader>Linked Data Sources</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb="4">
              <Input
                disabled={isLoading}
                placeholder="Search by name or description"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
            {isLoading ? (
              <Spinner
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
              />
            ) : tableData.length > 0 ? (
              <TableContainer height="50vh" overflowY="Scroll">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Description</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tableData.map((item) => (
                      <Tr key={item.id}>
                        <Td>{item.name}</Td>
                        <Td>{item.description}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Box textAlign="center" mt="4">
                No Data Sources available.
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WidgetDataSources;
