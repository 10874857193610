import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import Select from "react-select";
import { fetchController } from "../../utils/FetchController/fetchController";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";
export default function CampaignFields({ watch, setValue, register, errors }) {
  const baseUrl = getHostDomain();
  const [widgets, setWidgets] = useState([]);
  const widgetOptions = widgets.map((widget) => ({
    value: widget,
    label: `${widget.name} [${widget.widget_type}]`,
  }));
  const getWidgets = useCallback(async () => {
    const response = await fetchController(
      baseUrl + "/api/v1/widget/get_my_details"
    );
    setWidgets(
      response.data.items.filter(
        (widgetItem) => widgetItem.widget_type === "phone"
      )
    );
  }, []);
  useEffect(() => {
    getWidgets();
  }, []);
  const theme = useTheme();
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg:bg } = agency
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: bg,
    }),
    menu: base => ({
      ...base,
      background: bg,
    }),
    menuList: base => ({
      ...base,
      background: bg,
    })
  }
  return (
    <>
      <FormControl isInvalid={errors.widget} isRequired>
        <FormLabel>Widget</FormLabel>
        <Select
          autoFocus
          {...register("widget", {
            required: true,
          })}
          value={watch("widget")}
          onChange={(selectedWidget) => {
            setValue("widget", selectedWidget);
          }}
          options={widgetOptions}
          styles={customStyles}
        />
        <FormErrorMessage>
          {errors.widget?.type === "required" ? "Widget is required" : null}
        </FormErrorMessage>
        <Text fontSize={"sm"}>Only widget of type phone will appear here.</Text>
      </FormControl>
      {watch("widget") && watch("widget").value?.widget_type === "whatsapp" ? (
        <FormControl isInvalid={errors.message} isRequired>
          <FormLabel>Message</FormLabel>
          <Textarea
            value={watch("message")}
            {...register("message", {
              required: true,
            })}
          />
          <FormErrorMessage>
            {errors.message?.type === "required" ? "Message is required" : null}
          </FormErrorMessage>
        </FormControl>
      ) : null}
    </>
  );
}
