import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Stack,
} from "@chakra-ui/react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { useNavigate, useSearchParams } from "react-router-dom";
import PasswordInput from "../Forms/PasswordInput";
import { MdLockReset } from "react-icons/md";
import { toast } from "react-toastify";
import { getHostDomain } from "../../utils/utils";
import { useContext, useState } from "react";
import AgencyLogo from "./AgencyLogo";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

export default function ResetPassword() {
  const baseUrl = getHostDomain();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [isValidate, setIsValidate] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsValidate(true);
    if (!formData.password || formData.password !== formData.confirmPassword)
      return;

    try {
      setLoading(true);
      const response = await fetchController(
        `${baseUrl}/api/v1/login/reset_password`,
        "POST",
        {
          password: formData.password,
        },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${searchParams.get("token")}`,
        }
      );
      const { access_token, refresh_token } = response.data;

      localStorage.setItem("token", access_token);
      localStorage.setItem("refreshToken", refresh_token);
      toast.success("Password reset successfully");
      navigate("/dashboard");
    } catch (e) {
      toast.error(e?.response?.data?.detail || "Some error occurred");
    } finally {
      setLoading(false);
    }
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg } = agency;
  return (
    <Box height={"100vh"} bgColor={"#F0F1F8"} p={1}>
      <Flex height={"100%"}>
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          width={"50%"}
          spacing={4}
          py={10}
        >
          <Box>
            <AgencyLogo />
          </Box>
          <Heading size="xl" color={textColor}>
            Welcome
          </Heading>
          <Heading size="md" color={textColor}>
            Log in to continue to App.
          </Heading>
        </Stack>

        <Stack
          width={"50%"}
          px={10}
          py={20}
          spacing={6}
          justifyContent="center"
          alignItems={"center"}
          borderRadius={20}
          bgColor={cardBg}
        >
          <Flex
            gap={4}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            mb={10}
          >
            <MdLockReset fontSize={"40px"} />
            <Heading size="xl">Reset Password</Heading>
          </Flex>

          <form onSubmit={onSubmit} style={{ width: "80%" }}>
            <Flex direction={"column"} gap={6}>
              <FormControl isInvalid={isValidate && !formData.password}>
                <FormLabel>New Password</FormLabel>
                <PasswordInput
                  placeholder={"Enter new password"}
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                />
                {isValidate && !formData.password && (
                  <FormErrorMessage>
                    {"New password is required"}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                isInvalid={
                  isValidate && formData.password !== formData.confirmPassword
                }
              >
                <FormLabel>Confirm password</FormLabel>
                <PasswordInput
                  placeholder={"Enter confirm password"}
                  value={formData.confirmPassword}
                  onChange={(e) =>
                    setFormData({ ...formData, confirmPassword: e.target.value })
                  }
                />
                {isValidate &&
                  formData.password !== formData.confirmPassword && (
                    <FormErrorMessage>
                      {"Password should match"}
                    </FormErrorMessage>
                  )}
              </FormControl>

              <Button
                type="submit"
                colorScheme={buttonColorScheme}
                isLoading={loading}
                w={"100%"}
              >
                {"Change Password"}
              </Button>
            </Flex>
          </form>
        </Stack>
      </Flex>
    </Box>
  );
}
