import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useState } from "react";

export default function PasswordInput({ placeholder, value, onChange, register, autoComplete="off" }) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      {register ? (
        <Input
          pr="4.5rem"
          type={show ? "text" : "password"}
          placeholder={placeholder || ""}
          bgColor={"white"}
          {...register}
        />
      ) : (
        <Input
          pr="4.5rem"
          type={show ? "text" : "password"}
          placeholder={placeholder || ""}
          bgColor={"white"}
          value={value}
          onChange={onChange}
        />
      )}
      <InputRightElement width="4.5rem">
        <Button type="button" h="1.75rem" size="sm" onClick={handleClick}>
          {show ? <ViewOffIcon /> : <ViewIcon />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}
