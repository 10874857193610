import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Highlight,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import QRCode from "react-qr-code";
import { fetchController } from "../../utils/FetchController/fetchController";
import { toast } from "react-toastify";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";

const Code = ({ isOpen, selectedItem: selectedWidget, onClose }) => {
  const baseUrl = getHostDomain();
  const agency = useContext(AgencyContext);
  const chatbotDomain =
    agency?.myDetails?.domain?.chatbotDomain || "cdn.insighto.ai";
  const apiDomain = agency?.myDetails?.domain?.apiDomain || "api.insighto.ai";
  const widgetId = selectedWidget?.id;
  const finalRef = useRef(null);
  const agency_id = agency?.myDetails ? agency?.myDetails?.agency_id : null;
  const agencyName = agency?.myDetails?.branding?.agency_name;
  const iframeHtmlName = `${
    selectedWidget?.widget_type === "html_call"
      ? "call"
      : selectedWidget?.widget_type === "web_call"
      ? "web-call"
      : "bot"
  }-iframe.html`;
  const chatWidgetCode = {
    web_call: {
      label: "Phone Icon Widget",
      placeholder:
        "Place the style in head tag and iframe anywhere in your body tag",
      data: `<!-- code for ${
        agency_id ? agencyName : "Insighto.ai"
      } HTML Iframe Widget-->
    <!-- Place the code in head tag -->
     <link rel="stylesheet" href="https://${chatbotDomain}/web_call_assets/phone_icon.min.css" />
    <!-- End of head tag code-->
    <!-- Place the code in body tag -->
       <iframe
      id="phoneIconCallIframe"
      allow="microphone"
      src="https://${chatbotDomain}/${iframeHtmlName}?widgetId=${widgetId}&deployType=phoneIcon"
      frameborder="0"
    ></iframe>
    <!-- End of body tag code-->
      `,
    },
    chat: {
      label: "Chat Widget",
      placeholder: "Place the code in your HEAD tag.",
      data: `<!-- code for ${
        agency_id ? agencyName : "Insighto.ai"
      } chat widget -->
<script>
  const insighto_ai_widget_id = "${widgetId}"
</script>
<script defer src="https://${chatbotDomain}/assets/widget.min.js"></script>
<link rel="stylesheet" href="https://${chatbotDomain}/assets/widget.min.css">
<!-- end of ${agency_id ? agencyName : "Insighto.ai"} chat widget code -->`,
    },
  };

  const widgetOptions = [
    {
      label: "HTML Iframe Widget",
      placeholder:
        "Place the code anywhere in your BODY tag where you want to show the widget.",
      data: `<!-- code for ${
        agency_id ? agencyName : "Insighto.ai"
      } HTML Iframe Widget -->
<iframe 
      src="https://${chatbotDomain}/${iframeHtmlName}?widgetId=${widgetId}"
      allow="microphone" 
      style="border: none; display: block; width: 100%; height: 100%;"></iframe>
<!-- end of ${
        agency_id ? agencyName : "Insighto.ai"
      } HTML Iframe Widget code-->`,
    },
    chatWidgetCode[selectedWidget?.widget_type] || chatWidgetCode.chat,
  ];
  const [selectedWidgetOption, setSelectedWidgetOption] = useState(0);
  const onChangeOption = (e) => {
    setSelectedWidgetOption(e.currentTarget.value);
  };
  const { onCopy: onCopy, hasCopied: isCopied } = useClipboard(
    widgetOptions[selectedWidgetOption].data
  );
  const query = Number(selectedWidgetOption) === 0 ? "BODY" : "HEAD";
  function PhoneWidgetCode() {
    const apiLinkTwilio = `https://${apiDomain}/api/v1/call/initiate_call/${selectedWidget.id}`;
    const { onCopy: onCopy, hasCopied: isCopied } = useClipboard(apiLinkTwilio);
    return (
      <Box>
        <Box marginBlock={3}>
          <Text fontSize={"md"}>
            Twilio Voice Webhook should always be set as
          </Text>
        </Box>
        <Box
          onClick={onCopy}
          p="2"
          borderWidth="1px"
          borderRadius="md"
          _hover={{ cursor: "pointer", backgroundColor: "gray.50" }}
        >
          <Text>{apiLinkTwilio}</Text>
        </Box>
        <Text fontSize="12px" color="gray.500" textAlign="left">
          {isCopied ? "Code Copied!" : "Click to Copy Code"}
        </Text>
      </Box>
    );
  }
  function WidgetCode() {
    return (
      <Box>
        <FormControl>
          <FormLabel>What type of chat widget you want ?</FormLabel>
          <Select value={selectedWidgetOption} onChange={onChangeOption}>
            {widgetOptions.map((option, index) => (
              <option
                disabled={
                  selectedWidget?.widget_type === "html_call"
                    ? index !== 0
                    : false
                }
                key={index}
                value={index}
              >
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <Box marginBlock={2}>
          <Text fontSize="14px" color="gray.500" mb="2">
            <Highlight
              query={query}
              styles={{ px: "1", py: "1", bg: "orange.100" }}
            >
              {widgetOptions[selectedWidgetOption].placeholder}
            </Highlight>
          </Text>
          <Box p="2" borderWidth="1px" borderRadius="md">
            <Text fontSize="md" whiteSpace="pre-wrap">
              {widgetOptions[selectedWidgetOption].data}
            </Text>
          </Box>
        </Box>
        <Flex justifyContent={"flex-end"} mt={3} gap={2}>
          <Stack direction={"row"} gap={2}>
            <Button onClick={onCopy} colorScheme="blue">
              {isCopied ? "Code Copied!" : "Embed"}
            </Button>

            <Button
              onClick={() => {
                window.open(
                  `https://${chatbotDomain}/${
                    selectedWidget.widget_type === "html_call"
                      ? "call-iframe"
                      : selectedWidget?.widget_type === "web_call"
                      ? "web-call-iframe"
                      : "widget"
                  }.html?widgetId=${widgetId}`
                );
              }}
              colorScheme="orange"
            >
              Demo
            </Button>
          </Stack>
        </Flex>
      </Box>
    );
  }
  const qrCodeRef = useRef(null);
  const onExportWidgetQRCode = () => {
    if (!qrCodeRef.current) return;

    const qrCodePrint = window.open("", "");
    qrCodePrint.document.write(`<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>QR Code widget - ${widgetId}</title>
    </head>
    <body>
        <h1>QR Code for widget</h1>
        ${qrCodeRef.current.innerHTML}
        <h2><a href="https://${chatbotDomain}/bot-iframe.html?widgetId=${widgetId}">https://${chatbotDomain}/bot-iframe.html?widgetId=${widgetId}</a></h2>
    </body>
    </html>
    `);
    qrCodePrint.document.close();
    qrCodePrint.focus();
    qrCodePrint.print();
  };
  function PhoneQrCode() {
    const phoneQrCodeRef = useRef(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [status, setStatus] = useState("idle");
    const fetchWidgetById = useCallback(async () => {
      try {
        setStatus("loading");
        const response = await fetchController(
          baseUrl + `/api/v1/widget/${widgetId}`
        );
        setPhoneNumber(response.data.attributes.phone_number);
      } catch (error) {
        toast.error("Some error fetching qr code");
      } finally {
        setStatus("idle");
      }
    }, []);
    useEffect(() => {
      if (selectedWidget?.widget_type === "phone") fetchWidgetById();
    }, []);
    function onExportPhoneQrCode() {
      if (!phoneQrCodeRef.current) return;

      const qrCodePrint = window.open("", "");
      qrCodePrint.document.write(`<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>QR Code widget - ${widgetId}</title>
    </head>
    <body>
        <h1>QR Code for phone number</h1>
        ${phoneQrCodeRef.current.innerHTML}
    </body>
    </html>
    `);
      qrCodePrint.document.close();
      qrCodePrint.focus();
      qrCodePrint.print();
    }
    return status === "loading" ? (
      <Spinner />
    ) : (
      <>
        <Box ref={phoneQrCodeRef}>
          <QRCode
            style={{
              margin: "auto",
            }}
            value={`tel:${phoneNumber}`}
          />
        </Box>
        <Flex mt={2} justifyContent={"flex-end"} alignItems={"center"}>
          <Button onClick={onExportPhoneQrCode} colorScheme="orange">
            Export
          </Button>
        </Flex>
      </>
    );
  }
  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent width="95%" maxWidth="600px">
          <ModalHeader>Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>Widget Code</Tab>
                <Tab>QR Code</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  {selectedWidget?.widget_type === "phone" ? (
                    <PhoneWidgetCode />
                  ) : selectedWidget?.widget_type === "whatsapp" ? (
                    <WhatsAppWidgetCode />
                  ) : (
                    <WidgetCode />
                  )}
                </TabPanel>
                <TabPanel>
                  <Box>
                    <Flex
                      margin={"auto"}
                      marginBlock={4}
                      p={4}
                      width={"100%"}
                      maxWidth={300}
                      bg={"gray.200"}
                      borderRadius={"md"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Heading fontSize={"large"}>Scan QR Code</Heading>
                      <Text>Scan the QR Code for link to widget.</Text>
                    </Flex>
                    <Box pb={4}>
                      {selectedWidget?.widget_type === "phone" ? (
                        <PhoneQrCode />
                      ) : selectedWidget?.widget_type === "whatsapp" ? (
                        <WhatsAppQrCode widgetId={widgetId} />
                      ) : (
                        <>
                          <Box ref={qrCodeRef}>
                            <QRCode
                              style={{
                                margin: "auto",
                              }}
                              value={`https://${chatbotDomain}/${
                                selectedWidget?.widget_type === "html_call"
                                  ? "call"
                                  : selectedWidget?.widget_type === "web_call"
                                  ? "web-call"
                                  : "bot"
                              }-iframe.html?widgetId=${widgetId}`}
                            />
                          </Box>
                          <Flex
                            mt={2}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                          >
                            <Button
                              onClick={onExportWidgetQRCode}
                              colorScheme="orange"
                            >
                              Export
                            </Button>
                          </Flex>
                        </>
                      )}
                    </Box>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Code;

function WhatsAppQrCode({ widgetId }) {
  const baseUrl = getHostDomain();
  const whatsAppQRCodeRef = useRef(null);
  function onExportWhatsappQrCode() {
    if (!whatsAppQRCodeRef.current) return;

    const qrCodePrint = window.open("", "");
    qrCodePrint.document.write(`<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>QR Code - ${widgetId}</title>
  </head>
  <body>
      <h1>QR Code</h1>
      ${whatsAppQRCodeRef.current.innerHTML}
  </body>
  </html>
  `);
    qrCodePrint.document.close();
    qrCodePrint.focus();
    qrCodePrint.print();
  }
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState("idle");
  const fetchWidgetById = useCallback(async () => {
    try {
      setStatus("loading");
      const response = await fetchController(
        baseUrl + `/api/v1/widget/${widgetId}`
      );
      setPhoneNumber(response.data.attributes.whatsapp_phone_number);
    } catch (error) {
      toast.error("Some error fetching qr code");
    } finally {
      setStatus("idle");
    }
  }, []);
  useEffect(() => {
    fetchWidgetById();
  }, [widgetId]);
  return (
    <>
      <Box ref={whatsAppQRCodeRef}>
        {status === "loading" ? (
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Spinner />
          </Flex>
        ) : (
          <QRCode
            style={{
              margin: "auto",
            }}
            value={`https://web.whatsapp.com/send?phone=${phoneNumber}&text=Hi`}
          />
        )}
      </Box>
      <Flex mt={2} justifyContent={"flex-end"} alignItems={"center"}>
        <Button onClick={onExportWhatsappQrCode} colorScheme="orange">
          Export
        </Button>
      </Flex>
    </>
  );
}

function WhatsAppWidgetCode() {
  const apiLinkWhatsapp =
    "https://api.insighto.ai/api/v1/webhook/whatsapp_webhook";
  const verificationToken = "insighto!2#$";
  const { onCopy: onCopyLink, hasCopied: isLinkCopied } =
    useClipboard(apiLinkWhatsapp);
  const { onCopy: onCopyToken, hasCopied: isTokenCopied } =
    useClipboard(verificationToken);

  return (
    <Box>
      <Box fontSize={"md"} marginBlock={3}>
        <Text>
          Your WhatsApp webhook on Facebook Developers dashboard should be set
          as:
        </Text>
        <Text>Webhook url : </Text>
        <Box
          onClick={onCopyLink}
          p="2"
          borderWidth="1px"
          borderRadius="md"
          _hover={{ cursor: "pointer", backgroundColor: "gray.50" }}
        >
          <Text>{apiLinkWhatsapp}</Text>
        </Box>
        <Text fontSize="12px" color="gray.500" textAlign="left">
          {isLinkCopied ? "Code Copied!" : "Click to Copy Link"}
        </Text>
        <Text>Token :</Text>
        <Box
          onClick={onCopyToken}
          p="2"
          borderWidth="1px"
          borderRadius="md"
          _hover={{ cursor: "pointer", backgroundColor: "gray.50" }}
        >
          <Text>{verificationToken}</Text>
        </Box>
        <Text fontSize="12px" color="gray.500" textAlign="left">
          {isTokenCopied ? "Code Copied!" : "Click to Copy Token"}
        </Text>
      </Box>
    </Box>
  );
}
