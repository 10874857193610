import { marked } from "marked";
import './MessageText.css'
import { useEffect, useRef } from "react";
/**
 *
 * @param {{text : string}} param0
 * @returns
 */
const MessageText = ({ text }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = marked.parse(text);
    }
  }, []);
  return <div ref={ref} className="message__text"></div>;
};

export default MessageText;