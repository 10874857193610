import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import { fetchController } from "../../utils/FetchController/fetchController";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import General from "./General";
import Sources from "./Sources";
import { getHostDomain } from "../../utils/utils";

const SingleAssistant = () => {
  const baseUrl = getHostDomain();
  const { uuid } = useParams();
  const [loading, setLoading] = useState(true);
  const endpoint = `/api/v1/assistant/${uuid}?assistant=${uuid}`;
  const urlHit = baseUrl + endpoint;
  const [assistantData, setAssistantData] = useState();
  const [tab, setTab] = useState("General");

  const fetchAnalyticsData = async () => {
    setLoading(true);
    try {
      const getData = await fetchController(urlHit, "GET");
      setAssistantData(getData);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
      fetchAnalyticsData();
  }, []);
  return (
    <Layout>
     <Box>
        <Tabs>
          <TabList
            onClick={(e) => {
              setTab(e.target.innerText);
            }}
          >
            <Tab>General</Tab>
            <Tab>Sources</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Box mt={4}>
                <General
                assistantData={assistantData}
                />
              </Box>
            </TabPanel>
            <TabPanel>
              <Box mt={4}>
                <Sources
                assistantData={assistantData}
                
                />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
};

export default SingleAssistant;
