/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import Table from "../Tables/Table";
import { Box } from "@chakra-ui/react";
import { getHostDomain } from "../../utils/utils";

const Sources = ({ assistantData }) => {
  const baseUrl = getHostDomain();
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);

  const hitFetch = async () => {
    try {
      const endpoint = `/api/v1/assistant/${assistantData?.data?.id}/list_data_sources`;
      const urlHit = baseUrl + endpoint;
      const getData = await fetchController(urlHit, "GET");
      if (getData?.data) {
        const heads = ["name", "description"];
        const columns = heads.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        }));
        setTableData(getData.data.items);
        setHeads(columns);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (assistantData?.data?.id) {
      hitFetch();
    }
  }, [assistantData]);
  return (
    <Box width="50%">
      <Table columns={heads} data={tableData} />
    </Box>
  );
};

export default Sources;
