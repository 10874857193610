import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Textarea,
  Tooltip,
  Select
} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
// import Select from "react-select";
import { fetchController } from "../../utils/FetchController/fetchController";
import { showToast } from "../../utils/Toast/Toast";
import { toast } from "react-toastify";
import CopyToClipboardButton from "../../utils/CopyToClipboard/CopyToClipboard";
import CustomSelect from "../../utils/CustomSelect/CustomSelect";
import { getHostDomain } from "../../utils/utils";

const AddApiKey = ({ isOpen, onOpen, onClose, fetchApiKeyList, activeTab, buttonColorScheme, textColor }) => {
  const baseUrl = getHostDomain();
  const [isLoading, setIsLoading] = useState(false);
  const [keyRoleOptions, setKeyRoleOptions] = useState([]);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const finalRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();
  const [keyRole, setKeyRole] = useState(null);
  const [files, setFiles] = useState([]);
  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const endpoint = "/api/v1/apikey";
      const urlHit = baseUrl + endpoint;

      const body = {
        org_id: null,
        name: data.name,
        description: data.description,
        api_key_role: data.keyRole,
        secret_key: "string",
        created_at: null,
        last_used: null,
      };
      const getData = await fetchController(urlHit, "POST", body);

      if (getData?.message) {
        setApiKey(getData.data.secret_key);
        showToast(getData?.message, "success");
        setIsLoading(false);
      } else {
        showToast("Oops! Something went wrong.", "error");
      }
    } catch (error) {
      toast.error(error);
    } finally {
      const data = await fetchApiKeyList();
      if (data) {
        setIsLoading(false);
      }
    }
  };

  const loadApiKeyRoleOption = async () => {
    try {
      const res = await fetchController(
        baseUrl + `/api/v1/apikeyrole/list`,
        "GET"
      );
      let options = res?.data?.items.map((val, index) => {
        return {
          label: val.name,
          value: val.id,
        };
      });
      setKeyRoleOptions(options);
    } catch (error) { }
  };

  useEffect(() => {
    if (activeTab === "api-keys") {
      loadApiKeyRoleOption();
    }
  }, [activeTab]);
  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setValue("name", null);
          setValue("keyRole", null);
          setValue("description", null);
          setApiKey(null);
        }}
      >
        <ModalOverlay />
        <ModalContent height="auto" width="95%" maxWidth="650px">
          <ModalHeader color={textColor}>API Key</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box flex="1" mb="4">
              <Input
                placeholder="API KEY"
                hidden={true}
                disabled
              // {...register("name")}
              />
            </Box>
            {apiKey && (
              <>
                <Flex mb={10} width={"100%"} direction={"column"} gap={2}>
                  <Box flex={1}>
                    <Text as={"b"} mb={3}>
                      The API Key will only be shown once. Please note it down
                      safely.
                    </Text>
                  </Box>
                  <Box flex={1}>
                    <CopyToClipboardButton text={apiKey} buttonColorScheme={buttonColorScheme} />
                  </Box>
                </Flex>
              </>
            )}
            {!apiKey && (
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack
                    direction="row"
                    spacing={4}
                    mb="4"
                    width={"100%"}
                    justifyContent={"space-between"}
                  >
                    <Box flex="1">
                      <Input
                        placeholder="Name"
                        {...register("name", { required: true })}
                      />
                      {errors.name && (
                        <p style={{ color: "red", fontSize: "11px" }}>
                          Name is required
                        </p>
                      )}
                    </Box>
                    <Box flex="1">
                      {/* <Select
                        placeholder="Key role"
                        {...register("keyRole", { required: true })}
                        options={keyRoleOptions}
                        isSearchable={false}
                        onChange={(selectedOption) => {
                          setValue("keyRole", selectedOption?.value || null, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                          setKeyRole(selectedOption?.value || null);
                        }}
                      /> */}
                     

                      <CustomSelect
                        placeholder="Key role"
                        // value={seletedAddon?.value}
                        {...register("keyRole", { required: true })}
                        onChange={(val) => {
                          setValue("keyRole", val.value || null, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                          setKeyRole(val?.value || null);
                        }}
                        options={keyRoleOptions}
                      />


                      {errors.keyRole && (
                        <p style={{ color: "red", fontSize: "11px" }}>
                          Key role is required
                        </p>
                      )}
                    </Box>
                  </Stack>
                  <Textarea
                    placeholder="only for your reference, not used as part of the prompt"
                    {...register("description", { required: true })}
                    resize="vertical"
                  />
                  {errors.description && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Description is required
                    </p>
                  )}

                  {/* Submit button */}
                  <Button
                    isDisabled={isLoading}
                    width="80px"
                    colorScheme={buttonColorScheme}
                    type="submit"
                    mt="4"
                  >
                    {isLoading ? <Spinner size="sm" /> : "Submit"}
                  </Button>
                </form>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};


export default AddApiKey;
