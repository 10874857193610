import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { BiSupport } from "react-icons/bi";
import { FiExternalLink } from "react-icons/fi";
import { IoHelpCircleOutline } from "react-icons/io5";
import { RiDiscordLine } from "react-icons/ri";

export default function HelpMenu({ agency, navSize }) {
  const onClickDiscord = () => {
    window.open("https://discord.gg/3baqJDqGYQ", "_blank");
  };
  const onClickFreshDesk = () => {
    window.open(
      agency?.myDetails?.agency_id
        ? "https://insightoai.freshdesk.com/support/solutions/folders/1070000339833"
        : "https://insightoai.freshdesk.com",
      "_blank"
    );
  };
  const onClickAffiliate = () => {
    window.open("https://affiliates.insighto.ai/program", "_blank");
  };

  const menuItems = [
    { label: "Discord", value: onClickDiscord, icon: <RiDiscordLine /> },
    { label: "Docs", value: onClickFreshDesk, icon: <BiSupport /> },
    { label: "Affiliate ", value: onClickAffiliate, icon: <FiExternalLink /> },
  ];

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<IoHelpCircleOutline />}
        textAlign={"start"}
        marginX={2}
      >
        {navSize !== "small" && "Help"}
      </MenuButton>
      <MenuList display={"flex"} justifyContent={"center"}>
        {menuItems.map((item, index) => (
          <MenuItem
            key={`${item.label}_${index}`}
            onClick={item.value}
            width="14"
            gap="1"
            flexDirection="column"
            justifyContent="center"
          >
            {item.icon}
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
