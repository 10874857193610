import { useEffect, useState } from "react";
import { getHostDomain } from "../../utils/utils";
import { fetchController } from "../../utils/FetchController/fetchController";

export default function useGettingStartedAgency({ agency }) {
  const [status, setStatus] = useState("loading");

  const [response, setResponse] = useState({
    data: {
      checks: [],
    },
    meta: {},
  });
  const fetchAgencyChecks = async () => {
    try {
      setStatus("loading");
      const domain = getHostDomain();
      const url = domain + `/api/v1/agency/${agency?.agencyData?.id}/checklist`;
      const agencyCheckResponse = await fetchController(url);
      setResponse(agencyCheckResponse);
      setStatus("idle");
    } catch (error) {
      setStatus("error");
    }
  };
  useEffect(() => {
    if (agency?.agencyData?.id) fetchAgencyChecks();
    else setStatus("notfound");
  }, [agency.agencyData]);
  return {
    response,
    isLoading: status === "loading",
    hasError: status === "error",
    isIdle: status === "idle",
    status,
    refresh: fetchAgencyChecks,
  };
}
