import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useSteps,
  Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AuthorizationBodyForm from "./AuthorizationBodyForm";
import DisplayBanner from "./DisplayBanner";
import ToolConfigurationBodyForm, {
  systemToolsModal,
} from "./ToolConfigurationBodyForm";
import ToolFunctionsBody from "./ToolFunctionsBody";
import ToolUserStepperHeader from "./ToolUserStepperHeader";
import useToolUser from "./useToolUser";
import useExternalAuth from "./useExternalAuth";
export default function ToolUserModal({ isOpen, onClose, tool }) {
  const toolUserForm = useForm();
  const { watch, reset } = toolUserForm;
  const { tool_user_id } = watch();
  const { status } = useToolUser({ tool_user_id, reset });
  const navigate = useNavigate();
  const closeModal = () => {
    navigate(`/tools`);
    onClose();
  };
  const steps = [
    {
      title: "Authenticate",
      description: "Authenticate your Tool",
      Body: AuthorizationBodyForm,
    },
    {
      title: "Functions",
      description: "Pick Function to use",
      Body: ToolFunctionsBody,
    },
    {
      title: "Configuration",
      description: "Configure your Tool",
      Body: ToolConfigurationBodyForm,
    },
  ];
  const filterNotAvailableSystemToolReactComponent = (_, index) =>
    systemToolsModal[tool?.attributes?.react_config_component] || index !== 2;
  const availableSystemToolsSteps = steps.filter(
    filterNotAvailableSystemToolReactComponent
  );
  const stepHook = useSteps({
    index: 0,
    count: availableSystemToolsSteps.length,
  });
  const externalAuthHook = useExternalAuth({
    auth_provider: tool?.tool_provider,
  });
  const Body = steps[stepHook.activeStep].Body;
  return (
    <Modal size={"2xl"} isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Setup your Tool</ModalHeader>
        <ModalCloseButton onClick={closeModal} />
        <ModalBody>
          <ToolUserStepperHeader
            steps={availableSystemToolsSteps}
            activeStep={stepHook.activeStep}
          />

          <Flex
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            marginBlock={2}
          >
            <DisplayBanner tool={tool} />
          </Flex>
        </ModalBody>
        <Body
          stepHook={stepHook}
          tool={tool}
          externalAuthHook={externalAuthHook}
          toolUserStatus={status}
          onClose={closeModal}
          toolUserForm={toolUserForm}
        />
        
      </ModalContent>
    </Modal>
  );
}
