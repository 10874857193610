import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function useWebcallWidgetForm({ widgetId }) {
  const defaultValues = {
    style_params: {
      header_name: "Insighto.AI",
      logo: "/bot.png",
      remove_branding: false,
      theme: {
        footer: {},
        magicBall: {
          bgColor: "#1865e9",
        },
        body: {},
      },
    },
  };
  const [widget, setWidget] = useState(null);
  const form = useForm({
    defaultValues,
  });
  const [status, setStatus] = useState("loading");
  const baseUrl = getHostDomain();

  const getWidgetByIdWithParams = async () => {
    try {
      const response = await fetchController(
        baseUrl + `/api/v1/widget/${widgetId}`
      );
      const widgetParams = {
        ...response.data,
        style_params: {
          ...defaultValues.style_params,
          ...response.data.style_params,
        },
      };
      setWidget(widgetParams);
      form.reset(widgetParams);
      setStatus("success");
    } catch (error) {
      setStatus("error");
      setWidget(null);
    }
  };
  useEffect(() => {
    getWidgetByIdWithParams();
  }, [widgetId]);
  const isWidgetLoading = status === "loading";
  const hasError = status === "errorr";

  return { widget, form, status, hasError, isWidgetLoading, getWidgetByIdWithParams };
}
