import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { LuMailX } from "react-icons/lu";
import { MdMarkEmailRead } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import { getHostDomain, validateRegex } from "../../utils/utils";
import { IoArrowBackCircleOutline } from "react-icons/io5";

export default function VerifyEmail() {
  const baseUrl = getHostDomain();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isValidate, setIsValidate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$";

  const setting = useContext(SettingContext);

  const verifyEmail = async () => {
    try {
      setLoading(true);
      const response = await fetchController(
        `${baseUrl}/api/v1/login/verify_email`,
        "POST",
        {},
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${searchParams.get("token")}`,
        }
      );
      const { access_token, refresh_token } = response.data;
      localStorage.setItem("token", access_token);
      localStorage.setItem("refreshToken", refresh_token);
    } catch (e) {
      toast.error(e?.response?.data?.detail || "Some error occurred");
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.get("token")) {
      verifyEmail();
    }
    if (!searchParams.get("token")) {
      setError(true);
      setLoading(false);
    }
  }, []);

  const handleRedirect = () => {
    navigate("/dashboard");
    setting.loadUsersDetails();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsValidate(true);
    if (!email || !validateRegex(emailPattern, email)) return;

    try {
      setIsSubmitting(true);
      await fetchController(
        `${baseUrl}/api/v1/login/verify_email_request`,
        "POST",
        {
          email,
        },
        {
          "Content-Type": "application/json",
        }
      );
      toast.success("Email sent successfully");
      navigate("/login")
    } catch (e) {
      console.error(e);
      toast.error(e?.message || "Some error occurred");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Flex
      gap={4}
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      p={6}
      height={"100vh"}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          {error ? (
            <>
              <LuMailX fontSize="40px" color="red" />
              <Heading size="xl">Email verification failed</Heading>
              <Text color={"gray.500"} opacity={0.8}>
                Please try again.
              </Text>
              <form onSubmit={onSubmit} style={{ width: "30%" }}>
                <Flex direction={"column"} gap={6}>
                  <FormControl
                    isInvalid={
                      (isValidate && !email) ||
                      (isValidate && !validateRegex(emailPattern, email))
                    }
                  >
                    <Input
                      placeholder={"Enter your email"}
                      type="email"
                      bgColor={"white"}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {isValidate &&
                      (!email ? (
                        <FormErrorMessage>Email is required</FormErrorMessage>
                      ) : (
                        !validateRegex(emailPattern, email) && (
                          <FormErrorMessage>
                            Invalid email address
                          </FormErrorMessage>
                        )
                      ))}
                  </FormControl>

                  <Button
                    type="submit"
                    colorScheme={"blue"}
                    isLoading={isSubmitting}
                    w={"100%"}
                  >
                    Submit
                  </Button>
                </Flex>
              </form>

              <Flex
                alignItems={"center"}
                gap={1}
                color={"blue.500"}
                fontWeight="bold"
                cursor={"pointer"}
                fontSize={"14px"}
                onClick={() => navigate("/login")}
              >
                <IoArrowBackCircleOutline fontSize={"18px"} />
                Back to Login
              </Flex>
            </>
          ) : (
            <>
              <MdMarkEmailRead fontSize={"40px"} color="green" />
              <Heading size="xl">Email Verified</Heading>
              <Text color={"gray.500"} opacity={0.8}>
                Congratulations! your email account has been verified
                successfully.
              </Text>
              <Button colorScheme="blue" onClick={handleRedirect}>
                Continue to the App
              </Button>
            </>
          )}
        </>
      )}
    </Flex>
  );
}
