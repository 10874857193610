import { Alert, AlertIcon } from "@chakra-ui/react";

export default function AlertPayment() {
  return (
    <Alert status="info">
      <AlertIcon />
      Please add your payment method in order to activate any "add-on”
    </Alert>
  );
}
