import { createContext, useEffect, useReducer, useState,useContext } from "react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { initialState, settingReducer, userActivatedPlan } from "./reducer";
import AuthContext from "../../Auth/AuthContext";
import { getHostDomain } from "../../../utils/utils";

export const SettingContext = createContext();

const SettingProvider = ({ children }) => {
  const baseUrl = getHostDomain();
  const [state, dispatch] = useReducer(settingReducer, initialState);
  const [loading, setLoading] = useState(true);
  const { getUserData, setIsLoading } = useContext(AuthContext);
  const loadUsersDetails = async () => {
    try {
      const userDetailsRes = await getUserData();
      localStorage.setItem(
        "current_active_billing_plan_id",
        userDetailsRes.data.active_billing_plan_id
      );
      const billingPlanRes = await fetchController(
        baseUrl +
          `/api/v1/billing/effective_billing_plan`,
        "GET"
      );
      dispatch({ type: userActivatedPlan, payload: billingPlanRes.data });
    } catch (error) {
      console.log(
        "Error occurred while loading user details in SettingProvider"
      );
    } finally {
      setLoading(false)
    }
  };

  const accessToken = localStorage.getItem("token");
  useEffect(() => {
    const fetchData = async () => {
      if (accessToken) {
        loadUsersDetails();
      } else {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [accessToken]);

  return (
    <SettingContext.Provider value={{ state, dispatch, loadUsersDetails, loading }}>
      {children}
    </SettingContext.Provider>
  );
};

export default SettingProvider;
