import React from "react";
import "./AudioPlayback.css";
/**
 *
 * @param {string} audio
 * @returns {string}
 */
function getBase64(audio) {
  let inputString = audio;
  if (inputString.startsWith("b'")) {
    inputString = inputString.slice(2);
  }
  if (inputString.endsWith("'")) {
    inputString = inputString.slice(0, -1);
  }
  inputString = inputString.replace(/\\/g, "\\\\");
  return inputString;
}
/**
 *
 * @param {{audioBinary :string}} param0
 * @returns
 */
const AudioPlayback = ({ audioBinary }) => {
  const src = "data:audio/webm;base64," + getBase64(audioBinary);
  return audioBinary ? (
    <audio className="audio__play"  autostart="false"  controls>
      <source src={src} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  ) : null;
};

export default AudioPlayback;