import {
  Box,
  Button,
  Flex,
  IconButton,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { IoPause, IoPlay } from "react-icons/io5";

export default function PreviewVoices({ ttsList }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [voices, setVoices] = useState([]);
  const [status, setStatus] = useState("idle");
  const loading = status === "loading";
  const [selectedVoice, setSelectedVoice] = useState();
  const [currentAudio, setCurrentAudio] = useState(null);
  const tts = ttsList
    .filter((tts) => tts.voicetts_provider === "elevenlabs")
    .map((tts) => tts.voice);
  useEffect(() => {
    (async () => {
      setStatus("loading");
      const { data } = await axios.get("https://api.elevenlabs.io/v1/voices");
      setVoices(data.voices.filter((voice) => tts.includes(voice.voice_id)));
      setStatus("idle");
    })();
  }, []);
  const onClickPlayOfVoiceItem = (voiceItem) => {
    if (currentAudio) currentAudio.pause();
    const audio = new Audio(voiceItem.preview_url);
    audio.onpause = () => {
      onPauseOfVoiceItem();
    };
    audio.play();
    setSelectedVoice(voiceItem);
    setCurrentAudio(audio);
  };
  const onPauseOfVoiceItem = () => {
    if (currentAudio) currentAudio.pause();
    setSelectedVoice(undefined);
  };
  return (
    <>
      <Button onClick={onOpen} size={"xs"}>
        Preview
      </Button>
      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Preview Premium Voices</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH={500} overflowY={"auto"} pb={6}>
            {loading ? (
              <Flex justifyContent={"center"} alignItems={"center"}>
                <Spinner />
              </Flex>
            ) : (
              <List>
                {voices.map((voice) => (
                  <VoiceItem
                    voice={voice}
                    selectedVoice={selectedVoice}
                    key={voices.voice_id}
                    onPauseOfVoiceItem={onPauseOfVoiceItem}
                    onClickPlayOfVoiceItem={onClickPlayOfVoiceItem}
                  />
                ))}
              </List>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function VoiceItem({
  voice,
  onClickPlayOfVoiceItem,
  selectedVoice,
  onPauseOfVoiceItem,
}) {
  return (
    <ListItem
      bg={selectedVoice?.voice_id === voice.voice_id ? "gray.100" : undefined}
      borderRadius={"xl"}
      border={"1px solid lightgray"}
      p={2}
      marginBlock={3}
      key={voice.voice_id}
    >
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Box>
          <Text fontWeight={"bold"} fontSize={"md"}>
            {voice.name}
          </Text>
          <Text color={"gray"}>
            {voice.labels.gender}, {voice.labels.age}, {voice.labels.accent},{" "}
            {voice.labels["use case"]}
          </Text>
        </Box>
        {selectedVoice?.voice_id === voice.voice_id ? (
          <IconButton icon={<IoPause />} onClick={onPauseOfVoiceItem} />
        ) : (
          <IconButton
            icon={<IoPlay />}
            onClick={() => onClickPlayOfVoiceItem(voice)}
          />
        )}
      </Flex>
    </ListItem>
  );
}
