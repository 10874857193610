import { useForm } from "react-hook-form";

export default function useNodeForm({ operations, onCloseForm }) {
  const form = useForm({
    defaultValues: {
      type: "",
    },
  });
  const onSubmit = (values) => {
    const onSave = operations[values.id ? "onEditNode" : "onAddNewNode"];
    onSave(values);
    form.reset();
    onCloseForm();
  };
  return { form, onSubmit };
}
