import { Box, Flex, Stack } from "@chakra-ui/react";
import MessageItem from "./MessageItem";
import { MdOutlineSupportAgent } from "react-icons/md";
import { FaRobot } from "react-icons/fa";

export default function Chats({ messages }) {
  return (
    <Stack>
      {messages.map((messageItem, index) => (
        <Box key={index}>
          <Flex
            justifyContent={
              messageItem.sentBy === "agent" ||
              messageItem.sentBy === "assistant"
                ? "flex-start"
                : "flex-end"
            }
            alignItems={"center"}
          >
            <Box>
              {messageItem.sentBy === "agent" ? (
                <MdOutlineSupportAgent size={20} />
              ) : messageItem.sentBy === "assistant" ? (
                <FaRobot size={20} />
              ) : null}
            </Box>
            <MessageItem messageItem={messageItem} />
          </Flex>
        </Box>
      ))}
    </Stack>
  );
}
