import { FormControl } from "@chakra-ui/react";
import Select from "react-select";
import FormLabelWithInfoTooltip from "./FormLabelWithInfoTooltip";
export default function SelectPhoneNumber({ watch, phoneNumbers, setValue }) {
  return (
    <FormControl>
      <FormLabelWithInfoTooltip
        label={"Select phone number "}
        tip={"Your users will receive call from this number"}
      />
      <Select
        options={phoneNumbers.map((phoneNumber) => ({
          label: phoneNumber.friendly_name,
          value: phoneNumber.sid,
        }))}
        onChange={(selected) => {
          setValue("sid", selected.value);
          setValue("phone_number", selected.label);
        }}
        placeholder="Select phone number"
        value={phoneNumbers
          .map((phoneNumber) => ({
            label: phoneNumber.friendly_name,
            value: phoneNumber.sid,
          }))
          .find((phoneNumber) => phoneNumber.value === watch("sid"))}
      />
    </FormControl>
  );
}
