import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useClipboard,
  useColorModeValue,
} from "@chakra-ui/react";
export default function LogJsonModal({ selectedJson, isOpen, onClose }) {
  const strigifiedJson = JSON.stringify(selectedJson.json, null, 2);
  const color = useColorModeValue("gray.50", "gray.600");
  const copiedColor = useColorModeValue("gray.100", "gray.700");
  const { onCopy, hasCopied } = useClipboard(strigifiedJson);
  return (
    <Modal scrollBehavior="inside" size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{selectedJson.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          overflow={"scroll"}
          onClick={onCopy}
          cursor={"pointer"}
          border={"1px solid lightgray"}
          bg={hasCopied ? copiedColor : color}
          title="Click to copy"
        >
          <pre
            style={{
              borderRadius: "3px",
              backgroundColor: color,
              padding: "5px",
              fontSize: "small",
            }}
          >
            {strigifiedJson}
          </pre>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
