import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import Select from "react-select";

export default function LLmModel({
  editData,
  watch,
  register,
  setValue,
  errors,
}) {
  const baseUrl = getHostDomain();

  const [llmModels, setLlmModels] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const responseLLms = await fetchController(
          baseUrl + "/api/v1/llmmodel/list"
        );

        const llmModelOptions = responseLLms.data.items
          .filter((llmModel) =>
            llmModel.attributes?.supported_assistant_types.includes(
              watch("assistant_type")
            )
          )
          .map((llmModel) => ({
            value: llmModel.llm_model_label,
            label: llmModel.name,
            supportedAssistantTypes:
              llmModel.attributes?.supported_assistant_types,
          }));

        setLlmModels(llmModelOptions);

        if (editData) {
          const selectedLlm = llmModelOptions.find(
            (llm) => llm.value === editData.llm_model
          );
          if (selectedLlm) setValue("llmModel", selectedLlm);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [watch("assistant_type"), editData]);

  return (
    <FormControl isInvalid={errors.llmModel}>
      <FormLabel htmlFor="llmModel">LLM Model</FormLabel>
      <Select
        placeholder="Select LLM Model"
        value={watch("llmModel")}
        isLoading={loading}
        options={llmModels}
        {...register("llmModel", {
          required: "LLM Model is required",
        })}
        onChange={(selectedOption) => {
          setValue("llmModel", selectedOption || null, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }}
      />
      {errors.llmModel && (
        <FormErrorMessage>{errors.llmModel.message}</FormErrorMessage>
      )}
    </FormControl>
  );
}
