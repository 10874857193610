import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { InlineWidget } from "react-calendly";

export default function CalendlyWidgetModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} size={"6xl"} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Schedule Demo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Get Started</Text>
          <InlineWidget url="https://calendly.com/solutions-insighto/30min?month=2024-11" />
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
