import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { fetchController } from "../../../utils/FetchController/fetchController";

import WebhookLogsList from "./WebhookLogsList";
import WebhookLog from "./WebhookLog";
import { getHostDomain } from "../../../utils/utils";
export default function WebhookLogsModal({
  isOpen,
  onClose,
  selectedWebhook,
  table,
}) {
  const baseUrl = getHostDomain();
  const [logsResponse, setLogsResponse] = useState({
    items: [],
    pages: 0,
    page: 0,
  });
  const [status, setStatus] = useState("idle");
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (table === "webhooks" && selectedWebhook) {
      (async () => {
        setWebhookLog(null);
        setStatus("loading");
        const response = await fetchController(
          baseUrl +
            `/api/v1/outbound_webhook/${selectedWebhook.id}/logs?page=${page}`
        );
        setLogsResponse({
          items: response.data.items,
          page: response.data.page,
          pages: response.data.pages,
        });
        setStatus("idle");
      })();
    }
  }, [selectedWebhook, page]);
  const [webhookLog, setWebhookLog] = useState(null);
  const [selectedWebhookId, setSelectedWebhookId] = useState(null);
  const selectWebhook = useCallback(async (webhookId) => {
    try {
      setStatus("webhookLogLoading");
      setSelectedWebhookId(webhookId);
      const response = await fetchController(
        baseUrl +
          `/api/v1/outbound_webhook/WebhookLog/${webhookId}`
      );
      setWebhookLog(response.data);
    } catch (error) {
      setWebhookLog(null);
      setSelectedWebhookId(null);
    } finally {
      setStatus("idle");
    }
  }, []);
  function CenteredSpinner() {
    return (
      <Flex justifyContent={"center"} alignItems={"center"}>
        <Spinner />
      </Flex>
    );
  }
  const logLoading = status === "webhookLogLoading";
  const isFirstPage = logsResponse.page <= 1;
  const hasReachedLastPage = logsResponse.page >= logsResponse.pages;
  return (
    <Modal
      size={"5xl"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Webhook Logs</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid
            gap={3}
            gridTemplateColumns={(webhookLog || logLoading) && "1fr 1fr"}
          >
            {status === "loading" ? (
              <CenteredSpinner />
            ) : (
                <WebhookLogsList
                  logsResponseItems={logsResponse.items}
                  selectWebhook={selectWebhook}
                  selectedWebhookId={selectedWebhookId}
                />
            )}
            {logLoading ? (
              <CenteredSpinner />
            ) : webhookLog ? (
              <Box maxH={"65svh"} overflowY={"auto"}>
                <WebhookLog webhookLog={webhookLog} />
              </Box>
            ) : null}
          </Grid>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup justifyContent={"center"} alignItems={"center"} gap={3}>
            <Button
              size={"sm"}
              isDisabled={isFirstPage}
              onClick={() => {
                setPage((prev) => prev - 1);
                setWebhookLog(null);
              }}
            >
              Previous
            </Button>
            {logsResponse.items.length ? (
              <code>
                {logsResponse.page} / {logsResponse.pages}
              </code>
            ) : null}
            <Button
              size={"sm"}
              isDisabled={hasReachedLastPage}
              onClick={() => {
                setPage((prev) => prev + 1);
                setWebhookLog(null);
              }}
            >
              Next
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
