import { FormControl, FormLabel, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
import Select from "react-select";

export default function SelectConversationSync({ watch, setValue }) {
  const baseUrl = getHostDomain();
  const { attributes = {} } = watch();
  const { tool_id, conversation_syncs = [] } = attributes;

  const [CRMOptions, setCRMOptions] = useState([]);
  const [toolConfigurationOptions, setToolConfigurationOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCRMList = async () => {
    try {
      setLoading(true);
      const response = await fetchController(
        baseUrl + "/api/v1/tool/tools/conversation_sync/list",
        "GET"
      );
      const options = response.data.map((opt) => ({
        value: opt.id,
        label: opt.name,
      }));

      setCRMOptions(options);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCRMList();
  }, []);

  const getToolConfigurationList = async (toolId) => {
    try {
      setLoading(true);
      const response = await fetchController(
        baseUrl + `/api/v1/tool/${toolId}/LinkToolUser`,
        "GET"
      );
      const options = response.data?.items.map((opt) => ({
        value: opt.id,
        label: opt.name,
      }));

      setToolConfigurationOptions(options);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tool_id) {
      getToolConfigurationList(tool_id);
    }
  }, [tool_id]);

  const handleCRMSelect = ({ value }) => {
    setValue("attributes.tool_id", value);
  };

  return (
    <Stack dir="row" spacing={2} py={2}>
      <FormControl>
        <FormLabel>Supported CRM</FormLabel>
        <Select
          placeholder="Select CRM"
          value={CRMOptions.find((opt) => opt.value === tool_id)}
          options={CRMOptions}
          onChange={handleCRMSelect}
          isLoading={loading}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Tool Configuration to use</FormLabel>
        <Select
          placeholder="Select Tool Configuration"
          value={toolConfigurationOptions.find(
            (opt) => opt.value === conversation_syncs[0]
          )}
          options={toolConfigurationOptions}
          onChange={({ value }) => {
            setValue("attributes.conversation_syncs", [value]);
          }}
          isLoading={loading}
        />
      </FormControl>
    </Stack>
  );
}
