import { Box, Flex, Heading } from "@chakra-ui/react";
import { BsTools } from "react-icons/bs";

export default function ToolFunctionNotFound({ text="Tool functions not found", iconSize=100 }) {
  return (
    <Box height={"50svh"}>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"100%"}
      >
        <BsTools size={iconSize} />
        <Heading size='md'>{text}</Heading>
      </Flex>
    </Box>
  );
}
