import './TextAreaField.css'
export default function TextAreaField({ field }) {
  return (
    <div className="field text__areaField">
      <div className="field__label">
        <span>{field.label}</span>
        {field.required ? <span className="required">*</span> : null}
      </div>
      <textarea placeholder={field.description} name={field.name}/>
    </div>
  );
}
