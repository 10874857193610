import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";
export default function WebhookSelect({ webhook_id, onChangeWebhook }) {
  const baseUrl = getHostDomain();
  const [webhooks, setWebhooks] = useState([]);
  const getWebhooks = useCallback(async () => {
    const response = await fetchController(
      baseUrl + "/api/v1/outbound_webhook/list"
    );
    setWebhooks(response.data?.items);
  });
  useEffect(() => {
    getWebhooks();
  }, []);
  const options = webhooks.map((webhook) => ({
    label: webhook.name,
    value: webhook.id,
  }));
  return (
    <Select
      name="webhook_id"
      onChange={onChangeWebhook}
      value={options.find((option) => option.value == webhook_id)}
      options={options}
      isClearable={true}
    />
  );
}
