import { Box, FormControl, FormLabel, Stack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
export default function RealtimeOpenAiFields({ setValue, watch }) {
  const voiceOptions = [
    { value: "alloy", label: "Alloy" },
    { value: "echo", label: "Echo" },
    { value: "shimmer", label: "Shimmer" },
  ];
  return (
    <Stack marginBlock={2} spacing={2}>
      <FormControl>
        <FormLabel>Voice</FormLabel>
        <Select
          onChange={({ value }) => setValue("attributes.openai_voice", value)}
          value={voiceOptions.find(
            (v) => v.value === watch("attributes.openai_voice")
          )}
          options={voiceOptions}
        />
      </FormControl>
      <Box textDecoration={"underline"}>
        <Link
          target="_blank"
          to="https://platform.openai.com/docs/guides/text-to-speech/quickstart"
        >
          Voice preview
        </Link>
      </Box>
    </Stack>
  );
}
