import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { BiSupport } from "react-icons/bi";
import useGettingStartedAgency from "./useGettingStartedAgency";
import { FaLightbulb } from "react-icons/fa";
import Loader from "../../utils/Loader/Loader";
import { GoOrganization } from "react-icons/go";
import { LuRefreshCcw } from "react-icons/lu";

function DocsButton() {
  return (
    <Button
      size={"sm"}
      colorScheme="teal"
      leftIcon={<BiSupport />}
      onClick={() => {
        window.open(
          "https://insightoai.freshdesk.com/support/solutions/folders/1070000339833",
          "_blank"
        );
      }}
    >
      Docs
    </Button>
  );
}

function AgencyCheckboxDescription(props) {
  return (
    <Tr>
      <Td w={10}>
        <FaLightbulb color={props.status} />
      </Td>
      <Td whiteSpace={"wrap"}>{props.description}</Td>
    </Tr>
  );
}

function CategoryStatusTable(props) {
  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Status</Th>
            <Th>Description</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.categoryStatusList.map(({ status, description }, index) => (
            <AgencyCheckboxDescription
              key={index}
              status={status}
              description={description}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
function AgencyCheckTableItem(props) {
  const headingBg = useColorModeValue("gray.100", "gray.700");
  const [category, categoryStatusList] = props.agencyCheckEntry;
  return (
    <Box border={"1px solid lightgray"} borderRadius={"md"}>
      <Box bg={headingBg} paddingBlock={2}>
        <Heading
          textTransform={"capitalize"}
          fontWeight={"bold"}
          textAlign={"center"}
          fontSize={"medium"}
        >
          {category}
        </Heading>
      </Box>
      <CategoryStatusTable categoryStatusList={categoryStatusList} />
    </Box>
  );
}

function AgencySetupHasError({ status }) {
  const bg = useColorModeValue("gray.100", "gray.700");

  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      flexDir={"column"}
      h={"50svh"}
    >
      <Flex
        borderRadius={"full"}
        bg={bg}
        p={3}
        justifyContent={"center"}
        alignItems={"center"}
        flexDir={"column"}
      >
        <GoOrganization size={50} />
      </Flex>
      <Heading textAlign={"center"} fontSize={"xl"}>
        {status === "notfound"
          ? "Start setting up your Agency by going to Branding tab."
          : "Some error occured"}
      </Heading>
    </Flex>
  );
}

export default function GettingStarted({ agency }) {
  const { response, status, isLoading, hasError, refresh } =
    useGettingStartedAgency({
      agency,
    });
  const checks = response.data.checks;
  const getAllCategoriesDescriptionsStatus = (acc, check) => {
    const currentCategoryStatusList = acc[check.category] || [];
    acc[check.category] = [...currentCategoryStatusList, check];
    return acc;
  };
  const categoryDescriptionStatusMap = checks.reduce(
    getAllCategoriesDescriptionsStatus,
    {}
  );

  const agencyChecksMapEntries = Object.entries(categoryDescriptionStatusMap);
  const renderAgencyCheckItem = (agencyCheckEntry, index) => (
    <AgencyCheckTableItem key={index} agencyCheckEntry={agencyCheckEntry} />
  );

  const isNotFound = status === "notfound";
  return (
    <Box>
      {isLoading ? (
        <Loader />
      ) : hasError || isNotFound ? (
        <AgencySetupHasError status={status} />
      ) : (
        <>
          <Flex
            justifyContent={"flex-end"}
            alignItems={"center"}
            marginBlock={2}
            gap={3}
          >
            <Button
              colorScheme="blue"
              size={"sm"}
              onClick={refresh}
              leftIcon={<LuRefreshCcw />}
            >
              Refresh
            </Button>
            <DocsButton />
          </Flex>
          <Grid gap={2}>
            {agencyChecksMapEntries.map(renderAgencyCheckItem)}
          </Grid>
          <Divider />
          <Box marginBlock={2}>
            <Legends />
          </Box>
        </>
      )}
    </Box>
  );
}

function Legends() {
  const legendStatusColorDescription = {
    green: "Setup is complete",
    yellow: "It will work but not ideal",
    red: "Needs your attention before making your Agency functional",
  };
  return (
    <Grid gap={2}>
      {Object.entries(legendStatusColorDescription).map(
        ([status, description], index) => (
          <Flex
            gap={3}
            justifyContent={"flex-start"}
            alignItems={"center"}
            key={index}
          >
            <FaLightbulb size={20} color={status} />
            <Text>{description}</Text>
          </Flex>
        )
      )}
    </Grid>
  );
}
