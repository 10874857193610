import React, { useState } from "react";

export default function usePage() {
  const [page, setPage] = useState(1);
  const onNextPage = () => setPage((prevPage) => prevPage + 1);
  const onPrevPage = () => setPage((prevPage) => prevPage - 1);
  const onSetPage =(currentPage)=> setPage(currentPage);
  return {
    onNextPage,
    onPrevPage,
    onSetPage,
    page
  }
}
