import { Stack, Text } from "@chakra-ui/react";

export default function FilterListByToolType({
  toolTypes,
  onSetTypeOfTool,
  selectedToolType,
  toolsList,
  search,
}) {
  function ToolType({ toolType }) {
    const totalByType = toolsList.filter(
      (tool) =>
        (toolType === "all" || toolType === tool.category) &&
        (!search ||
          tool.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    );
    return (
      <Text
        fontWeight={selectedToolType === toolType ? "bold" : undefined}
        onClick={() => onSetTypeOfTool(toolType)}
        cursor={"pointer"}
        _hover={{
          textDecoration: "underline",
        }}
        fontSize={"md"}
        textTransform={"uppercase"}
        key={toolType}
      >
        {`${toolType} (${totalByType.length})`}
      </Text>
    );
  }
  return (
    <Stack spacing={3}>
      <ToolType toolType={"all"} />
      {toolTypes.map((toolType, index) => (
        <ToolType toolType={toolType} key={index} />
      ))}
    </Stack>
  );
}
