import { Box, Text } from "@chakra-ui/react";

export default function MessageItem({ messageItem }) {
  return (
    <Box
      borderRadius={"md"}
      maxW={720}
      whiteSpace={"wrap"}
      p={2}
      color={
        messageItem.sentBy === "agent" || messageItem.sentBy === "assistant"
          ? "black"
          : "white"
      }
      bg={
        messageItem.sentBy === "agent" || messageItem.sentBy === "assistant"
          ? "gray.100"
          : "blue.300"
      }
    >
      <Text>{messageItem.message}</Text>
    </Box>
  );
}
