import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import Select from "react-select";
export default function NodeModal({ isOpen, onClose, nodeForm }) {
  const { form, onSubmit } = nodeForm;
  const node = nodeForm.form.watch();

  const nodeTypeOptions = [
    { value: "", label: "Both" },
    { value: "input", label: "Input" },
    { value: "output", label: "Output" },
  ];
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader>Step</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Step name</FormLabel>
                <Input {...form.register("data.label")} />
              </FormControl>
              <FormControl>
                <FormLabel>Type of step</FormLabel>
                <Select
                  options={nodeTypeOptions}
                  onChange={({ value }) => {
                    form.setValue("type", value);
                  }}
                  value={nodeTypeOptions.find(
                    (opt) => opt.value === node?.type
                  )}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button type="submit" colorScheme="blue">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
