import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import { LiaToolsSolid } from "react-icons/lia";

export default function DisplayBanner({ tool }) {
  return (
    <>
      <Flex
        justifyContent={"center"}
        gap={3}
        alignItems={"center"}
        width={"100%"}
      >
        {tool.logo_url ? (
          <Image src={tool.logo_url} width={20} alt={tool.logo_url} />
        ) : (
          <LiaToolsSolid size={50} />
        )}
        <Heading fontSize={"xl"}>{tool.name}</Heading>
      </Flex>
      <Text>{tool.description}</Text>
    </>
  );
}
