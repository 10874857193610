import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import CreateToolFunctionModal from "./CreateToolFunctionModal";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { Fragment, useEffect, useState } from "react";
import { BsTools } from "react-icons/bs";
import {
  AddIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EditIcon,
} from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import PropertyFields from "../PropertyFields";
import { getHostDomain } from "../../../utils/utils";

export default function ToolFunctionModal({
  isOpen,
  onClose,
  toolId,
  closeToolModal,
}) {
  const baseUrl = getHostDomain();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      method: "",
      endPoint: "",
      contentType: "",
      functionName: "",
      functionDescription: "",
      properties: [{ name: "", type: "", description: "", isRequired: null }],
    },
  });

  const {
    isOpen: isCreateToolFunctionOpen,
    onOpen: openCreateToolFunction,
    onClose: closeCreateToolFunction,
  } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [toolFunctions, setToolFunctions] = useState([]);
  const [selectedToolFunction, setSelectedToolFunction] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const getToolFunctions = async () => {
    try {
      setLoading(true);
      const response = await fetchController(
        baseUrl +
          `/api/v1/tool/${toolId}/tool_function/list`,
        "GET"
      );

      setToolFunctions(response?.data?.items);
      setSelectedToolFunction(response?.data?.items[0]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getToolFunctions();
  }, []);

  function autoFillFunctionConfig() {
    const propertiesObj = {
      ...selectedToolFunction?.details?.openai_spec?.function?.parameters
        ?.properties,
    };
    const propertiesRequiredList = [
      ...selectedToolFunction?.details?.openai_spec?.function?.parameters
        ?.required,
    ];
    const propertiesArr = Object.keys(propertiesObj).reduce(
      (acc, propertyName) => {
        acc.push({
          name: propertyName,
          type: propertiesObj[propertyName].type,
          description: propertiesObj[propertyName].description,
          isRequired: propertiesRequiredList.includes(propertyName),
        });
        return acc;
      },
      []
    );

    setValue("method", selectedToolFunction.details.method);
    setValue("endPoint", selectedToolFunction.details.endpoint);
    setValue("contentType", selectedToolFunction.details?.content_type || "");
    setValue("functionName", selectedToolFunction.name);
    setValue("functionDescription", selectedToolFunction.description);
    setValue("properties", propertiesArr);
  }

  useEffect(() => {
    if (selectedToolFunction) {
      autoFillFunctionConfig();
    }
  }, [selectedToolFunction]);

  const handleClose = () => {
    setSelectedToolFunction(null);
    closeToolModal();
    onClose();
  };

  function preparePayloadDetails(data) {
    let requiredPropertiesList = [];
    let formattedProperties = {};
    for (let property of data.properties) {
      let formattedName = property.name.replace(/\s+/g, "_").toLowerCase();
      if (property.isRequired) {
        requiredPropertiesList.push(formattedName);
      }
      formattedProperties = {
        ...formattedProperties,
        [formattedName]: {
          type: property.type,
          description: property.description,
        },
      };
    }

    let payload = {
      method: data.method,
      endpoint: data.endPoint,
      openai_spec: {
        type: "function",
        function: {
          name: data.functionName.replace(/\s+/g, "_").toLowerCase(),
          parameters: {
            type: "object",
            required: requiredPropertiesList,
            properties: {
              ...formattedProperties,
            },
          },
          description: data.functionDescription,
        },
      },
      content_type: data?.contentType,
    };
    if (data.method === "POST") {
      payload.content_type = data?.contentType;
      payload.dependencies = [];
    }
    return payload;
  }

  const onSubmit = async (data) => {
    try {
      const payloadDetails = preparePayloadDetails(data);

      const response = await fetchController(
        baseUrl +
          `/api/v1/toolfunction/${selectedToolFunction?.id}`,
        "PUT",
        {
          name: data.functionName,
          description: data.functionDescription,
          tool_function_type: "curl",
          details: payloadDetails,
          tool_id: toolId,
          is_enabled: true,
        }
      );
      toast.success("Tool function updated successfully");
      getToolFunctions();
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong");
    }
  };

  const totalPages = Math.ceil(toolFunctions?.length / itemsPerPage);

  const currentItems = toolFunctions?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={handleClose}
        blockScrollOnMount={false}
      >
        <ModalOverlay />

        <ModalContent maxWidth="1200px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader mt={6}>
              <Flex justifyContent={"space-between"}>
                <Text>Tool Functions</Text>
                <Button
                  colorScheme="blue"
                  onClick={openCreateToolFunction}
                  mr={4}
                >
                  Create Tool function
                </Button>
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {loading ? (
                <Flex justifyContent={"center"} alignItems={"center"}>
                  <Spinner />
                </Flex>
              ) : (
                <>
                  {currentItems?.length ? (
                    <Flex gap={3}>
                      <Stack
                        height={"70svh"}
                        overflowY={"auto"}
                        p={3}
                        width={"30%"}
                        gap={2}
                      >
                        {currentItems?.map((item, index) => (
                          <Card
                            variant={"filled"}
                            key={item.id + index}
                            cursor={"pointer"}
                            bg={
                              selectedToolFunction?.id === item?.id
                                ? "#3182ce"
                                : "#E8E8E8"
                            }
                            color={
                              selectedToolFunction?.id === item?.id
                                ? "white"
                                : "black"
                            }
                            onClick={() => setSelectedToolFunction(item)}
                          >
                            <CardBody>
                              <Text
                                fontSize={"md"}
                                textTransform={"capitalize"}
                              >
                                {item.name}
                              </Text>
                            </CardBody>
                          </Card>
                        ))}
                      </Stack>
                      <Box width={"70%"}>
                        <Card
                          height={"70svh"}
                          overflowY={"auto"}
                          variant={"elevated"}
                        >
                          <CardHeader
                            position={"sticky"}
                            top={0}
                            zIndex={100}
                            bgColor={"white"}
                          >
                            <Flex justifyContent={"space-between"}>
                              <Heading size="md">
                                Function Configuration
                              </Heading>
                              <Stack gap={2} direction={"row"}>
                                {isEditing && (
                                  <IconButton
                                    isRound={true}
                                    icon={<CheckIcon />}
                                    size={"sm"}
                                    onClick={() => setIsEditing(false)}
                                  />
                                )}
                                <IconButton
                                  isRound={true}
                                  size="sm"
                                  icon={<EditIcon />}
                                  onClick={() => setIsEditing(true)}
                                />
                              </Stack>
                            </Flex>
                          </CardHeader>
                          <CardBody>
                            <Flex gap={4} direction={"column"}>
                              <Stack width={"100%"}>
                                <FormLabel>Endpoint</FormLabel>
                                <Stack spacing={0} direction={"row"}>
                                  <Select
                                    borderRight={0}
                                    borderRightRadius={0}
                                    {...register("method")}
                                    width={"20%"}
                                    variant="filled"
                                    isDisabled={!isEditing}
                                  >
                                    <option value="GET">GET</option>
                                    <option value="POST">POST</option>
                                  </Select>

                                  <Input
                                    placeholder={"Enter endpoint"}
                                    {...register("endPoint", {
                                      required: "Endpoint is required",
                                    })}
                                    borderLeft={0}
                                    borderLeftRadius={0}
                                    isDisabled={!isEditing}
                                  />
                                </Stack>
                                {errors.endPoint && (
                                  <Text color={"red"}>
                                    {errors.endPoint.message}
                                  </Text>
                                )}

                                <FormControl isInvalid={errors.contentType}>
                                  <FormLabel>Content Type</FormLabel>
                                  <Select
                                    {...register("contentType", {
                                      required: "Content type is required",
                                    })}
                                    isDisabled={!isEditing}
                                  >
                                    <option value={""}>Select...</option>
                                    <option value="application/json">
                                      application/json
                                    </option>
                                    <option value="application/x-www-form-urlencoded">
                                      application/x-www-form-urlencoded
                                    </option>
                                  </Select>
                                  {errors.contentType && (
                                    <FormErrorMessage>
                                      {errors.contentType.message}
                                    </FormErrorMessage>
                                  )}
                                </FormControl>

                                <FormControl isInvalid={errors.functionName}>
                                  <FormLabel>Function Name</FormLabel>
                                  <Input
                                    placeholder={"Enter tool function name"}
                                    {...register("functionName", {
                                      required: "Function name is required",
                                    })}
                                    isDisabled={!isEditing}
                                  />
                                  {errors.functionName && (
                                    <FormErrorMessage>
                                      {errors.functionName.message}
                                    </FormErrorMessage>
                                  )}
                                </FormControl>

                                <FormControl
                                  isInvalid={errors.functionDescription}
                                >
                                  <FormLabel>Function Description</FormLabel>
                                  <Textarea
                                    placeholder={
                                      "Enter tool function description"
                                    }
                                    {...register("functionDescription", {
                                      required:
                                        "Function description is required",
                                    })}
                                    isDisabled={!isEditing}
                                  />
                                  {errors.functionDescription && (
                                    <FormErrorMessage>
                                      {errors.functionDescription.message}
                                    </FormErrorMessage>
                                  )}
                                </FormControl>
                              </Stack>

                              <Stack width={"100%"}>
                                <Box display={"flex"}>
                                  <FormLabel>Properties</FormLabel>
                                  <IconButton
                                    isRound={true}
                                    colorScheme="blue"
                                    variant="outline"
                                    aria-label="Add Field"
                                    icon={<AddIcon />}
                                    size={"xs"}
                                    title={"Add new field"}
                                    onClick={() => {
                                      setValue("properties", [
                                        ...getValues("properties"),
                                        {
                                          name: "",
                                          type: "",
                                          description: "",
                                          isRequired: true,
                                        },
                                      ]);
                                    }}
                                    isDisabled={!isEditing}
                                  />
                                </Box>

                                <Box>
                                  {watch("properties")?.map(
                                    ({ name }, index) => (
                                      <Fragment key={`${name}_${index}`}>
                                        <PropertyFields
                                          settings={{
                                            register,
                                            errors,
                                            watch,
                                            setValue,
                                          }}
                                          index={index}
                                          isEditing={isEditing}
                                        />
                                        <Divider />
                                      </Fragment>
                                    )
                                  )}
                                </Box>
                              </Stack>
                            </Flex>
                          </CardBody>
                        </Card>
                      </Box>
                    </Flex>
                  ) : (
                    <Stack
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={4}
                    >
                      <BsTools size={"70"} />
                      <Text fontSize="lg">Tool function not available</Text>
                    </Stack>
                  )}
                </>
              )}
            </ModalBody>
            <ModalFooter justifyContent={"space-between"} gap={2}>
              <ButtonGroup
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={3}
                width={"30%"}
                visibility={!currentItems?.length && "hidden"}
              >
                <IconButton
                  isRound={true}
                  size="sm"
                  isDisabled={currentPage === 1}
                  icon={<ChevronLeftIcon />}
                  onClick={() => handlePageChange(currentPage - 1)}
                />

                <code>
                  {currentPage} / {totalPages}
                </code>

                <IconButton
                  isRound={true}
                  size="sm"
                  isDisabled={currentPage === totalPages}
                  icon={<ChevronRightIcon />}
                  onClick={() => handlePageChange(currentPage + 1)}
                />
              </ButtonGroup>

              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                isDisabled={selectedToolFunction?.id ? false : true}
              >
                Update
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      {isCreateToolFunctionOpen && (
        <CreateToolFunctionModal
          isOpen={isCreateToolFunctionOpen}
          onClose={closeCreateToolFunction}
          toolId={toolId}
          loadToolFunction={getToolFunctions}
          preparePayloadDetails={preparePayloadDetails}
        />
      )}
    </>
  );
}
