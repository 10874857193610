import {
  Alert,
  AlertIcon,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import Loader from "../../utils/Loader/Loader";
import Layout from "../Layout/Layout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import Branding from "./Tabs/Branding";
import DomainPlan from "./Tabs/DomainPlan";
import RebillingPlan from "./Tabs/RebillingPlan";
import SubAccount from "./Tabs/SubAccount";
import GettingStarted from "./GettingStarted";

export default function Agency() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { state, loading } = useContext(SettingContext);
  const agency = useContext(AgencyContext);
  const { textColor, agencyData, themeColor, getAgencies } = agency;
  const tabs = [
    {
      value: "Getting Started",
      children: <GettingStarted agency={agency} />,
    },
    {
      value: "Branding",
      children: (
        <Branding tab={"Branding"} activeTab={activeTabIndex} agency={agency} />
      ),
    },
    {
      value: "Sub Account",
      children: <SubAccount tab={"Sub-Account"} activeTab={activeTabIndex} />,
    },
    {
      value: "Rebilling Plan",
      children: (
        <RebillingPlan tab={"Rebilling-Plan"} activeTab={activeTabIndex} />
      ),
    },
    {
      value: "Domain",
      children: (
        <DomainPlan
          tab={"Domain-Plan"}
          agency={agency}
          activeTab={activeTabIndex}
        />
      ),
    },
  ];
  useEffect(() => {
    getAgencies();
  }, []);
  return (
    <Layout>
      <Box minH={"81svh"}>
        {loading && agency.loading ? (
          <Loader />
        ) : (
          <Box border="1px" p="5" borderRadius="md" h="90vh" overflowY="auto">
            {!state?.credits?.agency ? (
              <Alert status="info">
                <AlertIcon />
                {"Please contact the sales team to enable Agency plan"}
              </Alert>
            ) : (
              <Tabs
                isLazy
                index={activeTabIndex}
                onChange={(index) => setActiveTabIndex(index)}
              >
                <TabList>
                  {tabs.map((tab, listIndex) => (
                    <Tab
                      key={listIndex}
                      isDisabled={!agencyData && tab.value !== "Branding"}
                      color={textColor}
                    >
                      {tab.value}
                    </Tab>
                  ))}
                </TabList>

                <TabPanels>
                  {tabs.map((tab, panelIndex) => (
                    <TabPanel key={panelIndex}>
                      <Box mt={4}>{tab.children}</Box>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            )}
          </Box>
        )}
      </Box>
    </Layout>
  );
}
