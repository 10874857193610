import "./CheckboxField.css";
export default function CheckboxField({ field }) {
  return (
    <div className="checkbox__field">
      <div
        style={{
          fontSize: field.privacy_content_url ? "small" : undefined,
        }}
        className="field__label"
      >
        <span>{field.type === "gdpr" ? field.description : field.label}</span>
        {field.required ? <span className="required">*</span> : null}
      </div>
      <input type="checkbox" />
      {field.privacy_content_url ? (
        <a
          style={{
            fontSize: "small",
            textDecoration: "underline",
          }}
          href={field.privacy_content_url}
        >
          {field.label}
        </a>
      ) : null}
    </div>
  );
}
