import React, { useContext } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import { useColorModeValue, useTheme } from "@chakra-ui/react";
import { AgencyContext } from "../../components/Settings/AgencyProvider/AgencyProvider";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       display: false,
//     },
//   },
//   animation: {
//     tension: {
//       from: 1,
//       to: 0.5,
//     },
//   },
//   scales: {
//     x: {
//       stacked: true,
//       type: "category",
//       grid: {
//         display: false,
//       },
//       ticks: {
//         color: 'green', 
//       },
//     },
//     y: {
//       beginAtZero: true,
//       stacked: true,
//       grid: {
//         color: "#e2e8f0",
        
//       },
//       ticks: {
//         color: 'green', 
//       },
//     },
//   },
// };

const WaveChart = ({ chats }) => {
  const labels = chats?.map((data) => moment(data.date).format("DD MMM"));
  const dataValues = chats.map((data) => data.count);
  const theme = useTheme();
  const labelsColor = useColorModeValue(theme.colors.gray[700],"white")
  const data = {
    labels,
    datasets: [
      {
        label: "Conversations per day",
        data: dataValues,
        borderColor: "#3182ce",
        fill: {
          target: "origin",
          below: (ctx) => {
            const gradient = ctx.chart.ctx.createLinearGradient(
              0,
              0,
              0,
              ctx.chart.height
            );
            gradient.addColorStop(0, "rgba(0, 128, 128, 1)");
            gradient.addColorStop(0.5, "rgba(0, 128, 128, 0.5)");
            gradient.addColorStop(1, "rgba(0, 128, 128, 0)");
            return gradient;
          },
        },
       
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    animation: {
      tension: {
        from: 1,
        to: 0.5,
      },
    },
    scales: {
      x: {
        stacked: true,
        type: "category",
        grid: {
          display: false,
        },
        ticks: {
          color: labelsColor, 
        },
      },
      y: {
        beginAtZero: true,
        stacked: true,
        grid: {
          color: "#e2e8f0",

        },
        ticks: {
          color: labelsColor, 
        },
      },
    },
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, cardBg: bg } = agency;

  return <Line options={options} data={data} style={{ backgroundColor: bg }}/>;
};

export default WaveChart;
 