import { useForm } from "react-hook-form";

export default function useSlotAvailabilityForm({ toolUserForm, onClose }) {
  const { watch } = toolUserForm;
  const { credentials = {} } = watch();
  const { configuration = {} } = credentials;
  const form = useForm({
    values : {
      start : "09:00",
      end : "17:00"
    }
  });

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const onSubmitAvailability = (data) => {
    const dayKey = `credentials.configuration.availability.${data.day}`;
    const slot = `${data.start}-${data.end}`;
    const slots = (configuration.availability || {})[data.day] || [];
    toolUserForm.setValue(dayKey, [...slots, slot]);
    form.reset({
      day: "",
      start: "",
      end: "",
    });
  };
  const onRemoveAvailability = (day, slotIndex) => {
    const dayKey = `credentials.configuration.availability.${day}`;
    const slots = (configuration.availability || {})[day] || [];
    toolUserForm.setValue(
      dayKey,
      slots.filter((_, index) => index !== slotIndex)
    );
  };
  const closeModal = () => {
    const availability = days.reduce((prev, day) => {
      const slots = (configuration.availability || {})[day] || [];
      prev[day] = slots;
      return prev;
    }, {});

    toolUserForm.setValue(
      `credentials.configuration.availability`,
      availability
    );
    onClose();
  };
  return {
    onRemoveAvailability,
    closeModal,
    onSubmitAvailability,
    days,
    form,
  };
}
