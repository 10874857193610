import React from "react";
import { Box } from "@chakra-ui/react";

const CardRibbon = ({ text, bgColor = "blue.500", textColor = "#fff" }) => {
  return (
    <Box
      h={"88px"}
      position={"absolute"}
      overflow={"hidden"}
      top={"-3px"}
      left={"-3px"}
    >
      <Box
        fontWeight={"bold"}
        fontSize={"8px"}
        color={textColor}
        textAlign={"center"}
        transform={"rotate(-45deg)"}
        position={"relative"}
        padding={"7px 0"}
        top={"15px"}
        left={"-30px"}
        width={"120px"}
        bg={bgColor}
      >
        {text}
      </Box>
    </Box>
  );
};

export default CardRibbon;
