import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  theme,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";
import { AgencyContext } from "../../Settings/AgencyProvider/AgencyProvider";
import Conversations from "./Conversations";
import Messages from "./Messages";
import ConversationSummary from "./ConversationSummary";
import ConfirmationModal from "../../Modals/Confirmation";
import { toast } from "react-toastify";

const skeletonMap = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

export default function ConversationDrawer({
  isOpen,
  onClose,
  selectedContact,
}) {
  const baseUrl = getHostDomain();

  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [deleteChatId, setDeleteChatId] = useState(null);
  const [loading, setLoading] = useState({
    conversation: true,
    message: true,
    delete: false,
  });

  const {
    isOpen: isDeleteConfirmationModalOpen,
    onOpen: onOpenDeleteConfirmationModal,
    onClose: onCloseDeleteConfirmationModal,
  } = useDisclosure();

  const onConfirmDelete = async () => {
    onCloseDeleteConfirmationModal();
    try {
      setLoading((prevState) => ({ ...prevState, delete: true }));
      const url = baseUrl + `/api/v1/conversation/${deleteChatId}`;
      const response = await fetchController(url, "DELETE");
      setDeleteChatId(null);
      toast.success(response.detail || "conversations deleted successfully");
      getContactConversations(selectedContact.id);
    } catch (error) {
      toast.error(
        "Conversation has other linked items. Please delete them first and then retry."
      );
    } finally {
      setLoading((prevState) => ({ ...prevState, delete: false }));
    }
  };

  const getMessages = async (selectedConversationId) => {
    try {
      setLoading((prevState) => ({ ...prevState, message: true }));
      const response = await fetchController(
        baseUrl +
          `/api/v1/conversation/conversationchat_daterange?conversation_id=${selectedConversationId}`,
        "GET"
      );
      setMessages(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, message: false }));
    }
  };

  const getContactConversations = async (contactId) => {
    try {
      setLoading((prevState) => ({ ...prevState, conversation: true }));
      const response = await fetchController(
        baseUrl + `/api/v1/conversation/contact/${contactId}`,
        "GET"
      );
      setConversations(response?.data?.items);
      setSelectedConversation(response?.data?.items[0]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, conversation: false }));
    }
  };
  useEffect(() => {
    if(selectedContact) {
      getContactConversations(selectedContact?.id);
    }
  }, [selectedContact]);

  useEffect(() => {
    if (selectedConversation) {
      getMessages(selectedConversation?.id);
    } else {
      setTimeout(() => {
        setLoading((prevState) => ({ ...prevState, message: false }));
      }, 2000);
    }
  }, [selectedConversation]);

  const agency = useContext(AgencyContext);
  const { textColor, colorPreset } = agency;
  const chatBg = useColorModeValue("#E8E8E8", theme.colors.gray[700]);
  const chatColor = useColorModeValue("black", "white");
  const selectedChatBg = useColorModeValue(colorPreset || "#3182ce", textColor);

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={"full"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Conversations`}</DrawerHeader>
          <DrawerBody>
            <Flex justifyContent={"center"} gap={2} height={"100%"}>
              <Box
                d="flex"
                w="33%"
                justifyContent="space-between"
                alignItems="center"
                overflowY="auto"
              >
                <Conversations
                  loading={loading}
                  conversations={conversations}
                  skeletonMap={skeletonMap}
                  selectedConversation={selectedConversation}
                  setSelectedConversation={setSelectedConversation}
                  selectedChatBg={selectedChatBg}
                  chatBg={chatBg}
                  chatColor={chatColor}
                  onOpenDeleteConfirmationModal={onOpenDeleteConfirmationModal}
                  deleteChatId={deleteChatId}
                  setDeleteChatId={setDeleteChatId}
                />
              </Box>

              <Box
                d="flex"
                w="33%"
                overflowY="auto"
                justifyContent="space-between"
                alignItems="center"
              >
                <Messages
                  loading={loading}
                  skeletonMap={skeletonMap}
                  messages={messages}
                />
              </Box>

              <Box
                d="flex"
                w="33%"
                overflowY="auto"
                justifyContent="space-between"
                alignItems="center"
              >
                <ConversationSummary
                  loading={loading}
                  selectedConversation={selectedConversation}
                  skeletonMap={skeletonMap}
                />
              </Box>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {isDeleteConfirmationModalOpen && deleteChatId && (
        <ConfirmationModal
          table={"checkedConversationDelete"}
          isOpen={isDeleteConfirmationModalOpen}
          onConfirm={onConfirmDelete}
          onClose={onCloseDeleteConfirmationModal}
        />
      )}
    </>
  );
}
