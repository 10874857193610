import { Select } from "@chakra-ui/react";
import { countryList } from "../../utils/CountryList/CountryList";

export default function CountryCodeSelectField({ register, errors }) {
  return (
    <>
      <Select
        borderRight={0}
        borderRightRadius={0}
        {...register("countryCode")}
      >
        <option value="">Code</option>
        {countryList.map((country) => (
          <option key={country.code} value={country.code}>
            {country.flag} {country.code} ({country.dial_code})
          </option>
        ))}
      </Select>
      {errors.countryCode && (
        <p style={{ color: "red", fontSize: "11px" }}>
          Country code is required
        </p>
      )}
    </>
  );
}
