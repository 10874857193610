import { Box, Flex, Heading } from "@chakra-ui/react";
import Chats from "./Chats";
import ChatFooter from "./Chatfooter";
import { useEffect, useRef } from "react";

export default function AgentChatWidget({
  messages,
  sendMessage,
  connected,
  chatWindowHeading
}) {
  const dummyRef = useRef(null);
  useEffect(() => {
    if (dummyRef.current) {
      dummyRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, dummyRef]);
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      flexDir={"column"}
      bg={"white"}
      overflowY={"auto"}
      h={"70svh"}
    >
      <Box
        borderTopRadius={"md"}
        w={"100%"}
        bg={"blue.400"}
        color={"white"}
        p={2}
      >
        <Heading fontSize={"xl"}>{`Visitor ${chatWindowHeading}`}</Heading>
      </Box>
      <Box w={"100%"} overflowY={"auto"} flex={1} p={3}>
        <Chats messages={messages} />
        <Box ref={dummyRef}></Box>
      </Box>
      <Box h={10} w={"100%"}>
        <ChatFooter connected={connected} sendMessage={sendMessage} />
      </Box>
    </Flex>
  );
}
