import { useEffect, useState } from "react";
import Select from "react-select";
import { fetchController } from "../../utils/FetchController/fetchController";
import { getHostDomain } from "../../utils/utils";

export default function SelectAssistant({ assistant, onChangeAssistant, disable }) {
  const baseUrl = getHostDomain();
  const [assistants, setAssistants] = useState([]);
  const options = assistants.map((assistant) => ({
    value: assistant,
    label: assistant.name,
    isDisabled: assistant.has_human_agent === false || assistant.has_human_agent === null
  }));
  useEffect(() => {
    (async () => {
      const response = await fetchController(
        baseUrl +
          "/api/v1/assistant/get_my_details?page=1&size=50"
      );
      setAssistants(response.data.items);
    })();
  }, []);
  return (
    <Select
      value={options.find((option) => option.value.id === assistant?.id)}
      options={options}
      onChange={onChangeAssistant}
      isDisabled={disable}
    />
  );
}
