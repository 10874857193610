import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import TourLayout from "../Layout/TourLayout";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { useContext } from "react";

export default function WidgetTour({ onCloseWidgetTour }) {
    const agency = useContext(AgencyContext);
    const { textColor } = agency;
  return (
    <TourLayout onCloseTour={onCloseWidgetTour}>
      <Box fontSize={"md"}>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          What is Widget?
        </Text>
        <Text>
          A widget is an interface to let your users interact with your
          assistants. You could embed it as an iframe on your webpage, use it as
          a virtual chatbot, deploy it on WhatsApp or use Twilio to let your
          customers interact with your voice assistants
        </Text>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          To Create Voice Widgets
        </Text>

        <UnorderedList>
          <ListItem>
            Select a Voice Assistant from the Assistant dropdown
          </ListItem>
          <ListItem>
            Select the Twilio account that you’d like to integrate it with and
            the phone number
          </ListItem>
          <ListItem>
            Once created, ring up the associated phone number to test it out.
          </ListItem>
          <ListItem>
            You can also generate QR code that can be placed at physical places
            to let anyone call the number and speak with the Voice Assistant.
          </ListItem>
        </UnorderedList>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          Setting up the Widget
        </Text>
        <UnorderedList>
          <ListItem>Click "Add a Widget".</ListItem>
          <ListItem>
            Choose the assistant you want to connect the widget to and give a
            name and description to your widget & click “Submit”
          </ListItem>
          <ListItem>Click "Submit", and you’re done!</ListItem>
          <ListItem>
            Click on the “Demo” button to start using your personalized chatbot!
          </ListItem>
        </UnorderedList>
        <Text color={textColor} fontWeight={"bold"} fontSize={"xl"}>
          Adding the chatbot to your website
        </Text>
        <Text>
          {
            " Click on the “</> code” button under operations to get code snippets & integrate them on your website to deploy the chatbot! "
          }
        </Text>
      </Box>
    </TourLayout>
  );
}
