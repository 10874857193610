import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  theme,
  useColorModeValue,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import ConfigurationFormOptions from "./ConfigurationFormOptions";
import { countryList } from "../../utils/CountryList/CountryList";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
export default function ConfigurationForm({
  register,
  handleSubmit,
  control,
  errors,
  onRemoveField,
  setValue,
  watch,
  selectedField,
}) {
  const required = watch("required");
  const show_country_code = watch("show_country_code");
  const selectedFieldType = watch("type");
  const typeOfFields = [
    { value: "text", label: "Text" },
    { value: "date", label: "Date picker" },
    { value: "checkbox", label: "Checkbox" },
    { value: "select", label: "Select Options" },
    { value: "gdpr", label: "GDPR" },
    { value: "textarea", label: "Text Area" },
    { value: "tel", label: "Telephone" },
  ];
  const [option, setOption] = useState("");
  const onChangeOption = (e) => setOption(e.currentTarget.value);
  const clearOption = () => setOption("");
  const optionField = {
    option,
    onChangeOption,
    clearOption,
  };
  const countryCodeOptions = countryList.map((countryCode) => ({
    value: countryCode.code,
    label: `${countryCode.dial_code} (${countryCode.name})`,
  }));
  const agency = useContext(AgencyContext);
  const { buttonColorScheme, cardBg:bg } = agency;
  return (
    <form onSubmit={handleSubmit}>
      <Stack
        spacing={3}
        padding={4}
        width={"100%"}
        maxWidth={"650px"}
        backgroundColor={bg}
        borderRadius={3}
      >
        <Text> Select the type of field and its properties</Text>
        <FormControl isRequired>
          <FormLabel>Type of Field</FormLabel>
          <Controller
            name="type"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                options={typeOfFields}
                value={typeOfFields.find((c) => c.value === value)}
                onChange={(val) => {
                  onChange(val.value);
                  setValue("required", val.value === "gdpr");
                }}
              />
            )}
          />
        </FormControl>
        <FormControl isInvalid={errors.name?.type} isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="Name"
            {...register("name", {
              required: true,
              pattern: /^[a-zA-Z0-9_]+$/,
            })}
            name="name"
          />
          {errors.name?.type === "pattern" ? (
            <FormErrorMessage>Enter a valid name</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl isInvalid={errors.name?.type} isRequired>
          <FormLabel>Description</FormLabel>
          <Input
            placeholder="Description"
            name="description"
            {...register("description", {
              required: true,
            })}
          />
          {errors.name?.type === "required" ? (
            <FormErrorMessage>Enter a valid value</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl isInvalid={errors.name?.type} isRequired>
          <FormLabel>Label</FormLabel>
          <Input
            placeholder="Label"
            name="label"
            {...register("label", {
              required: true,
            })}
          />
          {errors.name?.type === "required" ? (
            <FormErrorMessage>Enter a valid value</FormErrorMessage>
          ) : null}
        </FormControl>
        <Checkbox
          isDisabled={selectedFieldType === "gdpr"}
          isChecked={required}
          {...register("required")}
          colorScheme="green"
        >
          Required
        </Checkbox>
        {selectedFieldType === "select" ? (
          <ConfigurationFormOptions
            optionField={optionField}
            control={control}
          />
        ) : null}
        {selectedFieldType === "gdpr" ? (
          <FormControl>
            <FormLabel>Privacy Content Link</FormLabel>
            <Input type="url" {...register("privacy_content_url")} />
          </FormControl>
        ) : null}
        {selectedFieldType === "tel" ? (
          <>
            <Checkbox
              isChecked={show_country_code}
              {...register("show_country_code")}
              colorScheme="green"
            >
              Show country code
            </Checkbox>
            <FormControl>
              <FormLabel>Default CountryCode</FormLabel>
              <Select
                onChange={({ value }) => {
                  setValue("default_country_code", value);
                }}
                value={countryCodeOptions.find(
                  (countryCode) =>
                    countryCode.value === watch("default_country_code")
                )}
                name="default_country_code"
                options={countryCodeOptions}
              />
            </FormControl>
          </>
        ) : null}
        <Flex
          marginBlock={2}
          justifyContent={"space-around"}
          alignItems={"center"}
        >
          {selectedField ? (
            <Button onClick={onRemoveField} type="button" colorScheme="red">
              Delete Field
            </Button>
          ) : null}
          <Button type="submit" colorScheme={selectedField ? buttonColorScheme : "green"}>
            {selectedField ? "Update field" : "Add field"}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}
