import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import Table from "../../Tables/Table";

const ManageTeams = () => {
  // Dummy data for existing users
  const existingUsers = [
    {
      Name: "John Doe",
      Email: "john@example.com",
      Role: "Admin",
      Date: "2024-05-06",
      Operations: "operation",
    },
    {
      Name: "Jane Doe",
      Email: "jane@example.com",
      Role: "Member",
      Date: "2024-05-05",
      Operations: "operation",
    },
    // Add more users as needed
  ];

  const headers = ["Date", "Name", "Email", "Role", "Operations"];
  const columns = headers.map((key) => ({
    Header: key,
    accessor: key,
    Filter: ({ column }) => <input {...column.filterProps} />,
  }));
  return (
    <Box border="1px" p="5" borderRadius="md" h="81vh" overflowY="auto">
      <Flex justify="space-between" align="center" mb="4">
        <Text fontSize="xl" fontWeight="bold">
          Team : Sales
        </Text>
        <Button colorScheme="blue">Invite User</Button>
      </Flex>
      <Table data={existingUsers} columns={columns} />
    </Box>
  );
};

export default ManageTeams;
