import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  TableCaption,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

const CsvTable = ({ data, selectedCsvColumns, handleColumnSelect, showNumberOfRow,}) => {
  const [renderTableRow, setRenderTableRow] = useState([]);

  useEffect(() => {
    const maxLength = Math.max(...data.slice(0, showNumberOfRow).map((arr) => arr?.length));

    const filledArray = data.slice(0, showNumberOfRow).map((arr) => {
      if (arr?.length < maxLength) {
        return arr.concat(Array(maxLength - arr?.length).fill("-"));
      } else {
        return arr;
      }
    });

    setRenderTableRow(filledArray)
  }, [data]);

  return (
    <>
      {renderTableRow?.length && (
        <TableContainer>
          <Table variant="simple" colorScheme="blue">
            <TableCaption>{`More ${
              data?.length - 4
            } data remaining`}</TableCaption>
            <Thead>
              <Tr>
                {renderTableRow[0]?.map((row, index) => (
                  <Th
                    cursor={"pointer"}
                    onClick={() => handleColumnSelect(row, index)}
                    color={
                      (selectedCsvColumns.text?.index === index ||
                        selectedCsvColumns.imageURL?.index === index) &&
                      "white"
                    }
                    bgColor={
                      (selectedCsvColumns.text?.index === index ||
                        selectedCsvColumns.imageURL?.index === index) &&
                      "#3182ce"
                    }
                    key={`table-head-${index + 1}`}
                    id={index}
                  >
                    Column {index + 1}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {renderTableRow?.map((row, rowIndex) => (
                <Tr key={`table-row-${rowIndex}`}>
                  {row?.map((cell, cellIndex) => (
                    <Td
                      key={`table-cell-${cellIndex}`}
                      color={
                        (selectedCsvColumns?.text?.index === cellIndex ||
                          selectedCsvColumns?.imageURL?.index === cellIndex) &&
                        "white"
                      }
                      bgColor={
                        (selectedCsvColumns?.text?.index === cellIndex ||
                          selectedCsvColumns?.imageURL?.index === cellIndex) &&
                        "#3182ce"
                      }
                    >
                      <pre>{cell}</pre>
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default CsvTable;
