// ToastComponent.js
import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastComponent = () => {
  return <ToastContainer position="top-right" autoClose={3000} />;
};

export const showToast = (message, type = 'success') => {
  switch (type) {
    case 'success':
      toast(message, { className: 'toast-success' });
      break;
    case 'error':
      toast(message, { className: 'toast-error' });
      break;
    case 'info':
      toast.info(message, { className: 'toast-info' });
      break;
    case 'warning':
      toast.warning(message, { className: 'toast-warning' });
      break;
    default:
      toast(message);
  }
};

export default ToastComponent;
