import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { fetchController } from "../../utils/FetchController/fetchController";
import { showToast } from "../../utils/Toast/Toast";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";

const options = [
  { value: "pdf", label: "PDF" },
  { value: "http", label: "HTTP" },
];

const AddIntent = ({ isOpen, onOpen, onClose, fetchAssistants }) => {
  const baseUrl = getHostDomain();
  const finalRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();
  const endpoint = "/api/v1/assistant/list?page=1&size=50";
  const urlHit = baseUrl + endpoint;
  const [assistants, setAssistants] = useState([]);
  const hitFetch = async () => {
    try {
      const getData = await fetchController(urlHit, "GET");
      if (getData?.data) {
        const data = getData?.data?.items.map((item) => {
          return {
            value: item.id,
            label: `${item.name}
            (${item.description})
            `,
          };
        });
        if (data) {
          setAssistants(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (isOpen) {
      hitFetch();
    }
  }, [isOpen]);

  const onSubmit = async (data) => {
    const endpoint = "/api/v1/intent";
    const urlHit = baseUrl + endpoint;
    const body = {
      ...data,
      attributes: {},
      is_active:true,
    };
    const getData = await fetchController(urlHit, "POST", body);
    if (getData?.message) {
      showToast("Intent Added", "success");
      reset();
      fetchAssistants();
      setTimeout(() => {
        onClose();
      }, 1000);
    } else {
      showToast("Oops! Something went wrong.", "error");
    }
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;
  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setValue("name", "");
        }}
      >
        <ModalOverlay />
        <ModalContent height="50vh" width="95%" maxWidth="600px">
          <ModalHeader>Add an intent</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* First line */}
              <Stack direction="row" mb="4">
                <Box flex="1">
                  <Input
                    placeholder="Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Name is required
                    </p>
                  )}
                </Box>
                {/* <Box flex="1" mr="2">
                  <Select
                    placeholder="Select Intent type"
                    {...register("assistant_id", { required: true })}
                    options={assistants && assistants}
                    isSearchable={false}
                    onChange={(selectedOption) => {
                      setValue("assistant_id", selectedOption?.value || null, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                  />
                  {errors.assistant_id && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Assistant required
                    </p>
                  )}
                </Box> */}
              </Stack>
              {/* <Stack direction="row" alignItems="center" mb="4">
                <Text mr="2" fontWeight="bold">
                  Widget Type:
                </Text>
                {widgetTypes.map((type) => (
                  <Box
                    key={type.value}
                    onClick={() => setValue("widget_type", type.value)}
                    style={{
                      cursor: "pointer",
                      marginRight: "8px",
                      fontSize: "20px",
                      backgroundColor:
                        watch("widget_type") === type.value
                          ? "#f0f0f0"
                          : "white",
                      padding: "4px",
                      borderRadius: "4px",
                    }}
                  >
                    {type.icon}{" "}
                    <input
                      type="radio"
                      {...register("widget_type", { required: true })}
                      value={type.value}
                      style={{ display: "none" }}
                    />
                  </Box>
                ))}
                {errors.widget_type && (
                  <p style={{ color: "red", fontSize: "11px" }}>
                    Widget Type is required
                  </p>
                )}
              </Stack> */}
              <Textarea
                placeholder="only for your reference, not used as part of the prompt"
                {...register("description", { required: true })}
                resize="vertical"
              />
              {errors.description && (
                <p style={{ color: "red", fontSize: "11px" }}>
                  Description is required
                </p>
              )}

              {/* Submit button */}
              <Button colorScheme={buttonColorScheme} type="submit" mt="4">
                Submit
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddIntent;
