import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { fetchController } from "../../utils/FetchController/fetchController";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getHostDomain } from "../../utils/utils";
import { SettingContext } from "../Settings/Reducer/SettingProvider";
import { useContext, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import DragAndDropFileButton from "../DataSources/DataSourcesBegginers/DragDropFileButton";
import { fileUploadFetch } from "../../utils/FetchController/fileUplaodFetch";
import Select from "react-select";
import { RiDeleteBin7Line } from "react-icons/ri";
function WebcallWidgetForm({ form, getWidgetByIdWithParams }) {
  const { register, formState, watch } = form;
  const { widgetId } = useParams();
  const widget = watch();
  const baseUrl = getHostDomain();
  const { state: plan } = useContext(SettingContext);
  const remove_branding = !plan?.remove_branding;
  const [status, setStatus] = useState("idle");
  const onSubmit = async (data) => {
    try {
      const widgetToUpdate = {
        ...widget,
        style_params: data.style_params,
      };
      await fetchController(
        baseUrl + `/api/v1/widget/${widgetId}`,
        "PUT",
        widgetToUpdate
      );
      toast.success("Widget updated");
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const onChangeBotIcon = async (botIcon) => {
    try {
      const formData = new FormData();
      formData.append("upload_bubble_bot_icon", botIcon);
      const url = baseUrl + `/api/v1/widget/${widgetId}/BubbleBotIcon`;
      await fileUploadFetch(formData, url);
      await getWidgetByIdWithParams();
      form.setValue("deploy_type", "phoneIcon");
    } catch (error) {
      toast.error("Some error occured");
    }
  };
  const deployTypeOptions = [
    { value: "iframe", label: "Iframe" },
    { value: "phoneIcon", label: "Phone Icon" },
  ];
  const deployType = watch("deploy_type") || "iframe";
  const removeBotIcon = async () => {
    try {
      setStatus("removing");
      const url = baseUrl + `/api/v1/widget/${widgetId}/BubbleBotIcon`;
      await fetchController(url, "DELETE");
      await getWidgetByIdWithParams();
      form.setValue("deploy_type", "phoneIcon");
    } catch (error) {
      toast.error("Some error occured");
    } finally {
      setStatus("idle");
    }
  };
  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <FormControl>
          <FormLabel>Deploy type</FormLabel>
          <Select
            options={deployTypeOptions}
            value={deployTypeOptions.find((o) => o.value === deployType)}
            onChange={({ value }) => form.setValue("deploy_type", value)}
          />
        </FormControl>
        {deployType === "phoneIcon" && (
          <>
            <Divider />
            <FormControl>
              <FormLabel
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                gap={3}
              >
                <Text>Bot Icon</Text>
                <IconButton
                  isDisabled={!watch("bubble_bot_icon")}
                  isLoading={status === "removing"}
                  colorScheme="red"
                  icon={<RiDeleteBin7Line />}
                  onClick={removeBotIcon}
                  size={"sm"}
                />
              </FormLabel>
              <FileUploader handleChange={onChangeBotIcon}>
                <DragAndDropFileButton />
              </FileUploader>
            </FormControl>
            <Divider />
          </>
        )}
        {deployType === "iframe" ? (
          <>
            <FormControl isRequired>
              <FormLabel>Header Name</FormLabel>
              <Input {...register("style_params.header_name")} />
            </FormControl>
            <FormControl>
              <FormLabel>Logo</FormLabel>
              <Input {...register("style_params.logo")} />
            </FormControl>
            <FormControl
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <FormLabel>Remove branding</FormLabel>
              <Checkbox
                isDisabled={remove_branding}
                {...register("style_params.remove_branding")}
              />
            </FormControl>
          </>
        ) : null}
        <FormControl
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <FormLabel>Record button color</FormLabel>
          <Input
            {...register("style_params.theme.magicBall.bgColor")}
            type="color"
            width={"100px"}
            borderRadius={"md"}
          />
        </FormControl>
        <Button
          margin={"auto"}
          loadingText="Submitting"
          isLoading={formState.isSubmitting}
          colorScheme="blue"
          type="submit"
        >
          Submit
        </Button>
      </Stack>
    </form>
  );
}
export default WebcallWidgetForm;
