import { Center, Spinner, Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const Loader = ({ text="" }) => {
  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      backgroundColor={"rgba(0, 0, 0, 0.5)"} // Adjust the opacity (0.5) as needed
      zIndex="1000"
      display="flex"
      align="center"
      justify="center"
      direction="column"
    >
      <Spinner />
      <Text fontSize="md" colorScheme="blue">
        {text}
      </Text>
    </Flex>
  );
};

export default Loader;
