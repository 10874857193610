import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Textarea,
  Link,
  TagLabel,
  TagCloseButton,
  Tag,
  HStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { fetchController } from "../../utils/FetchController/fetchController";
import { fileUploadFetch } from "../../utils/FetchController/fileUplaodFetch";
import { showToast } from "../../utils/Toast/Toast";
import FileDragUploader from "../DataSources/DataSourcesBegginers/FileDragUploader";
import CsvTable from "../Tables/CsvTable";
import Loader from "../../utils/Loader/Loader";
import Papa from "papaparse";
import { IoCloseCircle } from "react-icons/io5";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import CustomSelect from "../../utils/CustomSelect/CustomSelect";
import { getHostDomain } from "../../utils/utils";

const options = [
  { value: "pdf", label: "PDF" },
  { value: "http", label: "HTTP" },
  { value: "text_blob", label: "Text Blob" },
  { value: "text_image", label: "CSV (Text + Image)" },
];

const AddDataSources = ({ isOpen, onOpen, onClose, fetchSources, onGotoFirstPage }) => {
  const baseUrl = getHostDomain();
  const [isLoading, setIsLoading] = useState(false);
  const [dsType, setDsType] = useState(null);
  const [fileTypes, setFileTypes] = useState([]);

  const [csvTableData, setCsvTableData] = useState([]);
  const [selectedCsvColumns, setSelectedCsvColumns] = useState({
    text: null,
    imageURL: null,
  });

  const [includeUrls, setIncludeUrls] = useState([]);
  const [excludeUrls, setExcludeUrls] = useState([]);
  const [includeUrlInput, setIncludeUrlInput] = useState("");
  const [excludeUrlInput, setExcludeUrlInput] = useState("");

  useEffect(() => {
    if (dsType === "pdf") {
      setFileTypes(["PDF"]);
    } else if (dsType === "image") {
      setFileTypes(["JPG", "PNG", "JPEG"]);
    } else if (dsType === "text_image") {
      setFileTypes(["CSV"]);
    }
  }, [dsType]);

  const finalRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();
  const [files, setFiles] = useState([]);

  const resetStates = () => {
    setCsvTableData([]);
    setSelectedCsvColumns({ text: null, imageURL: null });
    reset();
    setValue("dsType", null);
    fetchSources();
    onClose();
    setFiles([]);
    setDsType(null);
    setIncludeUrls([]);
    setExcludeUrls([]);
    onGotoFirstPage();
  };

  const onSubmit = async (data) => {
    if (data.dsType === "text_image") {
      handleCSVSubmit(data);
    } else {
      try {
        setIsLoading(true);
        const endpoint = "/api/v1/datasource";
        const urlHit = baseUrl + endpoint;
        const attributesExist = {
          url: data.startURLs,
          include_urls: includeUrls,
          exclude_urls: excludeUrls,
          max_crawl_pages: Number(data.maxCrawlPages),
        };
        const body = {
          ds_type: data.dsType,
          name: data.name,
          description: data.description,
          attributes: data.dsType === "http" ? attributesExist : {},
          org_id: null,
        };
        const getData = await fetchController(urlHit, "POST", body);

        if (getData?.message) {
          if (data.dsType === "text_blob") {
            await fetchController(
              baseUrl +
                `/api/v1/datasource/${getData.data.id}/text_blob?datasource_id=${getData.data.id}&ds_type=${getData.data.ds_type}&name=${getData.data.name}&description=${getData.data.description}`,
              "POST",
              { text_blob: data.attributes_text_blob }
            );
          }
          for (const file of files) {
            const uniqueFilename = `${file.name}-${Date.now()}`;
            const description = "";
            const endPoint = `/api/v1/datasource/${
              getData?.data?.id
            }/file?ds_type=${getData.data.ds_type}&name=${encodeURIComponent(
              uniqueFilename
            )}&description=${description}`;

            const urlHit = baseUrl + endPoint;

            const formData = new FormData();
            formData.append("datasourcefile_file", file);

            await fileUploadFetch(formData, urlHit);
          }

          showToast("Source Added", "success");
          resetStates();
        } else {
          showToast("Oops! Something went wrong.", "error");
        }
      } catch (e) {
        console.error(e);
        toast.error(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleChange = (file) => {
    setFiles([...files, ...file]);
    if (file[0]?.type === "text/csv") {
      readCSVFile(file);
    }
  };

  const handleRemove = (indexToRemove) => {
    const updatedFiles = files.filter((_, index) => index !== indexToRemove);
    setFiles(updatedFiles);
    setCsvTableData([]);
    setSelectedCsvColumns({ text: null, imageURL: null });
  };

  function readCSVFile(file) {
    if (file.length > 0) {
      const reader = new FileReader();
      reader.readAsText(file[0]);

      reader.onload = function (event) {
        const csvData = event.target.result;
        const parsedData = Papa.parse(csvData);
        setCsvTableData(parsedData.data.slice(1));
      };
    }
  }

  const handleCsvColumnSelect = (row, index) => {
    if (
      selectedCsvColumns?.text === null &&
      selectedCsvColumns.imageURL?.index !== index
    ) {
      setSelectedCsvColumns({
        ...selectedCsvColumns,
        text: { name: row, index: index },
      });
    } else if (
      selectedCsvColumns?.text &&
      selectedCsvColumns?.text?.index === index
    ) {
      setSelectedCsvColumns({
        ...selectedCsvColumns,
        text: null,
      });
    } else if (selectedCsvColumns?.imageURL === null) {
      setSelectedCsvColumns({
        ...selectedCsvColumns,
        imageURL: { name: row, index: index },
      });
    } else if (
      selectedCsvColumns?.imageURL &&
      selectedCsvColumns?.imageURL?.index === index
    ) {
      setSelectedCsvColumns({
        ...selectedCsvColumns,
        imageURL: null,
      });
    } else return;
  };

  const handleCSVSubmit = async (data) => {
    if (selectedCsvColumns.text === null) {
      toast.error("Please select text column");
    } else {
      try {
        setIsLoading(true);
        const endpoint = "/api/v1/datasource";
        const urlHit = baseUrl + endpoint;

        const attributes = {
          col_index_text: selectedCsvColumns.text
            ? selectedCsvColumns.text?.index
            : null,
        };

        if(selectedCsvColumns.imageURL) {
          attributes.col_index_image_url = selectedCsvColumns.imageURL?.index
        }
        
        const payload = {
          ds_type: data.dsType,
          name: data.name,
          description: data.description,
          attributes: attributes,
          org_id: null,
        };

        const dataSourceResponse = await fetchController(
          urlHit,
          "POST",
          payload
        );
        const dataSourceId = dataSourceResponse.data.id;

        const formData = new FormData();
        formData.append("collection_file", files[0]);

        const response = await fileUploadFetch(
          formData,
          baseUrl +
            `/api/v1/datasource/${dataSourceId}/collection`
        );
        toast.success(response?.message);
        resetStates();
      } catch (error) {
        toast.error(error?.message || "Oops! Something went wrong.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAddIncludeUrl = () => {
    if (
      includeUrlInput &&
      !includeUrls.includes(includeUrlInput) &&
      !excludeUrls.includes(includeUrlInput)
    ) {
      setIncludeUrls([...includeUrls, includeUrlInput]);
      setIncludeUrlInput("");
    } else {
      toast.error("URL must be unique and not already in the list.");
    }
  };

  const handleAddExcludeUrl = () => {
    if (
      excludeUrlInput &&
      !excludeUrls.includes(excludeUrlInput) &&
      !includeUrls.includes(excludeUrlInput)
    ) {
      setExcludeUrls([...excludeUrls, excludeUrlInput]);
      setExcludeUrlInput("");
    } else {
      toast.error("URL must be unique and not already in the list.");
    }
  };

  const removeUrl = (type, index) => {
    if (type === "include") {
      setIncludeUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
    } else if (type === "exclude") {
      setExcludeUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
    }
  };
  const agency = useContext(AgencyContext);
  const { buttonColorScheme } = agency;
  return (
    <>
      <Modal
        size="xl"
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setFiles([]);
          setDsType(null);
          setValue("dsType", null);
          onGotoFirstPage();
        }}
      >
        <ModalOverlay />
        <ModalContent height="auto" width="100%" maxWidth="900px">
          <ModalHeader>Add Data Sources</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" mb="4">
                <Box flex="1" mr="2">
                  {/* <Select
                    placeholder="Select Data Source Type"
                    {...register("dsType", { required: true })}
                    options={options}
                    isSearchable={false}
                    onChange={(selectedOption) => {
                      setValue("dsType", selectedOption?.value || null, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                      setFiles([]);
                      setDsType(selectedOption?.value || null);
                    }}
                  /> */}
                  <CustomSelect 
                    placeholder="Select Data Source Type"
                    {...register("dsType", { required: true })}
                    options={options}
                    onChange={(selectedOption) => {
                      setValue("dsType", selectedOption?.value || null, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                      setFiles([]);
                      setDsType(selectedOption?.value || null);
                    }}
                  />


                  {errors.dsType && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Data Source Type is required
                    </p>
                  )}
                </Box>
                <Box flex="1">
                  <Input
                    placeholder="Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      Name is required
                    </p>
                  )}
                </Box>
              </Stack>
              <Textarea
                placeholder="Only for your reference, not used as part of the prompt"
                {...register("description", { required: true })}
                resize="vertical"
              />
              {errors.description && (
                <p style={{ color: "red", fontSize: "11px" }}>
                  Description is required
                </p>
              )}
              {(dsType === "pdf" ||
                dsType === "image" ||
                dsType === "text_image") && (
                <FileDragUploader
                  handleChange={handleChange}
                  fileTypes={fileTypes}
                  requriedMessage={`${
                    dsType === "text_image" ? "CSV file" : dsType
                  } is required`}
                  files={files}
                  handleRemove={handleRemove}
                  placeholder={
                    dsType === "text_image" && "Drag and drop a CSV file"
                  }
                />
              )}

              {files[0]?.type === "text/csv" && (
                <>
                  <Box
                    as="p"
                    paddingBlock={"0.5rem"}
                    fontSize={"0.8rem"}
                    color={"red"}
                  >
                    {selectedCsvColumns.text === null
                      ? "Please select Text column"
                      : selectedCsvColumns.imageURL === null
                      ? "Please select Image column"
                      : ""}
                  </Box>
                  {csvTableData.length ? (
                    <CsvTable
                      data={csvTableData}
                      handleColumnSelect={handleCsvColumnSelect}
                      selectedCsvColumns={selectedCsvColumns}
                      showNumberOfRow={4}
                    />
                  ) : (
                    <Loader />
                  )}
                </>
              )}

              {watch("dsType") === "http" && (
                <Stack mb="4" mt="12px">
                  <Input
                    placeholder="Start URLs"
                    {...register("startURLs", {
                      required: true,
                      pattern: {
                        value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
                        message: "Enter a valid URL with https://",
                      },
                    })}
                  />
                  {errors.startURLs && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      {errors.startURLs.message}
                    </p>
                  )}

                  <Input
                    placeholder="Max Crawl Pages"
                    defaultValue="100"
                    {...register("maxCrawlPages", {
                      required: true,
                      max: 1000,
                    })}
                  />
                  {errors.maxCrawlPages && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      {errors.maxCrawlPages.type === "required"
                        ? "Max crawl pages is required"
                        : errors.maxCrawlPages.type === "max"
                        ? "Max crawl pages cannot be greater than 1000. Please contact us for higher limits"
                        : "Max crawl poages is required !"}
                    </p>
                  )}
                  <FormControl mt="2">
                    <FormLabel>
                      Include URLs{" "}
                      <Link
                        href="https://www.digitalocean.com/community/tools/glob?comments=true&glob=%2F%2A%2A%2F%2A.js&matches=false&tests=%2F%2F%20This%20will%20match%20as%20it%20ends%20with%20%27.js%27&tests=%2Fhello%2Fworld.js&tests=%2F%2F%20This%20won%27t%20match%21&tests=%2Ftest%2Fsome%2Fglobs"
                        isExternal
                        sx={{
                          _hover: {
                            color: "blue",
                          },
                        }}
                      >
                        {"(Use Glob format)"}
                      </Link>
                    </FormLabel>
                    <Stack direction="row">
                      <Input
                        value={includeUrlInput}
                        onChange={(e) => setIncludeUrlInput(e.target.value)}
                        placeholder="Include URLs"
                      />
                      <Button onClick={handleAddIncludeUrl}>Add</Button>
                    </Stack>
                    <HStack
                      mt={2}
                      spacing={4}
                      as="ul"
                      style={{ listStyle: "none" }}
                    >
                      {includeUrls.map((url, index) => (
                        <Tag
                          size="md"
                          key={index}
                          borderRadius="full"
                          variant="solid"
                          colorScheme="green"
                        >
                          <TagLabel>{url}</TagLabel>
                          <TagCloseButton
                            onClick={() => removeUrl("include", index)}
                          />
                        </Tag>
                      ))}
                    </HStack>
                  </FormControl>
                  <FormControl mt="2">
                    <FormLabel>
                      Exclude URLs{" "}
                      <Link
                        href="https://www.digitalocean.com/community/tools/glob?comments=true&glob=%2F%2A%2A%2F%2A.js&matches=false&tests=%2F%2F%20This%20will%20match%20as%20it%20ends%20with%20%27.js%27&tests=%2Fhello%2Fworld.js&tests=%2F%2F%20This%20won%27t%20match%21&tests=%2Ftest%2Fsome%2Fglobs"
                        isExternal
                        sx={{
                          _hover: {
                            color: "blue",
                          },
                        }}
                      >
                        {"(Use Glob format)"}
                      </Link>
                    </FormLabel>
                    <Stack direction="row">
                      <Input
                        value={excludeUrlInput}
                        onChange={(e) => setExcludeUrlInput(e.target.value)}
                        placeholder="Exclude URLs"
                      />
                      <Button onClick={handleAddExcludeUrl}>Add</Button>
                    </Stack>
                    <HStack
                      mt={2}
                      spacing={4}
                      as="ul"
                      style={{ listStyle: "none" }}
                    >
                      {excludeUrls.map((url, index) => (
                        <Tag
                          size="md"
                          key={index}
                          borderRadius="full"
                          variant="solid"
                          colorScheme="red"
                        >
                          <TagLabel>{url}</TagLabel>
                          <TagCloseButton
                            onClick={() => removeUrl("exclude", index)}
                          />
                        </Tag>
                      ))}
                    </HStack>
                  </FormControl>
                </Stack>
              )}
              {watch("dsType") === "text_blob" ? (
                <FormControl marginBlock={2}>
                  <Textarea
                    placeholder="Text Blob"
                    name="attributes_text_blob"
                    {...register("attributes_text_blob", {
                      required: true,
                    })}
                  />
                </FormControl>
              ) : null}
              <Button
                isDisabled={isLoading}
                width="80px"
                colorScheme={buttonColorScheme}
                type="submit"
                mt="4"
              >
                {isLoading ? <Spinner size="sm" /> : "Submit"}
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddDataSources;
