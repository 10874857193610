import { Box, Input } from "@chakra-ui/react";
import CountryCodeSelectField from "./CountryCodeSelectField";
export default function TelephoneContactField({ register, errors }) {
  return (
    <>
      <Box>
        <CountryCodeSelectField register={register} errors={errors} />
      </Box>
      <Input
        type="tel"
        placeholder="Phone Number"
        borderLeft={0}
        borderLeftRadius={0}
        {...register("phoneNumber")}
      />
    </>
  );
}
