import { Box, Button, Flex, Grid, Stack } from "@chakra-ui/react";
import TelephoneContactField from "../Contacts/TelephoneContactField";

export default function PhoneDialer({ register, onDialerKeyPress, errors }) {
  const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"];
  return (
    <Stack spacing={1}>
      <Flex>
        <TelephoneContactField register={register} errors={errors} />
      </Flex>
      <Box marginBlock={3}>
        <Grid gap={3} gridTemplateColumns={"1fr 1fr 1fr"}>
          {keys.map((key) => (
            <Key onClickKey={onDialerKeyPress(key)} key={key} value={key} />
          ))}
        </Grid>
      </Box>
    </Stack>
  );
}

function Key({ value, onClickKey }) {
  return (
    <Button onClick={onClickKey} >
      {value}
    </Button>
  );
}
