import {
  Box,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import { Controller } from "react-hook-form";
import FollowUpFieldSelect from "./FolowUpFieldSelect";

export default function FollowUpTextInput({ automateForm, fields }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { control } = automateForm;
  const inputRef = useRef(null);
  const onCloseFieldSelect = () => {
    onClose();
    if (inputRef.current) inputRef.current.focus();
  };
  const onSelectField = (selectedField) => {
    const { followUpText: currentValue } = automateForm.getValues();
    const updatedValue = currentValue.replace("/", `{{${selectedField}}}`);
    automateForm.setValue("followUpText", updatedValue);
    onCloseFieldSelect();
  };

  return (
    <Box height={100} position={"relative"}>
      {isOpen ? (
        <FollowUpFieldSelect
          fields={fields}
          onSelectField={onSelectField}
          closeFieldSelectOptions={onCloseFieldSelect}
        />
      ) : null}
      <Controller
        control={control}
        name="followUpText"
        render={({ field }) => (
          <FormControl>
            <FormLabel>
              Follow up Text{" "}
              <i>
                <small>(Press '/' to enter a field )</small>
              </i>
            </FormLabel>
            <Input
              placeholder="Follow up text. Press '/' to enter a field"
              {...field}
              ref={inputRef}
              onChange={(e) => {
                const text = e.currentTarget.value;
                if (text.includes("/")) onOpen();
                field.onChange(e.currentTarget.value);
              }}
              name="followUpText"
            />
          </FormControl>
        )}
      />
    </Box>
  );
}
