import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useState } from "react";
import { BiSend } from "react-icons/bi";

export default function ChatFooter({ sendMessage, connected }) {
  const [message, setMessage] = useState("");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!message) return;
        sendMessage(message);
        setMessage("");
      }}
    >
      <InputGroup size="md">
        <Input
          required
          value={message}
          isDisabled={!connected}
          placeholder="Message"
          onChange={(e) => setMessage(e.currentTarget.value)}
        />
        <InputRightElement>
          <IconButton
            isDisabled={!connected}
            type="submit"
            colorScheme="blue"
            icon={<BiSend />}
            size={"sm"}
          />
        </InputRightElement>
      </InputGroup>
    </form>
  );
}
