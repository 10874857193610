import { createContext } from "react";

const AuthContext = createContext({
  authUser: null,
  isLoading: false,
  error: false,
  login: async () => {},
  logout: async () => {},
  isAuthenticated: false,
  getAccessToken: () => {},
  getRefreshToken: () => {},
  setAuthUser: () => {},
  setIsLoading: () => {},
  setError: () => {},
  exchangeCodeWithToken: async () => {},
  getUserData: async () => {},
});

export default AuthContext;
