import {
  Alert,
  Button,
  FormControl,
  FormLabel,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { fetchController } from "../../../utils/FetchController/fetchController";
import { getHostDomain } from "../../../utils/utils";
import useToolUserRunStaticFunction from "../useToolUserRunStaticFunction";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function GoHighLevel({ onClose, toolUserForm }) {
  const baseUrl = getHostDomain();
  const { watch, setValue, register, handleSubmit, formState } = toolUserForm;
  const { tool_user_id, credentials = {} } = watch();
  const { configuration = {} } = credentials;

  const { response, status } = useToolUserRunStaticFunction({
    tool_user_id,
    staticFnPayload: {
      function_name: "get_calendars",
    },
  });

  const googleCalendarOptions = response
    ? response.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    : [];

  const navigate = useNavigate();

  const onSubmitGHLConf = async (data) => {
    try {
      await fetchController(
        baseUrl + `/api/v1/tool/${data.tool_user_id}/LinkToolUser`,
        "PUT",
        {
          credentials,
        }
      );
      toast.success("Tool setup done");
      onClose();
      navigate(`/tools`);
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitGHLConf)}>
      <ModalBody>
        <Stack spacing={2}>
          <Alert status="info">
            {
              "Available slots from your GHL calendar will be picked in real time and booked."
            }
          </Alert>
          <FormControl>
            <FormLabel>Select Calendar</FormLabel>
            <Select
              value={googleCalendarOptions.find(
                (calendarOption) =>
                  calendarOption.value === configuration.calendar_id
              )}
              onChange={({ value }) => {
                setValue("credentials.configuration.calendar_id", value);
              }}
              options={googleCalendarOptions}
              isLoading={status === "loading"}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Invite Address</FormLabel>
            <Textarea
              {...register("credentials.configuration.invite_address")}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Invite Description</FormLabel>
            <ReactQuill
              value={
                watch("credentials.configuration.invite_description") || ""
              }
              onChange={(value) => {
                setValue("credentials.configuration.invite_description", value);
              }}
              theme="snow"
            />
          </FormControl>
          <Text size="sm">{"You must complete all the fields above if you intend to use the GHL Calendar with your AI Agents."}</Text>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          isLoading={formState.isSubmitting}
          colorScheme="blue"
          mr={3}
          type="submit"
        >
          Finish
        </Button>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </form>
  );
}
