import { Box, Button, FormLabel, Spinner, Textarea } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { models } from "../../utils/Data/data";
import { fetchController } from "../../utils/FetchController/fetchController";
import { showToast } from "../../utils/Toast/Toast";
import { getHostDomain } from "../../utils/utils";

const General = ({ assistantData }) => {
  const { register, handleSubmit, setValue, watch } = useForm();
  const llmModelValue = watch("llmModel");
  const [defaultValue, setDefaultValue] = useState(null);
  const [defaultPrompt, setDefaultPrompt] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state

  useEffect(() => {
    if (assistantData?.data?.llm_model) {
      const selectedModel = models.find(
        (item) => item.value === assistantData.data.llm_model
      );
      if (selectedModel) {
        setValue("llmModel", selectedModel);
        setDefaultValue(selectedModel);
        setValue("prompt", assistantData?.data?.system_prompt);
        setDefaultPrompt(assistantData?.data?.system_prompt);
      }
    }
  }, [assistantData, setValue]);

  const onSubmit = async (data) => {
    const baseUrl = getHostDomain();
    setLoading(true); // Set loading to true when submitting
    const endpoint = `/api/v1/assistant/${assistantData?.data?.id}`;
    const urlHit = baseUrl + endpoint;
    const body = {
      ...assistantData?.data,
      system_prompt: data.prompt,
      llm_model: data.llmModel.value,
    };
    const getData = await fetchController(urlHit, "PUT", body);
    if (getData) {
      showToast("Assistant Updated", "success");
    }
    setLoading(false); // Set loading to false after the data is updated
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <Box width="50%" mt="3">
        <FormLabel htmlFor="llmModel">LLM Model</FormLabel>
        <Select
          id="llmModel"
          placeholder="Select LLM Model"
          options={models}
          value={defaultValue}
          {...register("llmModel", {
            required: "LLM Model is required",
          })}
          onChange={(selectedOption) => {
            setValue("llmModel", selectedOption);
            setDefaultValue(selectedOption);
          }}
        />
      </Box>
      <Box mt="3" width="50%">
        <FormLabel htmlFor="prompt">Prompt</FormLabel>
        <Textarea
          id="prompt"
          name="prompt"
          placeholder="Enter prompt..."
          defaultValue={defaultPrompt}
          {...register("prompt")}
          onChange={(selectedOption) => {
            setValue("prompt", selectedOption.target.value);
            setDefaultPrompt(selectedOption.target.value);
          }}
        />
      </Box>
      <Box mt="3">
        <Button type="submit" width="120px" colorScheme="blue" position="relative">
          
          {loading ?<Spinner size="sm" color="white" ml="2" />:"Submit"}
        </Button>
      </Box>
    </form>
  );
};

export default General;
