import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { IoMicOutline } from "react-icons/io5";

function MagicBall(props) {
  const isIframDeploy = props.deployType === "iframe";
  const magicBallSize = isIframDeploy ? 150 : 50;
  const micIconSize = isIframDeploy ? 50 : 25;
  const isPhoneIconDeploy = props.deployType === "phoneIcon";
  return (
    <Flex alignItems={"center"} justifyContent={"center"} height={"xl"}>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        width={magicBallSize}
        height={magicBallSize}
        borderRadius={"full"}
        background={`radial-gradient(
        54.75% 54.75% at 50% 50%,
        rgba(247, 244, 244, 0.22) 70.24%,
        ${props.bgColor}
      ),
      linear-gradient(135deg, rgba(255, 255, 255, 0.54) 0%, ${props.bgColor}),
      radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.22) 0%, ${props.bgColor} 90.5%)`}
      >
        {isPhoneIconDeploy && props.bubble_bot_icon ? (
          <Image src={props.bubble_bot_icon} width={"100%"} loading="" />
        ) : (
          <IoMicOutline size={micIconSize} color={props.bgColor} />
        )}
      </Flex>
    </Flex>
  );
}

export default function WebcallRepresentation({ watch }) {
  const { style_params, deploy_type = "iframe", bubble_bot_icon } = watch();
  const bgColor = style_params?.theme?.magicBall?.bgColor;
  const isIframDeploy = deploy_type === "iframe";

  return (
    <Box>
      {isIframDeploy && (
        <Flex gap={2} justifyContent={"center"} alignItems={"center"}>
          <Image
            src={style_params.logo}
            fallbackSrc={"/logo/favicon.png"}
            width={30}
          />
          <Heading fontSize={30}>{style_params?.header_name}</Heading>
        </Flex>
      )}
      <MagicBall
        bubble_bot_icon={bubble_bot_icon}
        bgColor={bgColor}
        deployType={deploy_type}
      />
      {style_params?.remove_branding
        ? null
        : isIframDeploy && (
            <Box gap={2} textAlign={"center"}>
              <Text>
                <span>PoweredBy </span>
                <strong>{style_params?.agency_name}</strong>
              </Text>
            </Box>
          )}
    </Box>
  );
}
