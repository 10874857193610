import { Stack, Text } from "@chakra-ui/react";
import ToolFunctionFormField from "./ToolFunctionFormField";
export default function FunctionFieldMapper({
  triggerTools,
  toolUserFunctionsMap,
  setValue,
  fields,
  toolUsers,
}) {
  return (
    <Stack spacing={2}>
      <Text fontSize={"md"} fontWeight={"bold"}>
      Invoke function by mapping the form fields below
      </Text>
      {triggerTools.map((triggerTool, index) => {
        const selectedToolUser = toolUsers.find(
          (toolUser) => toolUser?.id === triggerTool?.tool_user_id
        );
        const toolFunctions =
          toolUserFunctionsMap[triggerTool.tool_user_id] || [];
        const toolFunctionOptions = toolFunctions.map((toolFunction) => ({
          label: toolFunction.name,
          value: toolFunction,
        }));
        const selectedToolFunction = toolFunctionOptions.find(
          (toolFunction) =>
            toolFunction.value.id === triggerTool?.tool_function_id
        );
        return (
          <ToolFunctionFormField
            key={index}
            currentToolUserIndex={index}
            selectedToolFunction={selectedToolFunction}
            setValue={setValue}
            toolFunctionOptions={toolFunctionOptions}
            triggerTool={triggerTool}
            toolUser={selectedToolUser || {}}
            triggerTools={triggerTools}
            fields={fields}
          />
        );
      })}
    </Stack>
  );
}
