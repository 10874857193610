import { Box, Skeleton, Stack } from "@chakra-ui/react";
import Summary from "../../Conversations/Summary";

export default function ConversationSummary({
  loading,
  selectedConversation,
  skeletonMap,
}) {
  return (
    <Box className="parent" id="parentDiv">
      <>
        {loading.message ? (
          <Box display={"flex"} flexDirection={"column"} spacing={"2"}>
            <Stack spacing="1">
              {skeletonMap.map((item) => {
                return (
                  <>
                    <Skeleton
                      startColor="#3182ce6e"
                      endColor="##3182ce6e"
                      height="36px"
                      bg="green.500"
                      color="white"
                      key={`${item}`}
                    ></Skeleton>
                  </>
                );
              })}
            </Stack>
          </Box>
        ) : (
          <>
            {selectedConversation ? (
              <Summary conversationChatData={selectedConversation} />
            ) : (
              <Box
                px={3}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                py={2}
                padding
                borderRadius="5px"
              >
                No summary
              </Box>
            )}
          </>
        )}
      </>
    </Box>
  );
}
