import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import useTwilioAccounts from "../Widgets/useTwilioAccounts";
import FollowUpFieldTypes from "./FollowUpFieldTypes";
import FollowUpTextInput from "./FollowUpTextInput";
export default function AuthomatedFollowUp({ fields, automateForm }) {
  const {
    watch,
    register,
    control,
    formState: { errors },
    setValue: setAutomateFormValue,
  } = automateForm;
  const automate = watch("automate");
  const options = fields.map((field) => ({
    value: field.name,
    label: field.label,
  }));
  const { accounts, phoneNumbers } = useTwilioAccounts({
    widgetType: "phone",
    twilio_auth_id: watch("twilio_auth"),
  });
  return (
    <Box>
      <Flex justifyContent={"flex-start"} alignItems={"center"} gap={2}>
        <Checkbox
          name="automate"
          {...register("automate")}
          isChecked={automate}
          title={
            fields.length
              ? "Automate follow up on whatsapp"
              : "Create fields to activate this field"
          }
          colorScheme="green"
          disabled={!fields.length}
        >
          Automated Follow-up
        </Checkbox>
      </Flex>
      {automate && fields.length ? (
        <Stack marginBlock={3} spacing={2}>
          <FollowUpFieldTypes automateForm={automateForm} />
          {watch("automate_type") === "sms" ? (
            <>
              <FormControl>
                <FormLabel>Twilio Account</FormLabel>
                <Select
                  required
                  {...register("twilio_auth", {
                    required: true,
                  })}
                  placeholder="Select twilio account"
                  name="twilio_auth"
                  value={accounts
                    .map((account) => ({
                      value: account.id,
                      label: `${account?.name || ""} - ${account.id}`,
                    }))
                    .find((account) => account.value === watch("twilio_auth"))}
                  options={accounts.map((account) => ({
                    value: account.id,
                    label: `${account?.name || ""} (${account.id})`,
                  }))}
                  onChange={(selectedOption) => {
                    setAutomateFormValue("twilio_auth", selectedOption.value);
                    setAutomateFormValue("phone_number", undefined);
                    setAutomateFormValue("sid", undefined);
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Select
                  {...register("sid", {
                    required: true,
                  })}
                  required
                  options={phoneNumbers.map((phoneNumber) => ({
                    label: phoneNumber.friendly_name,
                    value: phoneNumber.sid,
                  }))}
                  onChange={(selected) => {
                    setAutomateFormValue("sid", selected.value);
                    setAutomateFormValue("phone_number", selected.label);
                  }}
                  placeholder="Select phone number"
                  value={phoneNumbers
                    .map((phoneNumber) => ({
                      label: phoneNumber.friendly_name,
                      value: phoneNumber.sid,
                    }))
                    .find((phoneNumber) => phoneNumber.value === watch("sid"))}
                />
              </FormControl>
              <Checkbox
                onChange={(e) =>
                  setAutomateFormValue(
                    "should_send_me_too",
                    e.currentTarget.checked
                  )
                }
                isChecked={watch("should_send_me_too")}
              >
                Send Me too
              </Checkbox>
              {watch("should_send_me_too") ? (
                <FormControl>
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    required
                    type="tel"
                    {...register("send_me_too", { required: true })}
                  />
                  <FormErrorMessage>
                    {errors.send_me_too &&
                    errors.send_me_too.type === "required"
                      ? "Field is required"
                      : null}
                  </FormErrorMessage>
                </FormControl>
              ) : null}
            </>
          ) : null}
          <FormControl>
            <FormLabel>Contact Field</FormLabel>
            <Controller
              name="contactField"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  name="contactField"
                  options={options}
                  value={options.find((c) => c.value === value)}
                  onChange={(val) => {
                    onChange(val.value);
                  }}
                />
              )}
            />
          </FormControl>
          <FollowUpTextInput automateForm={automateForm} fields={fields} />
        </Stack>
      ) : (
        <Text fontStyle={"italic"} fontSize={"sm"} marginBlock={3}>
          Please add a text field in the form to enable it
        </Text>
      )}
    </Box>
  );
}
