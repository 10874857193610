import React, { useEffect } from 'react'

const useTitle = (agencyName) => {

    useEffect(() => {
        if (agencyName) {
            document.title  = `${agencyName.charAt(0).toUpperCase()}${agencyName.slice(1)}: Your LLM Adoption Starts here`
        } 
     }, [agencyName])
}
export default useTitle

